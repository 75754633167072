import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { XContainer } from '../../styles/GlobalStyle';
import HeaderFile from '../../components/Header/HeaderFile';
import { XTabs } from '../Manage/ManageStyle';
import { XCardsTransparentTabs } from './MAPlansAppealStyling';
import TabPane from 'antd/es/tabs/TabPane';
import { Row, Col, Modal, Input, Button, Result, Avatar } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

// Actions
import { createRoleApi, createRoleReset } from '../../actions/createRoleAction';
import Header from '../../components/Header/Header';
import { checkModulePermissions } from '../../utils/helper';
import StandardAppealTAT from './StandardAppealTAT';

const Manage = (props) => {
	const navigate = useNavigate();
	const location = useLocation();
	const [maProcessing, setMaProcessing] = useState('ma_standard');
	const [displayOrganization, setDisplayOrganization] = useState(false);
	const [appealLetterApprovals, setAppealLetterApprovals] = useState(0);
	const handleTabChange = (key) => {
		// Update the URL with the filing status
		const queryParams = new URLSearchParams(window.location.search);
		queryParams.set('monitor_type', key);

		if(key=='appeal_letter_approvals')
		{
			queryParams.delete('revenue_cycle');
			queryParams.delete('filing_status');
		}

		// Construct the new URL
		const newUrl = `${window.location.pathname}?${queryParams.toString()}`;

		// Push the new URL to the browser history
		window.history.pushState({}, '', newUrl);
		setMaProcessing(key);
		// window.location.reload();
	};

	useEffect(() => {
		if (localStorage.getItem('role') === 'super-admin')
			setDisplayOrganization(true);
	}, []);

	return (
		<>
			<>
				<Col lg={24} xl={24} xs={0} md={0}>
					<Header headerTag="MA Plan Appeals Processing" />
				</Col>
				<Col lg={0} xl={0} xs={24} md={24}>
					<HeaderFile activeNav="userRoleManagement" />
				</Col>
			</>
			<XContainer fluid>
				{checkModulePermissions(
					'ma-plan-appeals-processing',
				).sub_modules.includes('list-cases') ? (
					<XTabs
						// defaultActiveKey="ma_standard"
						type="card"
						activeKey={maProcessing}
						onChange={handleTabChange}
					>
						<TabPane
							key="ma_standard"
							tab="Standard Appeal Turn Around Time"
						>
							<StandardAppealTAT 
								maProcessing={maProcessing}
								setMaProcessing={setMaProcessing}
								appealLetterApprovals={appealLetterApprovals}
								setAppealLetterApprovals={setAppealLetterApprovals} 
							/>
						</TabPane>
						<TabPane
							key="ma_expedited"
							tab="Expedited Appeal Turn Around Time"
						>
							<StandardAppealTAT 
								maProcessing={maProcessing}
								setMaProcessing={setMaProcessing}
								appealLetterApprovals={appealLetterApprovals}
								setAppealLetterApprovals={setAppealLetterApprovals} 
							/>
						</TabPane>
						{
							checkModulePermissions(
								'appeal-letter',
							).sub_modules.includes('confirm-letter') && 
							<TabPane
								key="appeal_letter_approvals"
								tab={
									<div
										className="gilroy-semiBold"
										style={{ fontFamily: 'Poppins' }}
									>
										Appeal letter Approvals{' '}
										<Avatar size={20} className="ml-2">
											{appealLetterApprovals}{' '}
										</Avatar>
									</div>
								}
							>
								<StandardAppealTAT 
									maProcessing={maProcessing}
									setMaProcessing={setMaProcessing}
									appealLetterApprovals={appealLetterApprovals}
									setAppealLetterApprovals={setAppealLetterApprovals} 
								/>
							</TabPane>
						}
					</XTabs>
				) : (
					<Result
						status="403"
						title="403"
						subTitle="Sorry, you are not authorized to access this page."
						extra={
							<Button
								type="primary"
								onClick={() => navigate('/')}
							>
								Back Home
							</Button>
						}
					/>
				)}
			</XContainer>
		</>
	);
};

const mapStateToProps = (state) => ({
	createRoleState: state.createRole,
});

const mapDispatchToProps = (dispatch) => ({
	createRole: (params) => dispatch(createRoleApi(params)),
	createRoleReset: () => dispatch(createRoleReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Manage);
