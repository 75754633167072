import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
	Row,
	Col,
	Input,
	Button,
	Space,
	Form,
	// Timeline,
	Result,
} from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { XCardsTransparent, XForm, XTag } from '../../styles/GlobalStyle';
import { ArrowLeftOutlined } from '@ant-design/icons';
import {
	XCardBackBg,
	XButtonBack,
	XEditTitle,
} from '../CaseForm/CaseFormStyle';
import { XContainer } from '../../styles/GlobalStyle';
import HeaderFile from '../../components/Header/HeaderFile';
import moment from 'moment';

// actions
import {
	getUserDropdownApi,
	getUserDropdownReset,
} from '../../actions/getUserDropdownAction';
import {
	getCaseByIdApi,
	getCaseByIdReset,
} from '../../actions/getCaseByIdAction';
import { editCaseApi, editCaseReset } from '../../actions/editCaseAction';
import { addCaseApi, addCaseReset } from '../../actions/addCaseAction';
import { analyseCoverageApi } from '../../actions/analyseCoverageAction';
import { reviseCoverageApi } from '../../actions/reviseCoverageAction';
import { sendFeedbackApi } from '../../actions/sendFeedbackAction';
import {
	getAnalysisApi,
	getAnalysisReset,
} from '../../actions/getAnalysisAction';
import { checkModulePermissions, toTitleCase } from '../../utils/helper';
import {
	XDivider,
	XPara1,
	XTitleHead,
} from '../AnalyseCoverage/AnalyseCoverageStyle';
import Header from '../../components/Header/Header';
import { XButtonImg } from '../Manage/ManageStyle';
import Timeline from '../../components/HorizontalTimeline/Timeline';

const CaseForm = (props) => {
	const {
		getUserDropdown,
		getCaseById,
		getCaseByIdReset,
		getCaseByIdState,
		editCaseReset,
		editCaseState,
		getAnalysisState,
		getAnalysis,
		getAnalysisReset,
	} = props;
	const navigate = useNavigate();
	const id = useParams().id;
	const [formData, setFormData] = useState({
		status: 'success',
		successful_claim_commission_criteria: 'percentage',
		successful_claim_commission_fixed_value: null,
		successful_claim_commission_percentage_value: null,
		rejected_claim_commission_criteria: 'percentage',
		rejected_claim_commission_fixed_value: null,
		rejected_claim_commission_percentage_value: null,
		case_amount: null,
		claim_amount: null,
		status_logs: [],
	});
	const [pageContent, setPageContent] = useState({
		pastHistory: [],
		presentComplaint: [],
		recommendedProcedure: [],
		medicalCoverage: [],
	});

	const [items, setItems] = useState({});
	const [shouldDisplayCards, setShouldDisplayCards] = useState(false);

	const handleColor = (key) => {
		let color;
		switch (key) {
			case 'pending':
				color = '#E78900';
				break;
			case 'analysed':
				color = '#7A670E';
				break;
			case 'rejected':
				color = '#cf1322';
				break;
			case 'successful':
				color = '#14804a';
				break;
			case 'allocated':
				color = '#7A0E27';
				break;
			default:
				break;
		}
		return color;
	};

	// use effects
	useEffect(() => {
		if (id) getCaseById({ id: id });
		getUserDropdown();
	}, [id]);

	useEffect(() => {
		if (id) getAnalysis({ id: id });
	}, [id]);

	useEffect(() => {
		if (getCaseByIdState.apiState === 'success') {
			setFormData({
				...getCaseByIdState.data,
				id: getCaseByIdState.data._id,
				allocated_to: getCaseByIdState.data.allocated_to?.name
					? getCaseByIdState.data.allocated_to.name
					: '-',
			});

			const tempItem = getCaseByIdState.data.status_logs.map(
				(data, i) => {
					const previousStatus =
						i > 0
							? getCaseByIdState.data.status_logs[i - 1]
									.status_updated_to
							: null;

					return {
						color: handleColor(data.status_updated_to),
						children: (
							<>
								<p>
									{moment(data.updated_at).format(
										'MMM Do, YY [at] hh:mm A',
									)}{' '}
								</p>
								<p>
									{/* {i === 0
										? `${
												data.updated_by?.name
										  } changes status to ${toTitleCase(
												data.status_updated_to,
										  )}`
										: `${
												data.updated_by?.name
										  } changed status from ${toTitleCase(
												previousStatus,
										  )} to ${toTitleCase(
												data.status_updated_to,
										  )}`} */}
								</p>
							</>
						),
					};
				},
			);

			setItems(tempItem);
			getCaseByIdReset();
		}
		// else if (getCaseByIdState.apiState === 'error') {
		// 	notification.error({ message: getCaseByIdState.message });
		// 	getCaseByIdReset();
		// }
	}, [getCaseByIdState]);

	useEffect(() => {
		if (editCaseState.apiState === 'success') {
			editCaseReset();
			navigate(-1);
		}
		// else if (editCaseState.apiState === 'error') {
		// 	notification.error({ message: editCaseState.message });
		// }
	}, [editCaseState]);

	useEffect(() => {
		if (getAnalysisState.apiState === 'success' && getAnalysisState.data) {
			setPageContent({
				pastHistory:
					getAnalysisState.data.coverage_feedback_response[
						'Past History'
					] ?? [],
				presentComplaint:
					getAnalysisState.data.coverage_feedback_response[
						'Present Complaint'
					] ?? [],
				recommendedProcedure:
					getAnalysisState.data.coverage_feedback_response[
						'Suggested Treatment'
					] ?? [],
				medicalCoverage: [
					getAnalysisState.data.coverage_feedback_response[
						'Medicare Evaluation'
					]
						? getAnalysisState.data.coverage_feedback_response[
								'Medicare Evaluation'
						  ]['Coverage']
						: 'No',
					getAnalysisState.data.coverage_feedback_response[
						'Medicare Evaluation'
					]
						? getAnalysisState.data.coverage_feedback_response[
								'Medicare Evaluation'
						  ]['Rationale']
						: '-',
				],
			});
			const isAnyArrayNotEmpty = Object.keys(pageContent)
				.filter((key) => key !== 'medicalCoverage')
				.some((key) => pageContent[key].length > 0);

			setShouldDisplayCards(isAnyArrayNotEmpty);
		}
		getAnalysisReset();
	}, [getAnalysisState.apiState]);

	return (
		<>
			{props.displayHeader ? (
				<></>
			) : (
				<>
					<Col lg={24} xl={24} xs={0} md={0}>
						<Header headerTag="Case Management" />
					</Col>
					<Col lg={0} xl={0} xs={24} md={24}>
						<HeaderFile activeNav="caseManagement" />
					</Col>
				</>
			)}

			<XContainer fluid>
				{checkModulePermissions('case-management').sub_modules.includes(
					'view-cases',
				) ? (
					<>
						<XCardBackBg className="mb-3">
							<Row>
								<Col xs={20}>
									<XButtonBack
										onClick={() => navigate(-1)}
										icon={<ArrowLeftOutlined />}
										size="large"
									/>

									<XEditTitle>View Case</XEditTitle>
								</Col>
								<Col
									xs={{ span: 1, offset: 1 }}
									md={{ span: 1, offset: 2 }}
									lg={{ span: 1, offset: 3 }}
									xl={{ span: 1, offset: 3 }}
								>
									<XButtonImg
										onClick={() =>
											navigate(`/case/edit/${id}`)
										}
										type="text"
									>
										<img src="/images/pencil.svg" alt="" />
									</XButtonImg>
								</Col>
							</Row>
						</XCardBackBg>

						<XCardsTransparent style={{ padding: '20px' }}>
							<Row
								style={{
									display: 'flex',
									justifyContent: 'space-between',
								}}
							>
								<Col xl={18} lg={18}>
									<label className="headerStyle me-4">
										{formData.patient_name}
									</label>
									<XTag className={formData.status}>
										{formData.status
											? toTitleCase(formData.status)
											: ''}
									</XTag>
								</Col>
								<Col xl={6} lg={6}>
									<p
										style={{
											color: '#171C26',
											fontFamily: 'Poppins',
											fontSize: '16px',
											fontStyle: 'normal',
											fontWeight: '600',
											lineHeight: '24px',
										}}
									>
										Treatment Date :&nbsp;
										<span style={{ fontWeight: 500 }}>
											{moment(
												formData.treatment_date,
											).format('MMMM Do, YYYY')}
										</span>
									</p>
								</Col>
							</Row>

							<Form layout="vertical" className="mt-5">
								<Row justify="space-between">
									<Col lg={2} xl={2} md={8} xs={12}>
										<Form.Item
											label="Gender"
											className="labelStyle"
										>
											<span className="subLabelStyle">
												{formData.gender
													? toTitleCase(
															formData.gender,
													  )
													: ''}
											</span>
										</Form.Item>
									</Col>
									<Col lg={4} xl={4} md={8} xs={12}>
										<Form.Item
											className="labelStyle"
											label="Date Of Birth"
										>
											<span className="subLabelStyle">
												{moment(
													formData.date_of_birth,
												).format('MMMM Do, YYYY')}
											</span>
										</Form.Item>
									</Col>
									<Col lg={2} xl={2} md={8} xs={12}>
										<Form.Item
											className="labelStyle"
											label="Age"
										>
											{' '}
											<span className="subLabelStyle">
												{moment(
													formData.date_of_birth,
												).fromNow(true)}
											</span>
										</Form.Item>
									</Col>
									<Col lg={4} xl={4} md={8} xs={12}>
										<Form.Item
											className="labelStyle"
											label="State"
										>
											<span className="subLabelStyle">
												{formData.state}
											</span>
										</Form.Item>
									</Col>
									<Col lg={4} xl={4} md={8} xs={12}>
										<Form.Item
											className="labelStyle"
											label="Allocated To"
										>
											{' '}
											<span className="subLabelStyle">
												{formData.allocated_to
													? formData.allocated_to
													: ''}
											</span>
										</Form.Item>
									</Col>
									<Col lg={4} xl={4} md={8} xs={12}>
										<Form.Item
											className="labelStyle"
											label="Created On"
										>
											{' '}
											<span className="subLabelStyle">
												{moment(
													formData.createdAt,
												).format('MMMM Do, YYYY')}
											</span>
										</Form.Item>
									</Col>
									<Col lg={4} xl={4} md={8} xs={12}>
										<Form.Item
											className="labelStyle"
											label="Last Date"
										>
											{' '}
											<span className="subLabelStyle">
												{moment(
													formData.updatedAt,
												).format('MMMM Do, YYYY')}
											</span>
										</Form.Item>
									</Col>
								</Row>
								<Col span={24} className="pb-0">
									<Form.Item
										label="Clinical Summary"
										className="labelStyle"
									>
										<XCardBackBg
											className="mb-3"
											style={{ minHeight: '7rem' }}
										>
											<XPara1>
												{formData.clinical_summary}
											</XPara1>
										</XCardBackBg>
									</Form.Item>
								</Col>
							</Form>
						</XCardsTransparent>
						{shouldDisplayCards && (
							<XCardsTransparent
								style={{ padding: '20px', marginTop: '20px' }}
							>
								<Row
									gutter={(15, 15)}
									className="align-items-center"
								>
									<Col xs={20} lg={20}>
										<Space align="center">
											<img
												className="iconResp"
												src="/images/past-icon.svg"
												alt=""
											/>
											<XTitleHead>
												<span className="color1">
													Past Clinical History
												</span>
											</XTitleHead>
										</Space>
									</Col>
									<Col
										xs={4}
										lg={4}
										className="text-end"
									></Col>
								</Row>
								{pageContent.pastHistory.length > 0
									? pageContent.pastHistory.map(
											(row, index) => {
												return (
													<Row
														gutter={(15, 15)}
														className="mt-3 align-items-center"
													>
														<Col xs={24} lg={20}>
															<XPara1>
																{row}
															</XPara1>
														</Col>
														<Col
															xs={24}
															lg={4}
															className="text-end"
														></Col>
													</Row>
												);
											},
									  )
									: '-'}

								<XDivider dashed="true" />

								<Row
									gutter={(15, 15)}
									className="align-items-center"
								>
									<Col xs={20} lg={20}>
										<Space align="center">
											<img
												className="iconResp"
												src="/images/present-icon.svg"
												alt=""
											/>
											<XTitleHead>
												<span className="color2">
													Present Complaint
												</span>
											</XTitleHead>
										</Space>
									</Col>
									<Col
										xs={4}
										lg={4}
										className="text-end"
									></Col>
								</Row>
								{pageContent.presentComplaint.length > 0
									? pageContent.presentComplaint.map(
											(row, index) => {
												return (
													<Row
														gutter={(15, 15)}
														className="mt-3 align-items-center"
													>
														<Col xs={24} lg={20}>
															<XPara1>
																{row}
															</XPara1>
														</Col>
														<Col
															xs={24}
															lg={4}
															className="text-end"
														></Col>
													</Row>
												);
											},
									  )
									: '-'}

								<XDivider dashed="true" />

								<Row
									gutter={(15, 15)}
									className="align-items-center"
								>
									<Col xs={20} lg={20} sm={20}>
										<Space align="center">
											<img
												className="iconResp"
												src="/images/procedure-icon.svg"
												alt=""
											/>
											<XTitleHead>
												<span className="color3">
													Recommended Procedure
												</span>
											</XTitleHead>
										</Space>
									</Col>
									<Col
										xs={4}
										lg={4}
										className="text-end"
									></Col>
								</Row>
								{pageContent.recommendedProcedure.length > 0
									? pageContent.recommendedProcedure.map(
											(row, index) => {
												return (
													<Row
														gutter={(15, 15)}
														className="mt-3 align-items-center"
													>
														<Col xs={24} lg={20}>
															<XPara1>
																{row}
															</XPara1>
														</Col>
														<Col
															xs={24}
															lg={4}
															className="text-end"
														></Col>
													</Row>
												);
											},
									  )
									: '-'}

								<XDivider dashed="true" />

								<Row
									gutter={(15, 15)}
									className="align-items-center"
								>
									<Col xs={24}>
										<Space align="center">
											<img
												className="iconResp"
												src={
													pageContent
														.medicalCoverage[0] ===
													'Yes'
														? '/images/coverage-icon.svg'
														: '/images/cross-icon.svg'
												}
												alt=""
											/>
											<XTitleHead>
												<span
													className={
														pageContent
															.medicalCoverage[0] ===
														'Yes'
															? 'color4'
															: 'color5'
													}
												>
													Medicare Coverage
												</span>
											</XTitleHead>
										</Space>
									</Col>
								</Row>
								<Row
									gutter={(15, 15)}
									className="mt-3 align-items-center"
								>
									<Col xs={24}>
										<XPara1>
											{pageContent.medicalCoverage[1]}
										</XPara1>
									</Col>
								</Row>
							</XCardsTransparent>
						)}
						{/* {items.length > 0 ? ( */}
						{/* <XCardsTransparent
							style={{ padding: '20px', marginTop: '20px' }}
						>
							<Row
								gutter={(15, 15)}
								className="align-items-center mb-4"
							>
								<Col xs={20} lg={20}>
									<Space align="center">
										<img
											className="iconResp"
											src="/images/time-fast 1.svg"
											alt=""
										/>
										<XTitleHead>
											<span style={{ color: '#7A82FD' }}>
												Case Timeline
											</span>
										</XTitleHead>
									</Space>
								</Col>
								<Col xs={4} lg={4} className="text-end"></Col>
							</Row>
							
							<Timeline status={formData?.status} />
						</XCardsTransparent> */}
					</>
				) : (
					<Result
						status="403"
						title="403"
						subTitle="Sorry, you are not authorized to access this page."
						extra={
							<Button
								type="primary"
								onClick={() => navigate('/')}
							>
								Back Home
							</Button>
						}
					/>
				)}
			</XContainer>
		</>
	);
};

const mapStateToProps = (state) => ({
	getUserDropdownState: state.getUserDropdown,
	getCaseByIdState: state.getCaseById,
	editCaseState: state.editCase,
	addCaseState: state.addCase,
	analyseCoverageState: state.analyseCoverage,
	reviseCoverageState: state.reviseCoverage,
	sendFeedbackState: state.sendFeedback,
	getAnalysisState: state.getAnalysis,
});

const mapDispatchToProps = (dispatch) => ({
	getUserDropdown: () => dispatch(getUserDropdownApi()),
	getUserDropdownReset: () => dispatch(getUserDropdownReset()),
	getCaseById: (data) => dispatch(getCaseByIdApi(data)),
	getCaseByIdReset: () => dispatch(getCaseByIdReset()),
	editCase: (data) => dispatch(editCaseApi(data)),
	editCaseReset: () => dispatch(editCaseReset()),
	addCase: (data) => dispatch(addCaseApi(data)),
	addCaseReset: () => dispatch(addCaseReset()),
	analyseCoverage: (params) => dispatch(analyseCoverageApi(params)),
	reviseCoverage: (params) => dispatch(reviseCoverageApi(params)),
	sendFeedback: (params) => dispatch(sendFeedbackApi(params)),
	getAnalysis: (params) => dispatch(getAnalysisApi(params)),
	getAnalysisReset: (params) => dispatch(getAnalysisReset(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CaseForm);
