export const defaultPageSize = 10;
export const defaultPageNumber = 1;

export const caseStatuses = [
	{ label: 'Pending', value: 'pending' },
	{ label: 'Successful', value: 'successful' },
	{ label: 'Allocated', value: 'allocated' },
	{ label: 'Rejected', value: 'rejected' },
	{ label: 'Analysed', value: 'analysed' },
	{ label: 'Letter Prepared', value: 'letter_prepared' },
];

export const reimbursementStatuses = [
	{ label: 'Pending', value: 'pending' },
	{ label: 'Successful', value: 'successful' },
];

export const medicalCoverage = [
	{ label: 'Yes', value: 'Yes' },
	{ label: 'No', value: 'No' },
];

export const dateFilters = [
	{ label: 'Last Month', value: 'last_month' },
	{ label: '2 Months', value: 'last_two_months' },
	{ label: '3 Months', value: 'last_three_months' },
	{ label: '4 Months', value: 'last_four_months' },
];

export const revenueCycleOptions = [
	{ label: 'Pre-Service', value: 'pre_service' },
	{ label: 'Post Claim', value: 'post_claim' },
	{ label: 'Part B Drugs', value: 'part_b_drugs' },
];
export const revenueCycleStage = [
	{
		label: 'Organization Determination',
		value: 'organization_determination',
	},
	{ label: 'First Appeal', value: 'first_appeal' },
	{ label: 'Second Appeal', value: 'second_appeal' },
	{ label: 'Third Appeal', value: 'third_appeal' },
	{ label: 'Fourth Appeal', value: 'fourth_appeal' },
	{ label: 'Judicial Review', value: 'judicial_review' },
];

export const titleOptions = [
	{
		label: 'Mr.',
		value: 'Mr.',
	},
	{
		label: 'Ms.',
		value: 'Ms.',
	},
	{
		label: 'Miss.',
		value: 'Miss.',
	},
	{
		label: 'Master',
		value: 'Master',
	},
	{
		label: 'Mrs.',
		value: 'Mrs.',
	},
	{
		label: 'Dr.',
		value: 'Dr.',
	},
];

export const processTypes = [
	{
		label: 'MA Standard Appeal Turn-Around-Time (TAT)',
		value: 'ma_standard',
	},
	{
		label: 'MA Expedited Appeal Turn-Around-Time (TAT)',
		value: 'ma_expedited',
	},
];

export const htmlConsent = `<html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=utf-8">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:"Calibri Light";
	panose-1:2 15 3 2 2 2 4 3 2 4;}
@font-face
	{font-family:"Segoe UI";
	panose-1:2 11 5 2 4 2 4 2 2 3;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin:0in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	layout-grid-mode:line;}
h1
	{mso-style-name:"Heading 1\,1";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:0in;
	text-indent:0in;
	page-break-after:avoid;
	font-size:10.0pt;
	font-family:"Times New Roman",serif;
	layout-grid-mode:line;
	font-weight:normal;
	text-decoration:underline;}
h2
	{mso-style-name:"Heading 2\,2";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:0in;
	text-indent:.5in;
	font-size:10.0pt;
	font-family:"Times New Roman",serif;
	layout-grid-mode:line;
	font-weight:normal;}
h3
	{mso-style-name:"Heading 3\,3";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:0in;
	text-indent:1.0in;
	font-size:10.0pt;
	font-family:"Times New Roman",serif;
	layout-grid-mode:line;
	font-weight:normal;}
h4
	{mso-style-name:"Heading 4\,4";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:0in;
	text-indent:1.0in;
	font-size:10.0pt;
	font-family:"Times New Roman",serif;
	layout-grid-mode:line;
	font-weight:normal;}
h5
	{mso-style-name:"Heading 5\,5";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:0in;
	text-indent:0in;
	font-size:10.0pt;
	font-family:"Times New Roman",serif;
	layout-grid-mode:line;
	font-weight:normal;
	font-style:italic;}
h6
	{mso-style-name:"Heading 6\,6";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:0in;
	text-indent:0in;
	font-size:10.0pt;
	font-family:"Times New Roman",serif;
	layout-grid-mode:line;
	font-weight:normal;
	text-decoration:underline;}
p.MsoHeading7, li.MsoHeading7, div.MsoHeading7
	{mso-style-name:"Heading 7\,7";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:0in;
	text-indent:0in;
	font-size:10.0pt;
	font-family:"Times New Roman",serif;
	layout-grid-mode:line;
	font-style:italic;
	text-decoration:underline;}
p.MsoHeading8, li.MsoHeading8, div.MsoHeading8
	{mso-style-name:"Heading 8\,8";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:0in;
	text-indent:0in;
	font-size:10.0pt;
	font-family:"Times New Roman",serif;
	layout-grid-mode:line;
	font-weight:bold;
	font-style:italic;}
p.MsoHeading9, li.MsoHeading9, div.MsoHeading9
	{mso-style-name:"Heading 9\,9";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:0in;
	text-indent:0in;
	font-size:10.0pt;
	font-family:"Times New Roman",serif;
	layout-grid-mode:line;
	font-style:italic;}
p.MsoTitle, li.MsoTitle, div.MsoTitle
	{mso-style-name:"Title\,t";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:0in;
	text-align:center;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	layout-grid-mode:line;
	font-weight:bold;
	text-decoration:underline;}
p.MsoBodyText, li.MsoBodyText, div.MsoBodyText
	{mso-style-name:"Body Text\,b";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:0in;
	font-size:10.0pt;
	font-family:"Times New Roman",serif;
	layout-grid-mode:line;}
p.MsoBodyTextFirstIndent, li.MsoBodyTextFirstIndent, div.MsoBodyTextFirstIndent
	{mso-style-name:"Body Text First Indent\,bfi";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:0in;
	text-indent:.5in;
	font-size:10.0pt;
	font-family:"Times New Roman",serif;
	layout-grid-mode:line;}
 /* Page Definitions */
 @page WordSection1
	{size:8.5in 11.0in;
	margin:1.0in 1.0in 1.0in 1.0in;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0in;}
ul
	{margin-bottom:0in;}
-->
</style>

</head>

<body lang=EN-US link="#0563C1" vlink="#954F72" style='word-wrap:break-word'>

<div class=WordSection1>

<p class=MsoTitle><a name="_dxtcompanion_actionscomplete"><span
style='font-size:10.0pt'>BETA TEST AGREEMENT</span></a></p>

<p class=MsoBodyText style='text-align:justify'><b><u>IMPORTANT</u></b><b>:
PLEASE READ THIS BETA TEST AGREEMENT CAREFULLY. ACCESSING OR USING SERVICES OF ENCORE
HEALTH SOLUTIONS CONSTITUTES ACCEPTANCE OF THIS AGREEMENT. </b></p>

<p class=MsoBodyText style='text-align:justify'>BY ACCESSING OR USING SERVICES
OF ENCORE HEALTH SOLUTIONS OR OTHERWISE EXPRESSING YOUR AGREEMENT TO THE TERMS
CONTAINED IN THE AGREEMENT, YOU INDIVIDUALLY AND ON BEHALF OF THE BUSINESS OR
ORGANIZATION THAT YOU REPRESENT (“<u>LICENSEE</u>”) CONSENT TO BE BOUND BY THIS
AGREEMENT. IF YOU DO NOT OR CANNOT AGREE TO THE TERMS CONTAINED IN THIS AGREEMENT,
THEN DO NOT ACCESS OR USE ANY SERVICES OF ENCORE HEALTH SOLUTIONS.</p>

<p class=MsoBodyTextFirstIndent align=center style='text-align:center;
text-indent:0in'><u>Introduction</u></p>

<p class=MsoBodyTextFirstIndent style='text-align:justify'>1.             Encore
Health Solutions, Inc., a Delaware corporation (“<u>Encore</u>”) has developed
and is developing an artificial intelligence-powered platform designed to
optimize and prepare an organization’s revenue cycle for value-based care
reimbursement models (the “<u>Platform</u>”). </p>

<p class=MsoBodyTextFirstIndent style='text-align:justify'>2.             The
Platform is being made available on a limited basis for testing by Licensee. Licensee
would like to evaluate the Platform in accordance with the Beta Test Objectives
and on the terms and conditions set forth herein (the “<u>Beta Test</u>”). The
“<u>Beta Test Objectives</u>” are for: (a) Licensee to evaluate the Platform as
a potential solution for use by Licensee’s organization; and (b) Encore to test
the Platform and receive feedback on potential improvements to the Platform and
on related documentation and processes.</p>

<p class=MsoBodyTextFirstIndent>3.             During the Beta Test, Licensee
will only utilize de-identified data in order to conduct the Beta Test. </p>

<p class=MsoBodyTextFirstIndent>4.             This Beta Test Agreement (the “<u>Agreement</u>”)
is entered into on the date that Licensee clicks “I Accept” to accept this
Agreement (such date, the “<u>Effective Date</u>”). Each of Encore and Licensee
is a “<u>Party</u>”, and together they are the “<u>Parties</u>”.</p>

<p class=MsoBodyTextFirstIndent>The Parties hereby agree as follows:</p>

<h1 style='margin-left:0in;text-indent:0in'><u><span style='text-decoration:
none'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></u>Licenses.</h1>

<h2 style='margin-left:0in;text-align:justify'>1.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><u>Evaluation</u>. Subject to the terms and conditions contained herein,
Encore grants Licensee and Licensee accepts, a non-exclusive, non-transferable right
to use and test the Platform solely in the United States of America, for the
sole purpose of evaluating the Platform. Except for the express grant of rights
set forth herein, Encore does not grant any right or license by implication,
estoppel or otherwise.</h2>

<h2 style='margin-left:0in;text-align:justify'>1.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><u>No Rights to Distribute</u>. Licensee is not granted any rights to
distribute or otherwise disclose the Platform, any portion or component of the Platform
or any information regarding the Platform under this Agreement. </h2>

<p class=MsoBodyText style='text-align:justify'>Licensee shall not, and shall
not cause or permit, modification, adaptation, making derivative works of or
reverse engineering, disassembling or decompiling of the Platform or any
component thereof or of the Platform or any component thereof.</p>

<h2 style='margin-left:0in;text-align:justify'>1.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><u>Limitations</u>. Licensee will not directly or indirectly: (a) copy,
display, transfer, distribute or use the Platform in any manner or for any
purpose not expressly authorized by this Agreement; (b) prepare derivative
works of or otherwise adapt, modify, or translate the Platform; (c) reverse
engineer, decompile, reconstruct, remanufacture, or disassemble the Platform,
or any portion thereof; (d) alter, remove, obscure, erase, deface, or hide from
view any copyright, trademark, or other proprietary rights notice contained in
or incorporated into the Platform or its packaging; (e) use, rent, or lend the Platform
in connection with a service bureau, time-sharing, ASP, software-as-a-service,
or similar arrangement or pledge, lease, share, or otherwise make available the
Platform; (f) distribute or resell the Platform; (g) use the Platform in any
way that violates any individual’s privacy rights or other rights of any party;
(h) disclose any results of testing or benchmarking the Platform, or of any
part of the Beta Test, to any third party except as may be expressly authorized
by Encore; (i) use the Platform in any way that violates any applicable law or
regulation; (j) attempt any of the foregoing; or (k) assist any third party
with any of the foregoing.</h2>

<h1 style='margin-left:0in;text-indent:0in'><u><span style='text-decoration:
none'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></u>Responsibilities of the Parties.</h1>

<h2 style='margin-left:0in;text-align:justify'>2.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><u>Encore Responsibilities</u>. Encore will perform or provide the
following:</h2>

<h3 style='margin-left:0in;text-align:justify'>2.1.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><u>Access to Platform</u>. Encore shall provide Licensee with access to the
Platform promptly after Licensee accepts this Agreement. In addition, Encore
shall deliver to Licensee such documentation as Encore deems necessary for the planned
use and configuration of the Platform, without charge to Licensee, for the
duration of the Term. The Platform and all such documentation shall be
Confidential Information of Encore, which shall be used only for the Beta Test,
and shall be restricted to those persons within Licensee’s organization that
are assigned to the Beta Test. Licensee acknowledges that the Platform and all
related materials are beta-level services and may not perform as expected.</h3>

<h3 style='margin-left:0in;text-align:justify'>2.1.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><u>Encore Assistance</u>. Encore may, at Encore’s option, provide reasonable
support for the resolution of issues that arise during the Beta Test. This may
include the review of Licensee’s test plans. Licensee understands that Encore
shall be able to review Licensee’s use of the Platform, in part for the purpose
of providing better support.</h3>

<h2 style='margin-left:0in'>2.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><u>Licensee’s Responsibilities.</u> Licensee shall perform or provide the
following:</h2>

<h3 style='margin-left:0in;text-align:justify'>2.2.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><u>Equipment</u>. Licensee shall be responsible for procuring all
hardware and software necessary to use the Platform for the Beta Test.</h3>

<h3 style='margin-left:0in;text-align:justify'>2.2.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><u>Test Duration</u>. Licensee shall use and test the Platform during
the entire Term.</h3>

<h3 style='margin-left:0in;text-align:justify'>2.2.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><u>Feedback</u>. Licensee shall provide all material feedback regarding
the Platform to Encore, including without limitation usability, bug reports and
comprehensive test results during the Beta Test in a format specified by Encore
from time to time (“<u>Test Results</u>”), and shall keep Encore apprised of
all such information which may arise during the Beta Test (collectively, “<u>Feedback</u>”).
Feedback shall be provided orally twice weekly and both Parties agree to set up
mutually agreeable times to exchange Feedback. Upon the request of Encore from
time to time, Licensee will provide a written summary of Licensee’s Feedback
including, without limitation, information on ease of use of the Platform,
compatibility issues, performance relative to expectations, ease of use of the
documentation, Test Results and general experience of Licensee with the Platform,
as applicable. </h3>

<h3 style='margin-left:0in;text-align:justify'>2.2.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><u>Final Feedback Report</u>. Within ten (10) days after the expiration
or termination of this Agreement, Licensee shall provide Encore with a final
written Feedback report containing Licensee’s evaluation of the Platform’s
functionality, publications, performance and overall support. Licensee<span
style='color:black'> further agrees that Encore may freely use, disclose,
reproduce, license, distribute and otherwise commercialize Feedback in any product,
technology, service, specification or other documentation, including without
limitation in the Platform.</span></h3>

<h3 style='margin-left:0in;text-align:justify'>2.2.5<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><u>Licensee Assistance</u>. Licensee shall assist Encore with the
determination and resolution of problems associated with the Platform, which
may include collecting and furnishing data to Encore.</h3>

<h3 style='margin-left:0in;text-align:justify'>2.2.6<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><u>Availability</u>. Licensee shall ensure Licensee is reasonably
available to Encore to discuss the Beta Test, including without limitation
Feedback.</h3>

<h1 style='margin-left:0in;text-indent:0in'><u><span style='text-decoration:
none'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></u>Ownership of Platform. </h1>

<h2 style='margin-left:0in;text-align:justify'>3.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><u>Platform</u>. Encore shall retain all right, title and interest in and
to the Platform and all Intellectual Property therein and Licensee shall not
take any action inconsistent with such title and ownership. Licensee shall not
have any ownership interest in any element, segment or component of the Platform.
“<u>Intellectual Property</u>” means all patent, copyright, trademark, trade
secret or any other industrial or intellectual property rights.</h2>

<h2 style='margin-left:0in;text-align:justify'>3.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><u>Improvements</u>. Encore shall be the sole owner of all right, title
and interest in and to all improvements to the Platform or the Platform, including
without limitation any improvement made as a result of any Feedback. Licensee
hereby assigns all of its right, title and interest in and to any Intellectual
Property in any Feedback that Licensee provides to Encore. Licensee shall
execute such other documentation to evidence or perfect Encore’s ownership
interests in and to any such Feedback as may be requested by Encore from time
to time.</h2>

<h1 style='margin-left:0in;text-indent:0in'><u><span style='text-decoration:
none'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></u>Term and Termination.</h1>

<h2 style='margin-left:0in;text-align:justify'>4.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><u>Term</u>. This Agreement shall commence on the Effective Date and
shall continue until the date that is two (2) months after the Effective Date
(the “Initial Term”) and thereafter shall renew automatically for successive
one (1) month terms (each a “<u>Renewal Term</u>” and together with the Initial
Term, the “<u>Term</u>”), unless earlier terminated pursuant to Section 4.2.</h2>

<h2 style='margin-left:0in;text-align:justify'>4.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><u>Termination</u>. This Agreement may be terminated at any time for any
or no reason by either Party upon notice to the other Party.</h2>

<h2 style='margin-left:0in'>4.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><u>Effects of Termination</u>.</h2>

<h4 style='margin-left:0in;text-align:justify'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Upon expiration or termination of this Agreement, Licensee shall have no
further right to use or test the Platform.</h4>

<h4 style='margin-left:0in;text-align:justify'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>The provisions of Sections 2.2.4 (Final Feedback Report), 5
(Confidentiality), 6 (Disclaimer of Warranty), 7 (Infringement
Indemnification), 8 (Indemnification by Licensee), 9 (Limitations on
Liability), 10 (Compliance with Laws), 11 (Notices), 12 (General Provisions)
and this Section&nbsp;4.3 (Effects of Termination) shall survive any
termination or expiration of this Agreement according to their terms.</h4>

<h2 style='margin-left:0in;text-align:justify'>4.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><u>Following Term</u>. Following the expiration or termination of this
Agreement, Licensee and Encore may agree to a further commercial license of the
Platform, but neither Party is under an obligation to do so.</h2>

<h1 style='margin-left:0in;text-indent:0in'><u><span style='text-decoration:
none'>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></u>Confidentiality. </h1>

<h2 style='margin-left:0in'>5.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><u>Confidential Information</u>. Encore may disclose to Licensee certain
confidential information which may be identified as such in writing or may
reasonably be considered confidential (“<u>Confidential Information</u>”). All
Feedback shall constitute Confidential Information of Encore.</h2>

<h2 style='margin-left:0in;text-align:justify'>5.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><u>Protection of Proprietary Information</u>. Licensee agrees to protect
the confidentiality of Confidential Information with at least the same degree
of care that Licensee utilizes with respect to Licensee’s own similar
proprietary information, but in no event less than reasonable and customary
care for such materials, including without limitation agreeing:</h2>

<h4 style='margin-left:0in;text-align:justify'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>not to disclose or otherwise permit any other person or entity access
to, in any manner, any Confidential Information, or any part thereof in any
form whatsoever, except that such disclosure or access shall be permitted to an
employee of Licensee requiring access to such Confidential Information in the
course of his or her employment in connection with this Agreement and who has
signed an agreement obligating such employee to maintain the confidentiality of
the confidential information of third parties in Licensee’s possession, and Licensee
shall be responsible for any action or omission of any such employee that would
breach this Agreement;</h4>

<h4 style='margin-left:0in;text-align:justify'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>to notify Encore promptly and in writing of the circumstances
surrounding any suspected possession, use or knowledge of any Confidential
Information or any part thereof at any location or by any person or entity
other than those authorized by this Agreement; and</h4>

<h4 style='margin-left:0in;text-align:justify'>(c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>not to use any Confidential Information for any purpose other than as
explicitly set forth herein.</h4>

<h2 style='margin-left:0in;text-align:justify'>5.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><u>Injunctive Relief</u>. Because the unauthorized use, transfer or
dissemination of any Confidential Information may substantially diminish the
value of such information and may irreparably harm Encore, Encore shall,
without limiting its other rights or remedies, be entitled to seek equitable
relief, including without limitation injunctive relief, without posting a bond
therefor.</h2>

<h1 style='margin-left:0in;text-align:justify;text-indent:0in'><u><span
style='text-decoration:none'>6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></u>Disclaimer of Warranty<span style='text-decoration:none'>. THE
PLATFORM IS BEING PROVIDED “AS IS” WITHOUT WARRANTY OF ANY KIND AND ENCORE
HEREBY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, ORAL OR WRITTEN,
WITH RESPECT TO THE PLATFORM, INCLUDING WITHOUT LIMITATION ANY IMPLIED WARRANTY
OF MERCHANTABILITY, NON-INFRINGEMENT, FITNESS FOR ANY PARTICULAR PURPOSE,
INFORMATIONAL CONTENT, INTERFERENCE WITH ENJOYMENT OR THAT THE PLATFORM IS
ERROR-FREE OR FREE FROM DEFECTS.</span> </h1>

<h1 style='margin-left:0in;text-indent:0in'><u><span style='text-decoration:
none'>7.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></u>Infringement Indemnification<span style='text-decoration:
none'>.</span></h1>

<h2 style='margin-left:0in;text-align:justify'>7.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Except as provided below, Encore shall defend and indemnify Licensee
from and against any damages, liabilities, costs and expenses (including
reasonable attorneys’ fees) arising out of any claim that the Platform
infringes a valid U.S. patent, copyright or other intellectual property right of
a third party or misappropriates a trade secret of a third party, provided that:
(a) Licensee provides Encore prompt written notice thereof and reasonable
cooperation, information, and assistance in connection therewith; and (b) Encore
shall have sole control and authority with respect to the defense, settlement
or compromise thereof. Should the Platform become or, in Encore’s opinion, be
likely to become the subject of an injunction preventing its use as
contemplated herein, Encore may, at its option: (i) procure for Licensee the
right to continue using the Platform; (ii) replace or modify the Platform so that
it becomes non-infringing; or (iii) terminate Licensee’s license to the Platform.</h2>

<h2 style='margin-left:0in;text-align:justify'>7.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Encore shall have no liability or obligation to Licensee hereunder with
respect to any patent, copyright, trade secret or other intellectual property
infringement, misappropriation or claim thereof based on: (a) use of the Platform
by Licensee in combination with software not provided by Encore; or (b) any modification,
alteration or enhancement of the Platform not created by or for Encore. </h2>

<h2 style='margin-left:0in;text-align:justify'>7.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>This Section 7 states the entire liability of Encore with respect to
infringement of patents, copyrights, trade secrets and other intellectual
property rights by the Platform or any part thereof or by its operation.</h2>

<h1 style='margin-left:0in;text-align:justify;text-indent:0in'><u><span
style='text-decoration:none'>8.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></u>Indemnification by Licensee<span style='text-decoration:none'>.
Licensee shall defend and indemnify Encore from and against all damages,
liabilities, costs and expenses (including reasonable attorneys’ fees) arising
out of any claim by a third party relating to Licensee’s breach of this
Agreement or Licensee’s negligence, fraud or willful misconduct, provided that:
(a) Encore provides Licensee prompt written notice thereof and reasonable
cooperation, information, and assistance in connection therewith; and (b)
Licensee shall have sole control and authority with respect to the defense,
settlement or compromise thereof.</span></h1>

<h1 style='margin-left:0in;text-indent:0in'><u><span style='text-decoration:
none'>9.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></u>Limitations on Liability<span style='text-decoration:none'>.</span></h1>

<h2 style='margin-left:0in;text-align:justify'>9.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>EXCLUDING INDEMNIFICATION OBLIGATIONS, ENCORE’S LIABILITY FOR DAMAGES TO
LICENSEE FOR ANY CAUSE WHATSOEVER, REGARDLESS OF THE FORM OF ANY CLAIM OR
ACTION, SHALL NOT EXCEED THE AMOUNTS PAID TO ENCORE HEREUNDER. EXCEPT FOR
DAMAGES ARISING FROM A BREACH OF SECTION 2.2.3, 2.2.4, 3 OR 5, IN NO EVENT
SHALL EITHER PARTY BE LIABLE TO THE OTHER PARTY FOR ANY LOSS OF DATA, PROFITS
OR USE OF THE PLATFORM, OR FOR ANY SPECIAL, INCIDENTAL, CONSEQUENTIAL, PUNITIVE
OR OTHER INDIRECT DAMAGES, OR ANY LOST PROFITS OR LOST REVENUES, ARISING OUT OF
OR IN CONNECTION WITH THE USE OR PERFORMANCE OF THE PLATFORM OR OTHERWISE IN
RELATION TO THIS AGREEMENT.</h2>

<h2 style='margin-left:0in'>9.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Section 9.1 shall apply to the maximum extent permitted by applicable
Law.</h2>

<h2 style='margin-left:0in;text-align:justify'>9.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Licensee will immediately inform Encore as soon as Licensee becomes
aware of any threatened or actual liability claim by a third party relating to
the Platform.</h2>

<h1 style='margin-left:0in;text-indent:0in'><u><span style='text-decoration:
none'>10.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></u>Compliance with Laws. </h1>

<h2 style='margin-left:0in;text-align:justify'>10.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><u>Export</u>. Licensee shall not export, directly or indirectly, the Platform,
or other information or materials provided by Encore hereunder, to any country
for which the United States or any other relevant jurisdiction requires any
export license or other governmental approval at the time of export without
first obtaining such license or approval. It shall be Licensee’s responsibility
to comply with all applicable United States export Laws, and Licensee shall
defend and indemnify Encore from and against any damages, fines, penalties,
assessments, liabilities, costs and expenses (including reasonable attorneys’
fees and court costs) arising out of any claim that the Platform or other
information or materials provided by Encore hereunder were exported or
otherwise shipped or transported in violation of applicable Laws. “<u>Laws</u>”
means any declaration, decree, directive, legislative enactment, order, common
law, ordinance, regulation, rule, guideline, guidance or other binding
restriction or requirement of or by any governmental authority, as may be
amended or replaced from time to time.</h2>

<h2 style='margin-left:0in'>10.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><u>Compliance with Laws of Other Jurisdictions</u>. Licensee shall comply
with all applicable Laws and industry standards with respect to the Platform or
the use thereof, and the performance by Licensee of its obligations hereunder,
existing in any jurisdiction in which Licensee uses the Platform.</h2>

<h1 style='margin-left:0in;text-indent:0in'><u><span style='text-decoration:
none'>11.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></u>Notices. </h1>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;text-indent:
.5in'><span style='font-size:10.0pt'>Any notice or communication from one Party
to the other Party shall be in writing and either personally delivered or sent
via certified mail, postage prepaid and return receipt requested addressed, to
such other Party at the address specified below or such other address as either
Party may from time to time designate in writing to the other Party.</span></p>

<table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
 style='margin-left:41.4pt;border-collapse:collapse'>
 <tr>
  <td width=108 valign=top style='width:81.0pt;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal><span style='font-size:10.0pt'>If to Encore:</span></p>
  </td>
  <td width=475 valign=top style='width:4.95in;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal><span style='font-size:10.0pt'>Encore Health Solutions,
  Inc.</span></p>
  <p class=MsoNormal><span style='font-size:10.0pt'>Attn: Zain Patrawala</span></p>
  <p class=MsoNormal><span style='font-size:10.0pt'>321 High School Road NE,
  Suite D3,</span></p>
  <p class=MsoNormal><span style='font-size:10.0pt'>Bainbridge Island, WA 98110</span></p>
  <p class=MsoNormal style='margin-bottom:24.0pt'><span style='font-size:10.0pt'>&nbsp;</span></p>
  </td>
 </tr>
 <tr>
  <td width=108 valign=top style='width:81.0pt;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal><span style='font-size:10.0pt'>with a copy to:</span></p>
  </td>
  <td width=475 valign=top style='width:4.95in;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal><span style='font-size:10.0pt'>Wilmer Cutler Pickering
  Hale and Dorr LLP</span></p>
  <p class=MsoNormal><span style='font-size:10.0pt'>60 State Street</span></p>
  <p class=MsoNormal><span style='font-size:10.0pt'>Boston, Massachusetts 02109</span></p>
  <p class=MsoNormal style='margin-bottom:24.0pt'><span style='font-size:10.0pt'>Attn:
  Ariel Soiffer, Esq.</span></p>
  </td>
 </tr>
 <tr>
  <td width=108 valign=top style='width:81.0pt;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal><span style='font-size:10.0pt'>If to Licensee:</span></p>
  </td>
  <td width=475 valign=top style='width:4.95in;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='margin-bottom:24.0pt'><span style='font-size:10.0pt'>The
  address submitted by Licensee during the registration process to license the
  Platform</span></p>
  </td>
 </tr>
</table>

<p class=MsoNormal style='margin-bottom:12.0pt'><span style='font-size:10.0pt'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;text-indent:
.5in'><span style='font-size:10.0pt'>No change of address shall be binding upon
the other Party until notice thereof is received by such Party at the address
show herein. All notices shall be in English, in writing and effective upon
receipt. </span></p>

<h1 style='margin-left:0in;text-indent:0in'><u><span style='text-decoration:
none'>12.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></u>General Provisions.</h1>

<h2 style='margin-left:0in;text-align:justify'>12.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><u>Force Majeure</u>. In the event that either Party is prevented from
performing, or is unable to perform, any of its obligations under this
Agreement due to any cause beyond the reasonable control of the Party invoking
this provision, the affected Party’s performance shall be excused and the time
for performance shall be extended for the period of delay or inability to
perform due to such occurrence.</h2>

<h2 style='margin-left:0in;text-align:justify'>12.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><u>Publicity</u>. Licensee agrees that Encore may issue a news release
or other public announcement relating to the performance of the Platform which
may include Feedback or other results of the Beta Test, but shall reasonably
consider any input of Licensee in such a news release or other public
announcement. Licensee shall not issue or provide any information for the
purpose of any news release or other public announcement relating to this
Agreement.</h2>

<h2 style='margin-left:0in;text-align:justify'>12.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><u>Waiver</u>. The waiver by either Party of a breach or a default of
any provision of this Agreement by the other Party shall not be construed as a
waiver of any succeeding breach of the same or any other provision, nor shall
any delay or omission on the part of either Party to exercise or avail itself
of any right, power or privilege that it has, or may have hereunder, operate as
a waiver of any right, power or privilege by such Party.</h2>

<h2 style='margin-left:0in;text-align:justify'>12.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><u>No Agency; Independent Contractors; Third Party Beneficiaries</u>. Nothing
contained in this Agreement shall be deemed to imply or constitute either Party
as the agent or representative of the other Party, or both Parties as joint
venturers or partners for any purpose. Except for the Parties, this Agreement
is not intended to create any right or cause of action in or on behalf of any
other person or entity.</h2>

<h2 style='margin-left:0in;text-align:justify'>12.5<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><u>Governing Law</u>. This Agreement shall be governed by and construed
in accordance with the Laws of the State of New York, without regard to its conflict
of Law provisions.</h2>

<h2 style='margin-left:0in;text-align:justify'>12.6<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><u>Entire Agreement; Amendment</u>. This Agreement constitutes the
entire agreement between the Parties with regard to the subject matter hereof. No
consent, modification or change of any term of this Agreement shall bind either
Party unless in writing signed by both Parties. No waiver of any term of this
Agreement shall bind the Party making such waiver unless in writing and signed
by the Party making such waiver. Any such waiver shall be effective only in the
specific instance and for the specific purpose given. </h2>

<h2 style='margin-left:0in;text-align:justify'>12.7<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><u>Headings</u>. Captions and headings contained in this Agreement have
been included for ease of reference and convenience and shall not be considered
in interpreting or construing this Agreement.</h2>

<h2 style='margin-left:0in;text-align:justify'>12.8<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><u>Costs, Expenses and Attorneys’ Fees</u>. If either Party commences
any action or proceeding against the other Party to enforce or interpret this
Agreement, the prevailing Party in such action or proceeding shall be entitled
to recover from the other Party the actual costs, expenses and reasonable
attorneys’ fees (including all related costs and expenses), incurred by such
prevailing Party in connection with such action or proceeding and in connection
with obtaining and enforcing any judgment or order thereby obtained. </h2>

<h2 style='margin-left:0in;text-align:justify'>12.9<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><u>Assignment</u>. This Agreement, and the rights and obligations hereunder,
may not be assigned, in whole or in part by Licensee.</h2>

</div>

</body>

</html>
`;
export const base_urls = {
	"local": "http://localhost:5000",
	"dev": "https://decodex-dev-api.encore.health",
	"uat": "https://decodex-stage-api.encore.health",
	"prod": "https://decodex-api.encore.health",
}

export const s3_urls = {
    "local": "https://dev-decodex.s3.amazonaws.com",
    "dev": "https://dev-decodex.s3.amazonaws.com",
    "uat": "https://uat-decodex.s3.amazonaws.com",
    "prod": "https://prod-decodex.s3.amazonaws.com",
}
