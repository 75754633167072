import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from 'react-router-dom';
import {
	XNavbar,
	XSpace,
	XDropdown,
	XAvatar,
	XDownOutlined,
} from './HeaderStyle';
import { MenuOutlined, CloseOutlined } from '@ant-design/icons';
import { XContainer } from '../../styles/GlobalStyle';
// actions
import { logoutApi, logoutReset } from '../../actions/logoutAction';
import { notification } from 'antd';
import config from '../../config';
import { checkModulePermissions, getUserProfile } from '../../utils/helper';

const HeaderFile = (props) => {
	const { logout, logoutReset, logoutState } = props;
	const navigate = useNavigate();
	const [hamberToggle, setHamberToggle] = useState(false);
	const toggleHamburger = () => {
		setHamberToggle(!hamberToggle);
	};
	const items = [
		{
			key: '1',
			label: <Link to="/profile">My Profile</Link>,
			icon: (
				<img
					className="img-fluid"
					src="/images/profile-icon.svg"
					alt=""
				/>
			),
		},
		{
			type: 'divider',
		},
		{
			key: '2',
			label: <Link to="/change-password">Reset Password</Link>,
			icon: <img className="img-fluid" src="/images/reset.svg" alt="" />,
		},
		{
			type: 'divider',
		},
		{
			key: '3',
			label: (
				<Link
					onClick={() => {
						console.log('loks');
						logout();
					}}
				>
					Log Out
				</Link>
			),
			icon: <img className="img-fluid" src="/images/logout.svg" alt="" />,
		},
	];

	// use effect
	useEffect(() => {
		if (logoutState.apiState === 'success') {
			localStorage.removeItem('accessToken');
			localStorage.removeItem('email');
			localStorage.removeItem('username');
			localStorage.removeItem('refreshToken');
			localStorage.removeItem('expiresAt');
			localStorage.removeItem('changePassword');
			localStorage.removeItem('profile');
			localStorage.removeItem('permissions');
			localStorage.removeItem('role');
			logoutReset();
			navigate('/login');
		} else if (logoutState.apiState === 'error') {
			notification.error({ message: logoutState.message });
			logoutReset();
		}
	}, [logoutState]);

	return (
		<div className="marginTopMobile">
			<XNavbar expand={'xl'} className="mb-3">
				<XContainer fluid>
					<Navbar.Brand href="/">
						<img
							className="img-fluid"
							src="/images/logo.svg"
							alt=""
						/>
					</Navbar.Brand>

					<Navbar.Toggle
						onClick={toggleHamburger}
						// aria-controls={`offcanvasNavbar-expand-xl`}
					>
						{hamberToggle ? <CloseOutlined /> : <MenuOutlined />}
					</Navbar.Toggle>

					<Navbar.Offcanvas
						id={`offcanvasNavbar-expand-xl`}
						aria-labelledby={`offcanvasNavbarLabel-expand-xl`}
						placement="top"
						visible="true"
					>
						<Offcanvas.Body>
							<Nav className="justify-content-center centerMenuSpace flex-grow-1">
								{checkModulePermissions('dashboard')
									.authorized && (
									<Link
										className={
											props.activeNav === 'dashboard'
												? 'nav-link active'
												: 'nav-link'
										}
										to="/"
									>
										Dashboard
									</Link>
								)}
								{checkModulePermissions('case-management')
									.authorized && (
									<Link
										className={
											props.activeNav === 'caseManagement'
												? 'nav-link active'
												: 'nav-link'
										}
										to="/case-management"
									>
										Case Management
									</Link>
								)}
								{(checkModulePermissions('user-management')
									.authorized ||
									checkModulePermissions('role-management')
										.authorized) && (
									<Link
										className={
											props.activeNav ===
											'userRoleManagement'
												? 'nav-link active'
												: 'nav-link'
										}
										to="/user-role-management"
									>
										User & Role Management
									</Link>
								)}
								{/* <Link
									className={
										props.activeNav === 'CaseClosure'
											? 'nav-link active'
											: 'nav-link'
									}
									to="/case-allocation"
								>
									Case Allocation
								</Link> */}
								{checkModulePermissions('open-analysis')
									.authorized && (
									<Link
										className={
											props.activeNav ===
											'analyseCoverage'
												? 'nav-link active'
												: 'nav-link'
										}
										to="/open-analysis"
									>
										Open Analysis
									</Link>
								)}

								{checkModulePermissions('case-analysis')
									.authorized && (
									<Link
										className={
											props.activeNav === 'caseAnalysis'
												? 'nav-link active'
												: 'nav-link'
										}
										to="/case-analysis"
									>
										Case Analysis
									</Link>
								)}
								{checkModulePermissions('case-closure')
									.authorized && (
									<Link
										className={
											props.activeNav === 'caseClosure'
												? 'nav-link active'
												: 'nav-link'
										}
										to="/case-closure"
									>
										Case Closure
									</Link>
								)}
								{checkModulePermissions('feedback-analysis')
									.authorized && (
									<Link
										className={
											props.activeNav ===
											'feedbackAnalysis'
												? 'nav-link active'
												: 'nav-link'
										}
										to="/feedback"
									>
										Feedback Analysis
									</Link>
								)}
								{checkModulePermissions('appeal-letter')
									.authorized && (
									<Link
										className={
											props.activeNav === 'appealLetter'
												? 'nav-link active'
												: 'nav-link'
										}
										to="/appeal-letter"
									>
										Appeal Letter
									</Link>
								)}
								{checkModulePermissions(
									'insurance-company-management',
								).authorized && (
									<Link
										className={
											props.activeNav ===
											'insuranceCompany'
												? 'nav-link active'
												: 'nav-link'
										}
										to="/insurance-company"
									>
										Insurance Company
									</Link>
								)}
								{checkModulePermissions(
									'organization-management',
								).authorized && (
									<Link
										className={
											props.activeNav === 'organization'
												? 'nav-link active'
												: 'nav-link'
										}
										to="/organization"
									>
										Organization
									</Link>
								)}
								{checkModulePermissions('hospital-management')
									.authorized && (
									<Link
										className={
											props.activeNav === 'hospital'
												? 'nav-link active'
												: 'nav-link'
										}
										to="/hospital"
									>
										Hospital
									</Link>
								)}
								{checkModulePermissions('region-management')
									.authorized && (
									<Link
										className={
											props.activeNav === 'regions'
												? 'nav-link active'
												: 'nav-link'
										}
										to="/regions"
									>
										Regions
									</Link>
								)}

								<Link
									className={
										props.activeNav === 'caseLiterature'
											? 'nav-link active'
											: 'nav-link'
									}
									to="/case-literature"
								>
									Case Literature
								</Link>
								{checkModulePermissions('payer-configuration')
									.authorized && (
									<Link
										className={
											props.activeNav ===
											'PayerConfiguration'
												? 'nav-link active'
												: 'nav-link'
										}
										to="/payer-configuration"
									>
										Payer Configuration
									</Link>
								)}
								{checkModulePermissions('reimbursements')
									.authorized && (
									<Link
										className={
											props.activeNav ===
											'Reimbursements'
												? 'nav-link active'
												: 'nav-link'
										}
										to="/reimbursements"
									>
										Reimbursements
									</Link>
								)}
							</Nav>
							<Nav className="justify-content-end flex-grow-2">
								<XDropdown
									menu={{
										items,
									}}
									placement="bottom"
								>
									<XSpace>
										<XAvatar
											size={30}
											src={
												getUserProfile() === 'undefined'
													? '/images/prifile.svg'
													: `${
															config.s3_url
													  }${getUserProfile()}`
											}
										/>
										<XDownOutlined />
									</XSpace>
								</XDropdown>
							</Nav>
						</Offcanvas.Body>
					</Navbar.Offcanvas>
				</XContainer>
			</XNavbar>
		</div>
	);
};

const mapStateToProps = (state) => ({
	logoutState: state.logout,
});

const mapDispatchToProps = (dispatch) => ({
	logout: (params) => dispatch(logoutApi(params)),
	logoutReset: () => dispatch(logoutReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderFile);
