import { base_urls, s3_urls } from './utils/constants';

const config = {
	api: {
		// base_url: 'http://localhost:8000',
		// base_url: 'https://decodex-dev-api.aiolosx.ai',
		base_url: base_urls[process.env.REACT_APP_APP_ENV],
	},
	s3_url: s3_urls[process.env.REACT_APP_APP_ENV],
	tiny_url_key: 'hifnzacmp0cqzl92lh5vg6cjoyipu9ih69ajw1kizdlw4y04',
};

export default config;
