import styled from 'styled-components';
import '../../styles/GlobalStyle';
import Navbar from 'react-bootstrap/Navbar';
import { Dropdown, Button, Avatar, Space } from 'antd';
import { DownOutlined, BellOutlined } from '@ant-design/icons';

export const XNavbar = styled(Navbar)`
	background: transparent;
	@media only screen and (max-width: 1199.99px) {
		width: 100%;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 1100;
		padding: 15px 10px;
		background-color: rgba(255, 255, 255, 0.5);
		box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
		backdrop-filter: blur(10px);
	}

	.centerMenuSpace a {
		margin: 0px 15px;
	}
	.navbar-toggler {
		border: none;
		outline: none;
		box-shadow: none;
		color: #212226;
		@media only screen and (max-width: 768.99px) {
			float: right;
		}
	}
`;
export const XSpace = styled(Space)`
	color: ${({ theme }) => theme.colors.menu1};
`;
export const XSpaceNotif = styled(Space)`
	color: ${({ theme }) => theme.colors.menu1};
	margin-left: 30px;
`;
export const XDownOutlined = styled(DownOutlined)`
	margin-top: 5px;
	color: ${({ theme }) => theme.colors.menu1};
	font-size: 10px;
`;
export const XBellOutlined = styled(BellOutlined)`
	margin-left: 30px;
	margin-top: 5px;
	color: ${({ theme }) => theme.colors.menu1};
	font-size: 20px;
`;
export const XDropdown = styled(Dropdown)`
	a {
		text-decoration: none;
	}
`;
export const XButton = styled(Button)`
	background: transparent;
`;
export const XAvatar = styled(Avatar)`
	background: none;
	cursor: pointer;
`;
