import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
	Row,
	Col,
	Input,
	Button,
	Space,
	Form,
	// Timeline,
	Result,
	Tooltip,
	notification,
	Tag,
	Select,
	Upload,
	Modal,
	InputNumber,
	Card,
	DatePicker,
	Avatar,
	Image,
	Affix,
	Checkbox,
	Radio,
	List,
} from 'antd';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

import {
	XContainer,
	XForm,
	XModal,
	XAlertBar,
	XButton,
	XTag,
	XCardsCriteriaBg,
	XCardsTransparent,
	XAffix,
	XSelect,
	XRoundButton,
	XList,
	XCircle,
	XLabel,
	XModalTitle,
	XModalSubtitle,
} from '../../styles/GlobalStyle';
import {
	ArrowLeftOutlined,
	WarningOutlined,
	PlusOutlined,
	EditOutlined,
	FileTextOutlined,
	FileImageOutlined,
	UserOutlined,
	AntDesignOutlined,
	DeleteOutlined,
	CloseOutlined,
} from '@ant-design/icons';
import {
	XCardBackBg,
	XButtonBack,
	XEditTitle,
	XRadio,
} from '../CaseForm/CaseFormStyle';
import HeaderFile from '../../components/Header/HeaderFile';
import moment from 'moment';
import { XLottie } from '../Login/LoginStyle';
import AppealLoading from '../../json/reloading.json';
import ReloadingAnimation from '../../json/decodex_updated05.json';
import AnalysisLoader from '../../components/AnalysisLoader/AnalysisLoader';
import DownloadAnimation from '../../json/download.json';

// actions
import { editCaseApi, editCaseReset } from '../../actions/editCaseAction';
import { addCaseApi, addCaseReset } from '../../actions/addCaseAction';
import {
	analyseCoverageApi,
	analyseCoverageReset,
} from '../../actions/analyseCoverageAction';
import {
	reviseCoverageApi,
	reviseCoverageReset,
} from '../../actions/reviseCoverageAction';
import {
	sendFeedbackApi,
	sendFeedbackReset,
} from '../../actions/sendFeedbackAction';
import {
	getAnalysisApi,
	getAnalysisReset,
} from '../../actions/getAnalysisAction';
import {
	checkModulePermissions,
	toTitleCase,
	getUserToken,
	disableFutureDates,
} from '../../utils/helper';
import {
	XDivider,
	XPara1,
	XTitleHead,
	XLottieMargin,
	Title,
	XCardsGrdBG,
	XButtonAdd,
	XButtonLike,
	XLottieOverlay,
	XButtonConfirm,
	XNotificationBar,
	XButtonReload,
	XButtonCloseBar,
	XCheckboxGroup,
} from '../AnalyseCoverage/AnalyseCoverageStyle';
import Header from '../../components/Header/Header';
import { XButtonImg } from '../Manage/ManageStyle';
import Timeline from '../../components/HorizontalTimeline/Timeline';
import { XCardsCaseBg } from '../../styles/GlobalStyle';
import Thanks from '../../json/thanks.json';
import axios from 'axios';
import config from '../../config';
import { Document, Page, pdfjs } from 'react-pdf'; // Import necessary components from react-pdf
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

import { filterByLabel } from '../../utils/formFunctions';
// Prepare letter
import { XButtonRounded } from '../AppealLetter/AppealLetterViewStyle';
import { getAppealLetterApi } from '../../actions/getAppealLetterAction';
import { saveAppealLetterApi } from '../../actions/saveAppealLetterAction';
import { generateAppealLetterApi } from '../../actions/generateAppealLetterAction';
import { getConfigurationsApi } from '../../actions/getConfigurationsAction';
import PrepareLetter from '../../components/HorizontalTimeline/PrepareLetter';
import { ReactSortable } from 'react-sortablejs';

const { TextArea } = Input;

const CaseForm = (props) => {
	const [form] = XForm.useForm();
	const [updateForm] = XForm.useForm();
	const [newOptionForm] = XForm.useForm();
	const {
		analyseCoverage,
		analyseCoverageReset,
		analyseCoverageState,
		reviseCoverage,
		reviseCoverageReset,
		reviseCoverageState,
		editCaseReset,
		editCaseState,
		getAnalysisState,
		getAnalysis,
		getAnalysisReset,
		sendFeedback,
		sendFeedbackReset,
		sendFeedbackState,
		editCase,
		getAppealLetter,
		getAppealLetterState,
		saveAppealLetter,
		saveAppealLetterState,
		saveAppealLetterReset,
		generateAppealLetterState,
		generateAppealLetter,
		generateAppealLetterReset,
		getConfigurationsState,
		getConfigurations,
	} = props;
	const location = useLocation();

	const pathnameIncludesCaseMA = location.pathname.includes('/case/ma/');

	const navigate = useNavigate();
	const id = useParams().id;
	const [formData, setFormData] = useState({
		status: 'success',
		successful_claim_commission_criteria: 'percentage',
		successful_claim_commission_fixed_value: null,
		successful_claim_commission_percentage_value: null,
		rejected_claim_commission_criteria: 'percentage',
		rejected_claim_commission_fixed_value: null,
		rejected_claim_commission_percentage_value: null,
		case_amount: null,
		claim_amount: null,
		status_logs: [],
	});
	const [patientData, setPatientData] = useState({});
	const [revenueCycleFormData, setRevenueCycleFormData] = useState({
		id: id,
		appeal_status: '',
		claim_category: '',
		claim_amount: null,
		status: '',
		revenue_cycle_stage: '',
		is_claim_amount_settled: null,
		claim_amount_updated_at: '',
		appeal_status_updated_at: '',
		reimbursed_at: '',
		revenue_cycle_updates: {
			revenue_cycle_status: '',
			appeal_status: '',
			remarks: '',
			url: [],
		},
		denial_reason: '',
		denial_code1: '',
		denial_code2: '',
	});
	const [pageContent, setPageContent] = useState({
		pastHistory: [],
		presentComplaint: [],
		recommendedProcedure: [],
		medicalCoverage: [],
	});
	const [flag, setFlag] = useState(true);
	const [items, setItems] = useState({});
	const [shouldDisplayCards, setShouldDisplayCards] = useState(false);
	const [matchDateOfBirth, setMatchDateOfbirth] = useState(true);
	const [matchGender, setMatchGender] = useState(true);
	const [feedbackId, setFeedbackId] = useState(null);
	const [loading, setLoading] = useState(false);
	const [appealLetterLoading, setAppealLetterLoading] = useState(false);
	const [downloadLoading, setDownloadLoading] = useState(false);
	const [modalTextArea, setModalTextArea] = useState('');
	const [modalOpen, setModalOpen] = useState([false, '', '', '', '']);
	const [thanksModal, setThanksModal] = useState([false, '']);
	const [coverage, setCoverage] = useState('Yes');
	const [fileList, setFileList] = useState([]);
	const [pdfPreviewOpen, setPdfPreviewOpen] = useState(false);
	const [previewImage, setPreviewImage] = useState('');
	const [previewOpen, setPreviewOpen] = useState(false);
	const [previewTitle, setPreviewTitle] = useState('Upload Acknowledgement');
	const [pdfWidth, setPdfWidth] = useState(700); // Initial width of the modal
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
	const [selectedOption, setSelectedOption] = useState('textarea'); // Initial value can be 'textarea' or 'upload'
	const [revenueCycleStage, setRevenueCycleStage] = useState(''); // Initial value can be 'textarea' or 'upload'
	const [updateModalOpen, setUpdateModalOpen] = useState([false, '']);
	const [revenueCycleStatus, setRevenueCycleStatus] = useState('');
	const [amountReceived, setAmountReceived] = useState('');
	const [timelineData, setTimelineData] = useState({});
	const [affixTop, setAffixTop] = React.useState(10);
	// Appeal Letter
	const [togglePrepareLetter, setTogglePrepareLetter] = useState(false);
	const [updatedFileList, setUpdatedFileList] = useState([]);
	const [submitCase, setSubmitCase] = useState(false); // Initial value can be 'textarea' or 'upload'
	const [documentsToDisplay, setDocumentsToDisplay] = useState([]);
	const [missingFieldsModal, setMissingFieldsModal] = useState(false);
	const [missingFields, setMissingFields] = useState([]);
	const [missingFieldInstructions, setMissingFieldInstructions] = useState(
		[],
	);
	const [missingFieldTitle, setMissingFieldTitle] = useState('');
	const [missingFieldsForm] = XForm.useForm();
	const [lastAnalysisData, setLastAnalysisData] = useState({});
	const [showAnalysisData, setShowAnalysisData] = useState(false);
	const [analysisCompleted, setAnalysisCompleted] = useState('');
	const [denialReasonData, setDenialReasonData] = useState([]);
	const [newOptionText, setNewOptionText] = useState('');
	const [newCheckBoxModal, setNewCheckBoxModal] = useState([false, null]);
	const [updateCaseLoading, setUpdateCaseLoading] = useState(false);
	const [updateModalButtonText, setUpdateModalButtonText] =
		useState('Update');

	const showDeleteConfirm = (title, id, content, item) => {
		XModal.confirm({
			title: `Delete ${title}?`,
			content: (
				<p className="modalPara1">
					Are you sure you want to delete - {content}?
				</p>
			),
			icon: <img className="me-2" src="/images/delete-icon.svg" alt="" />,
			okText: 'Yes, Confirm',
			okType: 'danger',
			cancelText: 'No, Cancel',
			className: 'confirmModal',
			onOk() {
				deletePageContent(item, id);
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	const deletePageContent = (item, id) => {
		const updatedPageContent = { ...pageContent };
		updatedPageContent[[item]].splice(id, 1);
		setPageContent(updatedPageContent);
		setModalOpen([false, '', ' ']);
		setModalTextArea('');
		form.resetFields();
		document.getElementById('notification')?.classList.remove('d-none');
	};
	const getBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	const handlePreview = async (file) => {
		if (file && file.name && file.name.split('.')[1] === 'pdf') {
			setPreviewImage(`${config.s3_url}${file.url}` || file.preview);
			setPdfPreviewOpen(true);
		} else {
			if (!file.url && !file.preview) {
				file.preview = await getBase64(file.originFileObj);
			}
			setPreviewImage(`${config.s3_url}${file.url}` || file.preview);

			setPreviewOpen(true);
			setPreviewTitle(
				file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
			);
		}
	};

	const handlePreviewBeforUpload = async (file) => {
		if (file && file.name && file.name.split('.')[1] === 'pdf') {
			console.log(file.url);
			setPreviewImage(file.url || file.preview);
			setPdfPreviewOpen(true);
		} else {
			if (!file.url && !file.preview) {
				file.preview = await getBase64(file.originFileObj);
			}
			setPreviewImage(file.url || file.preview);
			setPreviewOpen(true);
			setPreviewTitle(
				file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
			);
		}
	};

	const handleDownload = (file) => {
		// Assuming 'file' contains the URL of the file to download
		window.open(file.url, '_blank');
	};

	const uploadButton = (
		<Button
			id="revnueModalUploadButton"
			style={{
				border: 0,
				boxShadow: 'none',
				background: 'none',
				color: 'black',
				margin: '0',
			}}
		>
			<PlusOutlined />
			<div>Upload</div>
		</Button>
	);

	const handleCancel = () => setPreviewOpen(false);

	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages);
	};

	const onDocumentLoadError = (error) => {
		console.error('Error loading document:', error);
		// Handle error
	};

	// Function to update the width of the modal based on the width of the document
	const updateModalWidth = (width) => {
		setPdfWidth(width + 40);
	};

	const handleColor = (key) => {
		let color;
		switch (key) {
			case 'pending':
				color = '#E78900';
				break;
			case 'analysed':
				color = '#7A670E';
				break;
			case 'rejected':
				color = '#cf1322';
				break;
			case 'successful':
				color = '#14804a';
				break;
			case 'allocated':
				color = '#7A0E27';
				break;
			default:
				break;
		}
		return color;
	};

	const updatePageContent = (title, id, content) => {
		if (id === null) {
			setPageContent({
				...pageContent,
				[title]: [...pageContent[[title]], content],
			});
		} else {
			const updatedPageContent = { ...pageContent };
			updatedPageContent[[title]][id] = content;
			setPageContent(updatedPageContent);
		}
		setModalOpen([false, '', ' ']);
		setModalTextArea('');
		form.resetFields();
		document.getElementById('notification')?.classList.remove('d-none');
	};

	const dateOfBirth = (inputDateString) => {
		const date = new Date(inputDateString);

		// Extract the year, month, and day components from the Date object
		const year = date.getFullYear();
		const month = date.getMonth() + 1; // Month is zero-based, so add 1
		const day = date.getDate();

		// Format the date as "YYYY-MM-DD" or any desired format
		return `${year}-${month.toString().padStart(2, '0')}-${day
			.toString()
			.padStart(2, '0')}`;
	};

	function compareDates(dateString, yearNumber) {
		// Parse the date string using Moment.js
		const dateOfBirth = moment(dateString);

		// Calculate the current year
		const currentYear = moment().year();

		// Extract the year from the parsed date of birth
		const birthYear = dateOfBirth.year();

		// Calculate the age by subtracting the birth year from the current year
		const age = currentYear - birthYear;
		// Extract the last two digits of the year number
		const yearDigits = parseInt(yearNumber.toString().slice(-2));
		// Compare the years
		return age === yearDigits;
	}

	function compareGender(patientGender, analysisGender) {
		// Get the first character of the analysisGender string and normalize to uppercase
		const firstCharAnalysis = analysisGender.charAt(0).toUpperCase();

		// Check if patientGender is "M" (Male) or "F" (Female)
		if (
			patientGender.toUpperCase() === 'MALE' ||
			patientGender.toUpperCase() === 'FEMALE'
		) {
			// Compare the first character of analysisGender with patientGender
			return firstCharAnalysis === patientGender.charAt(0).toUpperCase();
		} else {
			// Match patientGender with "O" (Other)
			return firstCharAnalysis === 'O';
		}
	}

	// use effects
	useEffect(() => {
		if (id)
			getAnalysis({
				id: id,
				process_type: pathnameIncludesCaseMA ? null : 'rc',
			});
		getConfigurations({});
	}, [id]);

	useEffect(() => {
		if (editCaseState.apiState === 'loading') {
			setUpdateCaseLoading(true);
		}
		if (editCaseState.apiState === 'success') {
			setUpdateModalButtonText('Update');
			setUpdateCaseLoading(false);
			// getAnalysis({ id: id });
			if (flag)
				notification.success({
					message: editCaseState.message,
				});
			setModalOpen([false, '']);
			editCaseReset();
			setUpdateModalOpen([false, '']);
			setRevenueCycleStatus('');
			updateForm.setFieldsValue({});
			setRevenueCycleFormData({
				id: id,
				appeal_status: '',
				reimbursed_at: '',
				claim_category: '',
				claim_amount: null,
				status: '',
				denial_reason: '',
				denial_code1: '',
				denial_code2: '',
				revenue_cycle_stage: '',
				is_claim_amount_settled: null,
				claim_amount_updated_at: '',
				appeal_status_updated_at: '',
				revenue_cycle_updates: {
					revenue_cycle_status: '',
					appeal_status: '',
					remarks: '',
					url: [],
				},
			});
			setRevenueCycleStage('');
			updateForm.resetFields();
			setSubmitCase(false);
			setSelectedOption('');
			setFileList([]);
			if (flag) getAnalysis({ id: id });
			// navigate(-1);
		}
		setFlag(true);
		if (editCaseState.apiState === 'error') {
			setUpdateModalButtonText('Update');
			setUpdateCaseLoading(false);
			console.log(editCaseState);
			setSubmitCase(false);
			notification.error({
				message: editCaseState.message
					? editCaseState.message
					: 'Unable to update case status, Please try again',
			});
		}
		editCaseReset();
	}, [editCaseState]);

	useEffect(() => {
		if (getAnalysisState.apiState === 'success') {
			if (
				getAnalysisState.data.case_details &&
				getAnalysisState.data.case_details.allocated_to === null
			) {
				notification.error({
					message: 'Case Not Allocated',
					description:
						"Oops! It seems like this case hasn't been allocated to anyone yet. Please allocate it first before proceeding with the analysis.",
				});
			}
			setPatientData(getAnalysisState.data.case_details);
			setPageContent({
				...pageContent,
				originalQuery:
					getAnalysisState.data.coverage_feedback_response[
						'original_query'
					] ?? '-',
				pastHistory:
					getAnalysisState.data.coverage_feedback_response[
						'Past History'
					] ?? [],
				presentComplaint:
					getAnalysisState.data.coverage_feedback_response[
						'Present Complaint'
					] ?? [],
				recommendedProcedure:
					getAnalysisState.data.coverage_feedback_response[
						'Suggested Treatment'
					] ?? [],
				medicalCoverage: [
					getAnalysisState.data.coverage_feedback_response[
						'Medicare Evaluation'
					]
						? getAnalysisState.data.coverage_feedback_response[
								'Medicare Evaluation'
						  ]['Coverage']
						: 'No',
					getAnalysisState.data.coverage_feedback_response[
						'Medicare Evaluation'
					]
						? getAnalysisState.data.coverage_feedback_response[
								'Medicare Evaluation'
						  ]['Rationale']
						: '-',
					getAnalysisState.data.coverage_feedback_response[
						'Medicare Evaluation'
					]
						? getAnalysisState.data.coverage_feedback_response[
								'Medicare Evaluation'
						  ]['reference']
						: null,
				],
				missing_points_for_coverage:
					getAnalysisState.data.coverage_feedback_response
						.missing_points_for_coverage ?? [],
				status: getAnalysisState.data.coverage_feedback_response.status,
			});
			if (
				getAnalysisState.data.coverage_feedback_response &&
				getAnalysisState.data.coverage_feedback_response.age
			)
				setMatchDateOfbirth(
					compareDates(
						getAnalysisState.data.case_details.date_of_birth,
						getAnalysisState.data.coverage_feedback_response.age,
					),
				);
			if (
				getAnalysisState.data.coverage_feedback_response &&
				getAnalysisState.data.coverage_feedback_response.gender
			)
				setMatchGender(
					compareGender(
						getAnalysisState.data.case_details.gender,
						getAnalysisState.data.coverage_feedback_response.gender,
					),
				);

			setFeedbackId(getAnalysisState.data.coverage_feedback_response._id);
			document.getElementById('getAns')?.classList.add('top-0');
			document.getElementById('getAns')?.classList.remove('top-100');
			setTimelineData(getAnalysisState.data?.timelineData);
			const revenueCycleDocs =
				getAnalysisState.data.case_details
					.revenue_cycle_supporting_documents;

			if (revenueCycleDocs && revenueCycleDocs.length > 0) {
				// Modify fileList to add the prefix to URLs
				const modifiedFileList = revenueCycleDocs.map((file) => ({
					...file,
					url: `${config.s3_url}${file.url}`,
				}));
				setFileList([...modifiedFileList]);
				setSelectedOption('upload');
				setSelectedOption('upload');
			}

			const supportingDocs =
				getAnalysisState.data.case_details.supporting_docs.length > 0 &&
				getAnalysisState.data.case_details.supporting_docs.map(
					(doc) => {
						return doc;
					},
				);
			setDocumentsToDisplay(supportingDocs);

			const isAnyArrayNotEmpty = Object.keys(pageContent)
				.filter((key) => key !== 'medicalCoverage')
				.some((key) => pageContent[key].length > 0);

			setShouldDisplayCards(isAnyArrayNotEmpty);
		}
	}, [getAnalysisState.apiState]);

	useEffect(() => {
		if (
			getAnalysisState.apiState === 'success' &&
			!Object.keys(getAnalysisState.data.coverage_feedback_response)
				.length > 0 &&
			patientData.clinical_summary
		) {
			if (
				patientData.allocated_to &&
				(!getAnalysisState.data.coverage_feedback_response[
					'original_query'
				] ||
					!getAnalysisState.data.coverage_feedback_response[
						'Past History'
					]?.length > 0 ||
					!getAnalysisState.data.coverage_feedback_response[
						'Present Complaint'
					]?.length > 0 ||
					!getAnalysisState.data.coverage_feedback_response[
						'Suggested Treatment'
					]?.length > 0 ||
					getAnalysisState.data.coverage_feedback_response[
						'Medicare Evaluation'
					] === 'No')
			) {
				setLoading(true);
				document
					.getElementById('notification')
					?.classList.add('d-none');
				analyseCoverage({
					clinicalSummary: patientData.clinical_summary,
					case_id: patientData._id,
					state: patientData.state,
					provider: patientData.insurance_company?.name,
				});
			}
		}
		getAnalysisReset();
	}, [patientData]);

	useEffect(() => {
		if (showAnalysisData) {
			document.getElementById('getAns')?.classList.add('top-0');
			document.getElementById('getAns')?.classList.remove('top-100');
		}
	}, [showAnalysisData]);

	useEffect(() => {
		if (analyseCoverageState.apiState === 'success') {
			setFeedbackId(analyseCoverageState.data.feedback_id);
			setLastAnalysisData(analyseCoverageState.data);
			setPageContent({
				...pageContent,
				originalQuery:
					analyseCoverageState.data['original_query'] ?? '',
				pastHistory: analyseCoverageState.data['Past History'] ?? [],
				presentComplaint:
					analyseCoverageState.data['Present Complaint'] ?? [],
				recommendedProcedure:
					analyseCoverageState.data['Suggested Treatment'] ?? [],
				medicalCoverage: [
					analyseCoverageState.data['Medicare Evaluation']
						? analyseCoverageState.data['Medicare Evaluation'][
								'Coverage'
						  ]
						: 'No',
					analyseCoverageState.data['Medicare Evaluation']
						? analyseCoverageState.data['Medicare Evaluation'][
								'Rationale'
						  ]
						: '',
					analyseCoverageState.data['Medicare Evaluation']
						? analyseCoverageState.data['Medicare Evaluation'][
								'reference'
						  ]
						: null,
				],
				missing_points_for_coverage:
					analyseCoverageState.data.missing_points_for_coverage ?? [],
			});
			analyseCoverageReset();
			setAnalysisCompleted('success');
			if (
				analyseCoverageState.data &&
				analyseCoverageState.data.date_of_birth
			)
				setMatchDateOfbirth(
					compareDates(
						patientData.date_of_birth,
						analyseCoverageState.data.age,
					),
				);
			if (analyseCoverageState.data && analyseCoverageState.data.gender)
				setMatchGender(
					compareGender(
						patientData.gender,
						analyseCoverageState.data.gender,
					),
				);
			if (
				analyseCoverageState.data.hasOwnProperty('is_fields_missing') &&
				analyseCoverageState.data.is_fields_missing &&
				analyseCoverageState.data.hasOwnProperty('missing_fields')
			) {
				if (analyseCoverageState.data.missing_fields.length > 0) {
					setMissingFields(
						analyseCoverageState.data.missing_fields ?? [],
					);
					setMissingFieldInstructions(
						analyseCoverageState.data.missing_field_instructions ??
							[],
					);
					setMissingFieldTitle(
						analyseCoverageState.data.missing_field_title ?? '',
					);
					setMissingFieldsModal(true);
				}
			} else {
				setShowAnalysisData(true);
			}
		} else if (analyseCoverageState.apiState === 'error') {
			setAnalysisCompleted('error');
			setLoading(false);
			notification.error({
				message:
					'Hey, we are facing high traffic. Please check back in few minutes.',
			});
			analyseCoverageReset();
		}
	}, [analyseCoverageState]);

	useEffect(() => {
		if (reviseCoverageState.apiState === 'success') {
			setFeedbackId(reviseCoverageState.data.feedback_id);
			setLastAnalysisData(reviseCoverageState.data);
			setPageContent({
				...pageContent,
				originalQuery: reviseCoverageState.data['original_query'] ?? '',
				pastHistory: reviseCoverageState.data['Past History'] ?? [],
				presentComplaint:
					reviseCoverageState.data['Present Complaint'] ?? [],
				recommendedProcedure:
					reviseCoverageState.data['Suggested Treatment'] ?? [],
				medicalCoverage: [
					reviseCoverageState.data['Medicare Evaluation']
						? reviseCoverageState.data['Medicare Evaluation'][
								'Coverage'
						  ]
						: 'No',
					reviseCoverageState.data['Medicare Evaluation']
						? reviseCoverageState.data['Medicare Evaluation'][
								'Rationale'
						  ]
						: '',
					reviseCoverageState.data['Medicare Evaluation']
						? reviseCoverageState.data['Medicare Evaluation'][
								'reference'
						  ]
						: null,
				],
				missing_points_for_coverage:
					reviseCoverageState.data.missing_points_for_coverage ?? [],
				status: reviseCoverageState.data.status,
			});
			if (reviseCoverageState.data && reviseCoverageState.data.age)
				setMatchDateOfbirth(
					compareDates(
						patientData.date_of_birth,
						reviseCoverageState.data.age,
					),
				);
			if (reviseCoverageState.data && reviseCoverageState.data.gender)
				setMatchGender(
					compareGender(
						patientData.gender,
						reviseCoverageState.data.gender,
					),
				);
			if (
				reviseCoverageState.data.hasOwnProperty('is_fields_missing') &&
				reviseCoverageState.data.is_fields_missing &&
				reviseCoverageState.data.hasOwnProperty('missing_fields')
			) {
				if (reviseCoverageState.data.missing_fields.length > 0) {
					setMissingFields(
						reviseCoverageState.data.missing_fields ?? [],
					);
					setMissingFieldInstructions(
						reviseCoverageState.data.missing_field_instructions ??
							[],
					);
					setMissingFieldTitle(
						reviseCoverageState.data.missing_field_title ?? '',
					);
					setMissingFieldsModal(true);
				}
			} else {
				setShowAnalysisData(true);
			}
			reviseCoverageReset();
			setLoading(false);
		} else if (reviseCoverageState.apiState === 'error') {
			setLoading(false);
			notification.error({
				message:
					'Hey, we are facing high traffic. Please check back in few minutes.',
			});
			document.getElementById('notification')?.classList.remove('d-none');
			reviseCoverageReset();
		}
	}, [reviseCoverageState]);

	useEffect(() => {
		if (sendFeedbackState.apiState === 'success') {
			setModalOpen([false, '', '']);
			if (sendFeedbackState.data === 'negative')
				setThanksModal([true, 'Thanks for making us Better!']);
			else
				setThanksModal([true, 'Case has been analysed and confirmed!']);
			sendFeedbackReset();
			if (id) getAnalysis({ id: id });
		}
	}, [sendFeedbackState]);

	useEffect(() => {
		if (getConfigurationsState.apiState === 'success') {
			if (getConfigurationsState.data.all_configurations.length > 0) {
				getConfigurationsState.data.all_configurations.map((option) => {
					if (option.key === 'denial_reason') {
						const optionsList = option.options.map((opt) => ({
							value: opt.value,
							label: opt.label,
						}));
						setDenialReasonData(optionsList);
					}
				});
			}
		}
	}, [getConfigurationsState]);

	const resetFormFields = () => {
		setRevenueCycleFormData({
			...revenueCycleFormData,
			reimbursed_at: '',
			claim_category: '',
			claim_amount: null,
			is_claim_amount_settled: null,
			claim_amount_updated_at: '',
			appeal_status_updated_at: '',
			revenue_cycle_updates: {
				revenue_cycle_status: '',
				appeal_status: '',
				remarks: '',
				url: [],
			},
			denial_reason: '',
			denial_code1: '',
			denial_code2: '',
		});
		setFileList([]);
		updateForm.resetFields([
			'claim_category',
			'claim_amount',
			'is_claim_amount_settled',
			'claim_amount_updated_at',
			'appeal_status_updated_at',
			'remarks',
			'revenue_cycle_supporting_documents',
			'denial_reason',
			'denial_code1',
			'denial_code2',
			'rejected_category',
		]);
		setSelectedOption('');
	};

	// Function to handle changes in the form fields
	const handleInputChange = (e) => {
		const { name, value } = e.target;

		// Update the state based on the field name
		if (name === 'appeal_status') {
			resetFormFields();
			setRevenueCycleFormData((prevState) => ({
				...prevState,
				appeal_status: value,
				revenue_cycle_stage: revenueCycleStage,
				revenue_cycle_updates: {
					appeal_status: value,
					revenue_cycle_status: revenueCycleStage,
				},
				status: value === 'approved' ? 'successful' : 'rejected',
			}));
		} else if (name === 'claim_category') {
			// Update claim_category and claim_amount based on the selected value
			const claimAmount = value === 'full' ? patientData.case_amount : '';
			setRevenueCycleFormData((prevState) => ({
				...prevState,
				claim_category: value,
				claim_amount: claimAmount,
			}));
			// If claim_category is 'full', disable the claim_amount field
			if (value === 'full') {
				updateForm.setFieldsValue({ claim_amount: claimAmount });
			} else {
				updateForm.setFieldsValue({ claim_amount: '' });
			}
		} else if (name === 'claim_amount') {
			// Update claim_amount
			setRevenueCycleFormData((prevState) => ({
				...prevState,
				claim_amount: value,
			}));
		} else if (name === 'remarks') {
			console.log('remark');
			setFileList([]);
			updateForm.resetFields(['revenue_cycle_supporting_documents']);
			setRevenueCycleFormData((prevState) => ({
				...prevState,
				revenue_cycle_stage: revenueCycleStage,
				revenue_cycle_updates: {
					...prevState.revenue_cycle_updates,
					remarks: value,
				},
			}));
		} else if (name === 'is_claim_amount_settled') {
			// if (value === false) {
			// 	updateForm.setFieldsValue({
			// 		claim_amount: '',
			// 		claim_category: '',
			// 		claim_amount_updated_at: '',
			// 	});
			// 	setRevenueCycleFormData((prevState) => ({
			// 		...prevState,
			// 		claim_amount: '',
			// 		claim_category: '',
			// 		claim_amount_updated_at: '',
			// 	}));
			// }
			setRevenueCycleFormData((prevState) => ({
				...prevState,
				is_claim_amount_settled: value,
			}));
		} else if (name === 'denial_code1') {
			setRevenueCycleFormData((prevState) => ({
				...prevState,
				denial_code1: value,
			}));
		} else if (name === 'denial_code2') {
			setRevenueCycleFormData((prevState) => ({
				...prevState,
				denial_code2: value,
			}));
		}
	};

	// Handle changes in the Select component
	const handleSelect = (value, fieldName) => {
		setRevenueCycleFormData((prevState) => ({
			...prevState,
			[fieldName]: value,
		}));
	};

	const handleDateChange = (date, name) => {
		const formattedDate = date
			? date.format('YYYY-MM-DDTHH:mm:ss.SSSZ')
			: null;
		setRevenueCycleFormData((prevState) => ({
			...prevState,
			[name]: formattedDate,
			...(name === 'appeal_status_updated_at' &&
				prevState.appeal_status === 'approved' && {
					claim_amount_updated_at: formattedDate,
				}),
		}));
	};

	const handleChange = ({ fileList: newFileList }) => {
		const updatedFileList = newFileList.map((file) => {
			if (file.status === 'error') {
				return { ...file, status: 'done' };
			}
			return file;
		});
		setFileList(updatedFileList);
		updateForm.validateFields(['revenue_cycle_supporting_documents']);
	};

	const handleSubmit = async () => {
		try {
			// if (revenueCycleFormData.status === 'rejected' && selectedOption === 'revenue_cycle_supporting_documents' && fileList.length === 0) {
			// 	notification.error({
			// 		message: 'Submission Error',
			// 		description: 'Please upload supporting documents before submitting.',
			// 	});
			// 	return;
			// }
			if (fileList.length > 0) {
				setUpdateCaseLoading(true);
				setUpdateModalButtonText('Processing Denial Letter');
				await Promise.all(
					fileList.map(async (fileData) => {
						let get_ext = fileData.originFileObj.name.split('.');
						let ext = get_ext[get_ext.length - 1];
						const res = await axios.get(
							`${config.api.base_url}/settings/get-signed-url?action=putObject&folder_name=case_supporting_documents&file_name=${patientData.patient_last_name}&ext=${ext}&id=${patientData.mrn}${fileData.originFileObj.uid}`,
							{
								headers: {
									'Content-type':
										'application/json; charset=UTF-8',
									Authorization: 'Bearer ' + getUserToken(),
								},
							},
						);

						const result = await axios.put(
							res.data.data,
							fileData.originFileObj,
						);
						console.log('Error of file upload===>', result);
						if (result.status === 200) {
							const documentPath = `/case_supporting_documents/${patientData.mrn}${fileData.originFileObj.uid}/${patientData.patient_last_name}.${ext}`;
							const newFile = {
								uid: fileData.originFileObj.uid,
								name: fileData.originFileObj.name,
								status: 'done',
								url: documentPath,
							};
							setUpdatedFileList((prevFileList) => [newFile]);
						}
					}),
				).then((res) => {
					setUpdateModalButtonText('Processing Denial Letter');
					setSubmitCase(true);
				});
			} else {
				setUpdateModalButtonText('Updating');
				editCase(revenueCycleFormData);
			}
		} catch (error) {
			setUpdateModalButtonText('Update');
			setUpdateCaseLoading(false);
			setSubmitCase(false);
			// Handle error if any
			console.error('Error occurred:', error);
		}
	};

	useEffect(() => {
		if (submitCase) {
			editCase({
				...revenueCycleFormData,
				revenue_cycle_updates: {
					...revenueCycleFormData.revenue_cycle_updates,
					url: updatedFileList,
					revenue_cycle_status:
						revenueCycleFormData.revenue_cycle_stage,
					remarks: null,
				},
			});
		}
	}, [submitCase]);

	useEffect(() => {}, [pageContent]);

	const handleAddOption = (index) => {
		setNewCheckBoxModal([true, index]);
		setNewOptionText('');
		newOptionForm.setFieldsValue({});
		newOptionForm.resetFields();
	};

	const handleNewCheckBoxModal = () => {
		// Create a new option object
		newOptionForm
			.validateFields()
			.then(() => {
				const newOption = {
					label: newOptionText,
					value: newOptionText,
				};

				if (
					newCheckBoxModal[1] !== null &&
					newCheckBoxModal[1] >= 0 &&
					newCheckBoxModal[1] < missingFields.length
				) {
					const updatedFields = [...missingFields];
					updatedFields[newCheckBoxModal[1]].options.push(newOption);
					setMissingFields(updatedFields);

					// Get the current form values
					const currentValues = missingFieldsForm.getFieldsValue();

					// Add the new option to the appropriate field and mark it as checked
					if (
						!currentValues[updatedFields[newCheckBoxModal[1]].name]
					) {
						currentValues[updatedFields[newCheckBoxModal[1]].name] =
							[];
					}
					currentValues[updatedFields[newCheckBoxModal[1]].name].push(
						newOptionText,
					);

					// Update the form with the new values
					missingFieldsForm.setFieldsValue(currentValues);
				}

				// Close the modal and reset the newOptionText state
				setNewCheckBoxModal([false, null]);
				setNewOptionText('');
				newOptionForm.setFieldsValue({});
				newOptionForm.resetFields();
			})
			.catch((info) => {
				console.log('Validate Failed:', info);
			});
	};

	return (
		<>
			{appealLetterLoading && (
				<XLottieOverlay>
					<XLottie animationData={AppealLoading} loop={true} />
				</XLottieOverlay>
			)}

			{downloadLoading && (
				<XLottieOverlay>
					<XLottie animationData={DownloadAnimation} loop={true} />
				</XLottieOverlay>
			)}

			{/* {loading && (
				<XLottieOverlay>
					<XLottie animationData={ReloadingAnimation} loop={true} />
				</XLottieOverlay>
			)}  */}

			{loading && (
				<AnalysisLoader
					analysisId={id}
					analysisCompleted={analysisCompleted}
					setAnalysisCompleted={setAnalysisCompleted}
					setLoading={setLoading}
					loading={loading}
				/>
			)}

			<XContainer fluid>
				<div className="marginTopMobile "></div>
				<Affix id="notification" className="d-none" offsetTop={10}>
					<div className="marginBottomMobile"></div>
					<div
						className="mb-3"
						style={{
							backgroundColor: 'white',
							borderRadius: '20px',
							zIndex: '1111110',
						}}
					>
						<XNotificationBar style={{ zIndex: '22222' }}>
							Clinical Summary has been changed !! Click to{' '}
							<XButtonReload
								type="button"
								className="refreshBtn"
								onClick={() => {
									setLoading(true);
									document
										.getElementById('notification')
										?.classList.add('d-none');

									reviseCoverageReset();
									reviseCoverage({
										case_id: patientData._id,
										feedbackId: feedbackId,
										clinicalHistory:
											pageContent.pastHistory,
										presentComplaint:
											pageContent.presentComplaint,
										recommendedProcedure:
											pageContent.recommendedProcedure,
										medicalCoverage:
											pageContent.medicalCoverage,
										state: patientData.state,
										provider:
											patientData.insurance_company?.name,
										last_analysis_data: lastAnalysisData,
									});
								}}
							>
								Reanalyse it !!
							</XButtonReload>
							<XButtonCloseBar type="button" className="closeBar">
								<CloseOutlined
									onClick={() => {
										document
											.getElementById('notification')
											?.classList.add('d-none');
									}}
									style={{ fontSize: '20px' }}
								/>
							</XButtonCloseBar>
						</XNotificationBar>
					</div>
					<div className="marginNone"></div>
				</Affix>
			</XContainer>
			{props.displayHeader ? (
				<></>
			) : (
				<>
					<Col lg={24} xl={24} xs={0} md={0}>
						<Header headerTag="Case Filings" />
					</Col>
					<Col lg={0} xl={0} xs={24} md={24}>
						<HeaderFile activeNav="caseManagement" />
					</Col>
				</>
			)}

			<XContainer fluid>
				{checkModulePermissions(
					'ma-plan-appeals-processing',
				).sub_modules.includes('view-case') ||
				checkModulePermissions('case-filing').sub_modules.includes(
					'view-case',
				) ? (
					<>
						<Row gutter={(0, 30)}>
							<Col xs={24} sm={24} md={24} lg={14} xl={16}>
								<XCardBackBg className="mb-3">
									<Row>
										<Col xs={20}>
											<XButtonBack
												onClick={() => navigate(-1)}
												icon={<ArrowLeftOutlined />}
												size="large"
											/>

											<XEditTitle>View Case</XEditTitle>
										</Col>
									</Row>
								</XCardBackBg>

								{/* New Data */}
								<XCardBackBg className="mb-3">
									<Row
										justify={'start'}
										align={'middle'}
										style={{ rowGap: '10px' }}
									>
										<Col xs={24} lg={12} md={12} xl={6}>
											<XPara1>
												MRN:&nbsp;
												{patientData.mrn}
											</XPara1>
										</Col>
										<Col xs={24} lg={12} md={12} xl={8}>
											<XPara1>
												Name: {patientData.patient_name}
											</XPara1>
										</Col>
										<Col xs={24} lg={12} md={12} xl={5}>
											<XPara1>
												Age:{' '}
												{moment(
													patientData.date_of_birth,
												).fromNow(true)}
												&nbsp;
												{matchDateOfBirth ? (
													<> </>
												) : (
													<Tooltip
														title="The age of the patient does not align with the age provided in the Clinical Summary."
														trigger={'hover'}
													>
														<WarningOutlined
															style={{
																color: 'red',
															}}
														/>
													</Tooltip>
												)}
											</XPara1>
										</Col>
										<Col xs={24} lg={12} md={12} xl={5}>
											<XPara1>
												Gender:{' '}
												{patientData.gender
													? toTitleCase(
															patientData.gender,
													  )
													: ''}
												&nbsp;
												{matchGender ? (
													<> </>
												) : (
													<Tooltip
														title="The gender of the patient does not correspond with the gender entered in the Clinical Summary."
														trigger={'hover'}
													>
														<WarningOutlined
															style={{
																color: 'red',
															}}
														/>
													</Tooltip>
												)}
											</XPara1>
										</Col>
										<Col xs={24} lg={12} md={12} xl={6}>
											<XPara1>
												DOB:{' '}
												{patientData.date_of_birth
													? dateOfBirth(
															patientData.date_of_birth,
													  )
													: ''}
											</XPara1>
										</Col>
										<Col xs={24} lg={12} md={12} xl={8}>
											<XPara1>
												Revenue Cycle:{' '}
												{patientData.revenue_cycle ===
													'pre_service' &&
													'Pre Service'}
												{patientData.revenue_cycle ===
													'post_claim' &&
													'Post Claim'}
												{patientData.revenue_cycle ===
													'part_b_drugs' &&
													'Part B Drugs'}
											</XPara1>
										</Col>
										<Col xs={24} lg={12} md={12} xl={10}>
											<XPara1>
												State: {patientData.state}
											</XPara1>
										</Col>
										<Col xs={24} lg={12} md={24} xl={24}>
											<XPara1>
												Insurance Company:{' '}
												{patientData.insurance_company
													?.name ?? '-'}
											</XPara1>
										</Col>
									</Row>
									{patientData.status === 'letter_sent' ? (
										<Row className="mt-2">
											<Col xl={18} lg={18}>
												<XTag
													className={
														patientData.status
													}
												>
													{toTitleCase(
														patientData.status,
													)}
												</XTag>
											</Col>
										</Row>
									) : (
										''
									)}
								</XCardBackBg>
								<XCardsGrdBG>
									<Row className="align-items-center">
										<Col xs={20} lg={22}>
											<XPara1>
												{patientData.clinical_summary}
											</XPara1>
										</Col>
									</Row>
									{/* // !TODO To add Avatar later */}
									<div
										style={{
											display: 'flex',
											justifyContent: 'end',
										}}
									>
										<Avatar.Group
											maxCount={5}
											maxStyle={{
												color: '#f56a00',
												backgroundColor: '#fde3cf',
											}}
										>
											{documentsToDisplay.length > 0 &&
												documentsToDisplay.map(
													(docs, index) => {
														const isPDF =
															docs.url.name
																.split('.')
																.pop() ===
															'pdf';
														const avatarStyle = {
															fontSize: '24px',
															color: '#08c',
														};

														return (
															<Tooltip
																key={index}
																title={
																	docs.document_name
																}
																placement="top"
															>
																<Avatar
																	style={
																		avatarStyle
																	}
																	onClick={() =>
																		handlePreview(
																			docs.url,
																		)
																	}
																	src={`${config.s3_url}${docs.url.url}`}
																>
																	{isPDF
																		? 'PDF'
																		: docs.url.name
																				.charAt(
																					0,
																				)
																				.toUpperCase()}
																</Avatar>
															</Tooltip>
														);
													},
												)}
										</Avatar.Group>
									</div>
								</XCardsGrdBG>

								<div
									id="getAns"
									className="customScroll slideUp top-100 mt-4"
								>
									<XCardsCaseBg className="mt-4">
										<Row
											gutter={(15, 15)}
											className="align-items-center"
										>
											<Col xs={20} lg={20}>
												<Space align="center">
													<img
														className="iconResp"
														src="/images/past-icon.svg"
														alt=""
													/>
													<XTitleHead>
														<span className="color1 d-none d-sm-block">
															Past Clinical
															History
														</span>

														<span className="color1 d-block d-sm-none text-start">
															Past Clinical <br />{' '}
															History
														</span>
													</XTitleHead>
												</Space>
											</Col>
											<Col
												xs={4}
												lg={4}
												className="text-end"
											>
												<XButtonAdd
													shape="circle"
													icon={<PlusOutlined />}
													onClick={() => {
														form.setFieldsValue({
															modal_text_area: '',
														});
														setModalTextArea('');
														setModalOpen([
															true,
															'Add Past Clinical History',
															'',
															null,
															'pastHistory',
														]);
													}}
												></XButtonAdd>
											</Col>
										</Row>
										{pageContent.pastHistory.length > 0
											? pageContent.pastHistory.map(
													(row, index) => {
														const splitPastHistory =
															row.split(
																'- ICD-10 Code:',
															);

														return (
															<Row
																gutter={
																	(15, 15)
																}
																className="mt-3 align-items-center"
															>
																<Col
																	xs={24}
																	lg={20}
																>
																	{splitPastHistory.length >
																	1 ? (
																		<XPara1
																			style={{
																				fontStyle:
																					'italic',
																			}}
																		>
																			{
																				splitPastHistory[0]
																			}
																			<span
																				style={{
																					padding:
																						'3px',
																					background:
																						'var(--Linear, linear-gradient(90deg, #d4f8eb 0%, #fff3fc 48%, #e4d3fc 100%))',
																				}}
																			>
																				ICD-10:
																				{
																					splitPastHistory[1]
																				}
																			</span>
																		</XPara1>
																	) : (
																		<XPara1
																			style={{
																				fontStyle:
																					'italic',
																			}}
																		>
																			{
																				row
																			}
																		</XPara1>
																	)}
																</Col>
																<Col
																	xs={24}
																	lg={4}
																	className="text-end"
																>
																	<XButtonImg
																		type="text"
																		onClick={() => {
																			form.setFieldsValue(
																				{
																					modal_text_area:
																						row,
																				},
																			);
																			setModalTextArea(
																				row,
																			);
																			setModalOpen(
																				[
																					true,
																					`Edit Past Clinical History`,
																					row,
																					index,
																					'pastHistory',
																				],
																			);
																		}}
																	>
																		<EditOutlined />
																	</XButtonImg>
																	<XButtonImg
																		type="text"
																		onClick={() => {
																			showDeleteConfirm(
																				'Past Clinical History',
																				index,
																				row,
																				'pastHistory',
																			);
																		}}
																	>
																		<DeleteOutlined />
																	</XButtonImg>
																</Col>
															</Row>
														);
													},
											  )
											: '-'}

										<XDivider dashed="true" />

										<Row
											gutter={(15, 15)}
											className="align-items-center"
										>
											<Col xs={20} lg={20}>
												<Space align="center">
													<img
														className="iconResp"
														src="/images/present-icon.svg"
														alt=""
													/>
													<XTitleHead>
														<span className="color2 d-none d-sm-block">
															Present Complaint
														</span>

														<span className="color2 d-block d-sm-none text-start">
															Present <br />{' '}
															Complaint
														</span>
													</XTitleHead>
												</Space>
											</Col>
											<Col
												xs={4}
												lg={4}
												className="text-end"
											>
												<XButtonAdd
													shape="circle"
													icon={<PlusOutlined />}
													onClick={() => {
														form.setFieldsValue({
															modal_text_area: '',
														});
														setModalTextArea('');
														setModalOpen([
															true,
															'Add Present Complaint',
															'',
															null,
															'presentComplaint',
														]);
													}}
												></XButtonAdd>
											</Col>
										</Row>
										{pageContent.presentComplaint.length > 0
											? pageContent.presentComplaint.map(
													(row, index) => {
														const splitPresentComplaint =
															row.split(
																'- ICD-10 Code:',
															);

														return (
															<Row
																gutter={
																	(15, 15)
																}
																className="mt-3 align-items-center"
															>
																<Col
																	xs={24}
																	lg={20}
																>
																	{splitPresentComplaint.length >
																	1 ? (
																		<XPara1
																			style={{
																				fontStyle:
																					'italic',
																			}}
																		>
																			{
																				splitPresentComplaint[0]
																			}
																			<span
																				style={{
																					padding:
																						'3px',
																					background:
																						'var(--Linear, linear-gradient(90deg, #d4f8eb 0%, #fff3fc 48%, #e4d3fc 100%))',
																				}}
																			>
																				ICD-10:
																				{
																					splitPresentComplaint[1]
																				}
																			</span>
																		</XPara1>
																	) : (
																		<XPara1
																			style={{
																				fontStyle:
																					'italic',
																			}}
																		>
																			{
																				row
																			}
																		</XPara1>
																	)}
																</Col>
																<Col
																	xs={24}
																	lg={4}
																	className="text-end"
																>
																	<XButtonImg
																		type="text"
																		onClick={() => {
																			form.setFieldsValue(
																				{
																					modal_text_area:
																						row,
																				},
																			);
																			setModalTextArea(
																				row,
																			);
																			setModalOpen(
																				[
																					true,
																					`Edit Present Complaint`,
																					row,
																					index,
																					'presentComplaint',
																				],
																			);
																		}}
																	>
																		<EditOutlined />
																	</XButtonImg>
																	<XButtonImg
																		type="text"
																		onClick={() => {
																			showDeleteConfirm(
																				'Present Complaint',
																				index,
																				row,
																				'presentComplaint',
																			);
																		}}
																	>
																		<DeleteOutlined />
																	</XButtonImg>
																</Col>
															</Row>
														);
													},
											  )
											: '-'}

										<XDivider dashed="true" />

										<Row
											gutter={(15, 15)}
											className="align-items-center"
										>
											<Col xs={20} lg={20}>
												<Space align="center">
													<img
														className="iconResp"
														src="/images/procedure-icon.svg"
														alt=""
													/>
													<XTitleHead>
														<span className="color3 d-none d-sm-block">
															Recommended
															Procedure
														</span>

														<span className="color3 d-block d-sm-none text-start">
															Recommended <br />{' '}
															Procedure
														</span>
													</XTitleHead>
												</Space>
											</Col>
											<Col
												xs={4}
												lg={4}
												className="text-end"
											>
												<XButtonAdd
													shape="circle"
													icon={<PlusOutlined />}
													onClick={() => {
														form.setFieldsValue({
															modal_text_area: '',
														});
														setModalTextArea('');
														setModalOpen([
															true,
															'Add Recommended Procedure',
															'',
															null,
															'recommendedProcedure',
														]);
													}}
												></XButtonAdd>
											</Col>
										</Row>
										{pageContent.recommendedProcedure
											.length > 0
											? pageContent.recommendedProcedure.map(
													(row, index) => {
														const splitRecommendedProcedure =
															row.split(
																'- CPT Code:',
															);

														return (
															<Row
																gutter={
																	(15, 15)
																}
																className="mt-3 align-items-center"
															>
																<Col
																	xs={24}
																	lg={20}
																>
																	{splitRecommendedProcedure.length >
																	1 ? (
																		<XPara1
																			style={{
																				fontStyle:
																					'italic',
																			}}
																		>
																			{
																				splitRecommendedProcedure[0]
																			}
																			<span
																				style={{
																					padding:
																						'3px',
																					background:
																						'var(--Linear, linear-gradient(90deg, #d4f8eb 0%, #fff3fc 48%, #e4d3fc 100%))',
																				}}
																			>
																				CPT:
																				{
																					splitRecommendedProcedure[1]
																				}
																			</span>
																		</XPara1>
																	) : (
																		<XPara1
																			style={{
																				fontStyle:
																					'italic',
																			}}
																		>
																			{
																				row
																			}
																		</XPara1>
																	)}
																</Col>
																<Col
																	xs={24}
																	lg={4}
																	className="text-end"
																>
																	<XButtonImg
																		type="text"
																		onClick={() => {
																			form.setFieldsValue(
																				{
																					modal_text_area:
																						row,
																				},
																			);
																			setModalTextArea(
																				row,
																			);
																			setModalOpen(
																				[
																					true,
																					`Edit Recommended Procedure`,
																					row,
																					index,
																					'recommendedProcedure',
																				],
																			);
																		}}
																	>
																		<EditOutlined />
																	</XButtonImg>
																	<XButtonImg
																		type="text"
																		onClick={() => {
																			showDeleteConfirm(
																				'Recommended Procedure',
																				index,
																				row,
																				'recommendedProcedure',
																			);
																		}}
																	>
																		<DeleteOutlined />
																	</XButtonImg>
																</Col>
															</Row>
														);
													},
											  )
											: '-'}

										<XDivider dashed="true" />

										<Row
											gutter={(15, 15)}
											className="align-items-center"
										>
											<Col xs={24}>
												<Space align="center">
													<img
														className="iconResp"
														src={
															pageContent
																.medicalCoverage[0] ===
															'Yes'
																? '/images/coverage-icon.svg'
																: '/images/cross-icon.svg'
														}
														alt=""
													/>
													<XTitleHead>
														<span
															className={
																pageContent
																	.medicalCoverage[0] ===
																'Yes'
																	? 'color4'
																	: 'color5'
															}
														>
															Medicare Coverage
														</span>
													</XTitleHead>
												</Space>
											</Col>
										</Row>
										{pageContent.medicalCoverage.length >
										0 ? (
											<Row
												gutter={(15, 15)}
												className="mt-3 align-items-center"
											>
												<Col xs={24}>
													<XPara1>
														{
															pageContent
																.medicalCoverage[1]
														}
														{pageContent
															.medicalCoverage[2] &&
														Object.keys(
															pageContent
																.medicalCoverage[2],
														).length > 0 ? (
															<>
																<br />
																<br />
																<i>
																	Reference:{' '}
																	<a
																		href={
																			pageContent
																				.medicalCoverage[2][
																				'target'
																			]
																		}
																		rel="noreferrer"
																		target="_blank"
																	>
																		{
																			pageContent
																				.medicalCoverage[2][
																				'display_text'
																			]
																		}
																	</a>
																</i>
															</>
														) : (
															''
														)}
													</XPara1>
												</Col>
											</Row>
										) : (
											'-'
										)}
									</XCardsCaseBg>
									{pageContent.missing_points_for_coverage &&
										pageContent.missing_points_for_coverage
											.length > 0 && (
											<>
												<XCardsCriteriaBg className="mt-4">
													<div className="criteria_list">
														<Col xs={24}>
															<Space align="center">
																<img
																	src={
																		'/images/criteria.svg'
																	}
																	alt=""
																/>
																<XTitleHead>
																	<span className="criteria_list_title">
																		Missing
																		Inclusion
																		Criteria
																	</span>
																	&nbsp;
																	<Tooltip title="This feature is experimental and may contain errors as it evolves. Please exercise discretion, especially when reviewing critical information.">
																		<Tag
																			style={{
																				border: '1px solid #9B68BF',
																				color: '#9B68BF',
																				fontSize:
																					'11px',
																			}}
																		>
																			Alpha
																		</Tag>
																	</Tooltip>
																</XTitleHead>
															</Space>
														</Col>
													</div>

													<Row
														gutter={(15, 15)}
														className="mt-3 align-items-center criteria_sub_list"
													>
														<Col xs={24}>
															<ul className="listCircule">
																{pageContent.missing_points_for_coverage?.map(
																	(
																		row,
																		index,
																	) => {
																		return (
																			<li>
																				{
																					row
																				}
																			</li>
																		);
																	},
																)}
															</ul>
														</Col>
													</Row>
												</XCardsCriteriaBg>
											</>
										)}
								</div>
								{checkModulePermissions(
									'ma-plan-appeals-processing',
								).sub_modules.includes('update-coverage') ||
								checkModulePermissions(
									'case-filing',
								).sub_modules.includes('update-coverage') ? (
									<Row className=" text-end">
										<Col
											xs={{ span: 24 }}
											lg={{ span: 6, offset: 0 }}
											md={{ span: 6, offset: 0 }}
											xl={{ span: 6, offset: 0 }}
											xxl={{ span: 6, offset: 0 }}
											className="mt-4"
										>
											<Space style={{ width: '100%' }}>
												{/* <Divider type="vertical" /> */}
												<XButtonLike
													type="primary"
													shape="round"
													icon={
														<img
															className="rotateButton"
															src="/images/like-icon.svg"
															alt=""
														/>
													}
													size="large"
													onClick={() => {
														form.setFieldsValue({
															modal_text_area:
																pageContent.medicalCoverage
																	? pageContent
																			.medicalCoverage[1]
																	: '',
															medicare_flag:
																pageContent.medicalCoverage
																	? pageContent
																			.medicalCoverage[0]
																	: '',
														});
														setModalTextArea(
															pageContent
																.medicalCoverage[1],
														);
														setModalOpen([
															true,
															`Edit Medicare Coverage`,
															pageContent
																.medicalCoverage[1],
															1,
															'feedbackSummary',
														]);
													}}
												>
													Refine
												</XButtonLike>
												<XButtonLike
													onClick={() => {
														sendFeedback({
															feedback_id:
																feedbackId,
															feedback:
																'positive',
															status: 'approved',
															response:
																pageContent.medicalCoverage,
														});
													}}
													type="primary"
													shape="round"
													icon={
														<img
															src="/images/like-icon.svg"
															alt=""
														/>
													}
													size="large"
												>
													Looks Good
												</XButtonLike>
											</Space>
										</Col>
										<Col
											xs={{ span: 10, offset: 0 }}
											lg={{ span: 3, offset: 8 }}
											md={{ span: 3, offset: 11 }}
											xl={{ span: 3, offset: 12 }}
											xxl={{ span: 3, offset: 15 }}
											className="mt-4"
										>
											<XButtonConfirm
												onClick={() => {
													sendFeedback({
														feedback_id: feedbackId,
														status: 'approved',
														response:
															pageContent.medicalCoverage,
													});
												}}
												disabled={
													pageContent.status ===
													'approved'
														? true
														: false
												}
												type="primary"
												shape="round"
												icon={
													<img
														src="/images/check 1.svg"
														alt=""
													/>
												}
												size="large"
												style={{
													background: '#000',
													color: '#fff',
												}}
												className="confirmCoverage"
											>
												Confirm Coverage
											</XButtonConfirm>
										</Col>
									</Row>
								) : (
									<></>
								)}
							</Col>
							<Col
								xs={24}
								sm={24}
								md={24}
								lg={10}
								xl={8}
								className="mt-4 mt-lg-0"
							>
								<XAffix offsetTop={affixTop}>
									<div
										className={`toggle ${
											!togglePrepareLetter
												? 'rotated'
												: ''
										}`}
									>
										<div className="front">
											{togglePrepareLetter && (
												<>
													<XCardsCaseBg className="timeline-documents">
														<Row className="align-items-center">
															<Col xs={24}>
																<Space align="center">
																	<XButtonBack
																		onClick={() =>
																			setTogglePrepareLetter(
																				false,
																			)
																		}
																		icon={
																			<ArrowLeftOutlined />
																		}
																		size="large"
																	/>
																	<label
																		class="headerTagStyle"
																		style={{
																			fontWeight: 600,
																		}}
																	>
																		Prepare
																		Letter
																	</label>
																</Space>
															</Col>
														</Row>
														<XDivider
															dashed="true"
															className="mt-3"
														/>
														<PrepareLetter
															setTogglePrepareLetter={
																setTogglePrepareLetter
															}
															setLoading={
																setAppealLetterLoading
															}
															setDownloadLoading={
																setDownloadLoading
															}
														/>
													</XCardsCaseBg>
												</>
											)}
										</div>
										<div className="back">
											{!togglePrepareLetter && (
												<XCardsCaseBg className="timeline-documents">
													<Row className="align-items-center">
														<Col xs={24}>
															<Space align="center">
																<img
																	className=""
																	src="/images/time-line-icon.svg"
																	alt=""
																/>
																<label
																	class="headerTagStyle"
																	style={{
																		fontWeight: 600,
																	}}
																>
																	Case
																	Timeline
																</label>
															</Space>
														</Col>
													</Row>
													<XDivider
														dashed="true"
														className="mt-3"
													/>
													{timelineData &&
													timelineData.length > 0 ? (
														<Timeline
															setRevenueCycleStage={
																setRevenueCycleStage
															}
															revenueCycleStage={
																revenueCycleStage
															}
															status={
																formData?.status
															}
															setUpdateModalOpen={
																setUpdateModalOpen
															}
															setRevenueCycleStatus={
																setRevenueCycleStatus
															}
															revenueCycleFormData={
																revenueCycleFormData
															}
															setRevenueCycleFormData={
																setRevenueCycleFormData
															}
															timelineData={
																timelineData
															}
															togglePrepareLetter={
																togglePrepareLetter
															}
															setTogglePrepareLetter={
																setTogglePrepareLetter
															}
															fileList={fileList}
															setFileList={
																setFileList
															}
															setUpdatedFileList={
																setUpdatedFileList
															}
															updatedFileList={
																updatedFileList
															}
															id={id}
															setSelectedOption={
																setSelectedOption
															}
															medicareCoverage={
																pageContent
																	.medicalCoverage[0]
															}
															case_status={
																patientData.status
															}
															setFlag={setFlag}
														/>
													) : (
														<></>
													)}
												</XCardsCaseBg>
											)}
										</div>
									</div>
								</XAffix>
							</Col>
						</Row>
					</>
				) : (
					<Result
						status="403"
						title="403"
						subTitle="Sorry, you are not authorized to access this page."
						extra={
							<Button
								type="primary"
								onClick={() => navigate('/')}
							>
								Back Home
							</Button>
						}
					/>
				)}
			</XContainer>
			<XModal
				title={
					<>
						<EditOutlined />
						<span className="ms-2">{modalOpen[1]}</span>
					</>
				}
				centered
				open={modalOpen[0]}
				onOk={() => {
					form.validateFields()
						.then((res) => {
							if (modalOpen[4] === 'feedbackSummary') {
								sendFeedback({
									feedback_id: feedbackId,
									feedback: 'negative',
									status: 'pending_for_analysis',
									response: [coverage, modalTextArea],
								});
							} else {
								updatePageContent(
									modalOpen[4],
									modalOpen[3],
									modalTextArea,
								);
							}
						})
						.catch((err) => {
							console.log('error', err);
						});
				}}
				onCancel={() => {
					form.resetFields();
					setModalTextArea('');
					setModalOpen([false, '', ' ']);
				}}
				className="modalButton"
				okText="Submit"
			>
				<XForm
					form={form}
					name="groupForm"
					layout="vertical"
					autoComplete="off"
				>
					<Row gutter={(15, 0)} className="mt-4">
						<Col xs={24}>
							{modalOpen[4] === 'feedbackSummary' && (
								<XForm.Item
									name="medicare_flag"
									label="Coverage"
									rules={[
										{
											required: true,
											message: 'Field is required',
										},
									]}
								>
									<Select
										showSearch
										placeholder="Select a person"
										optionFilterProp="children"
										onChange={(e) => {
											setCoverage(e);
										}}
										options={[
											{
												value: 'Yes',
												label: 'YES',
											},
											{
												value: 'No',
												label: 'NO',
											},
										]}
									/>
								</XForm.Item>
							)}
							<XForm.Item
								name="modal_text_area"
								rules={[
									{
										required: true,
										message: 'Field is required',
									},
								]}
							>
								<TextArea
									name="modal_text_area"
									showCount
									style={{
										height: 120,
										marginBottom: 24,
									}}
									className="textArea mt-2"
									placeholder="Enter"
									onChange={(e) =>
										setModalTextArea(e.target.value)
									}
								>
									{/* modalOpen[2] */}
								</TextArea>
							</XForm.Item>
						</Col>
					</Row>
				</XForm>
			</XModal>

			<XModal
				centered
				open={thanksModal[0]}
				onOk={() => setThanksModal([false, ''])}
				onCancel={() => {
					setThanksModal([false, '']);
				}}
				className="modalButton"
				okText="Submit"
				footer={null}
			>
				<Row gutter={(15, 0)} className="mt-4">
					<Col xs={24} lg={{ span: 12, offset: 6 }}>
						<XLottieMargin>
							<XLottie animationData={Thanks} loop={true} />
						</XLottieMargin>

						<XPara1 className="mt-5 mb-4">{thanksModal[1]}</XPara1>
					</Col>
				</Row>
			</XModal>

			{/* Update Revenue Modal */}
			<XModal
				title={`Update Case Status`}
				centered
				open={updateModalOpen[0]}
				onCancel={() => {
					if (!updateCaseLoading) {
						setUpdateModalOpen([false, '']);
						setRevenueCycleStatus('');
						updateForm.setFieldsValue({});
						setRevenueCycleFormData({
							id: id,
							reimbursed_at: '',
							appeal_status: '',
							claim_category: '',
							claim_amount: null,
							status: '',
							revenue_cycle_stage: '',
							is_claim_amount_settled: null,
							claim_amount_updated_at: '',
							appeal_status_updated_at: '',
							revenue_cycle_updates: {
								revenue_cycle_status: '',
								appeal_status: '',
								remarks: '',
								url: [],
							},
							denial_reason: '',
							denial_code1: '',
							denial_code2: '',
						});
						setRevenueCycleStage('');
						setFileList([]);
						setUpdatedFileList([]);
						updateForm.resetFields();
						setSelectedOption('');
					}
				}}
				className="modalButton"
				okText="Update Status"
				footer={null}
			>
				<XForm
					form={updateForm}
					name="updateRevenue"
					layout="vertical"
					autoComplete="off"
					onFinish={handleSubmit}
				>
					<Row gutter={(15, 0)} className="mt-4">
						<Col xs={24}>
							<XForm.Item
								name="appeal_status"
								label="Revenue Status"
								rules={[
									{
										required: true,
										message:
											'Please select revenue status!',
									},
								]}
							>
								<XRadio.Group
									onChange={(e) => {
										handleInputChange(
											e,
											revenueCycleFormData,
											setRevenueCycleFormData,
										);
										if (e.target.value === 'approved') {
											setRevenueCycleStatus('approved');
										} else if (
											e.target.value === 'rejected'
										) {
											setRevenueCycleStatus('rejected');
										} else {
											setRevenueCycleStatus('');
										}
									}}
									name="appeal_status"
								>
									<XRadio value={'approved'}>Approved</XRadio>
									<XRadio value={'rejected'}>Rejected</XRadio>
								</XRadio.Group>
							</XForm.Item>
						</Col>
						{revenueCycleStatus === 'rejected' && (
							<>
								<Col xs={24}>
									<Col xs={24}>
										<XForm.Item
											label="Select Rejected Date"
											name="appeal_status_updated_at"
											rules={[
												{
													required: true,
													message:
														'Please select Date!',
												},
											]}
										>
											<DatePicker
												size="large"
												name="appeal_status_updated_at"
												className="w-100"
												onChange={(date) =>
													handleDateChange(
														date,
														'appeal_status_updated_at',
													)
												}
												disabledDate={
													disableFutureDates
												}
											/>
										</XForm.Item>
									</Col>
									<Col xs={24} sm={24} md={24} lg={24}>
										<XForm.Item
											name="denial_reason"
											label={'Denial Type'}
											rules={[
												{
													required: true,
													message:
														'Please select Denial Type!',
												},
											]}
										>
											<Select
												size="large"
												placeholder="Select Denial Type"
												name="denial_reason"
												filterOption={filterByLabel}
												options={denialReasonData}
												onSelect={(value) =>
													handleSelect(
														value,
														'denial_reason',
													)
												}
											/>
										</XForm.Item>
									</Col>
									<Row gutter={[10]}>
										<Col xs={24} sm={12} md={12} lg={12}>
											<XForm.Item
												name="denial_code1"
												label="Denial Code 1"
											>
												<Input
													size="large"
													placeholder="Enter denial code 1"
													name="denial_code1"
													onChange={handleInputChange}
												/>
											</XForm.Item>
										</Col>
										<Col xs={24} sm={12} md={12} lg={12}>
											<XForm.Item
												name="denial_code2"
												label="Denial Code 2"
											>
												<Input
													size="large"
													placeholder="Enter denial code 2"
													name="denial_code2"
													onChange={handleInputChange}
												/>
											</XForm.Item>
										</Col>
									</Row>
									<Card>
										<Row>
											<Col xs={24}>
												<XForm.Item
													className="mb-0"
													label="Denial Reason"
													name="rejected_category"
													rules={[
														{
															required: true,
															message:
																'Please select option!',
														},
													]}
												>
													<XRadio.Group
														onChange={(e) => {
															handleInputChange(
																e,
																revenueCycleFormData,
																setRevenueCycleFormData,
															);
															updateForm.setFieldsValue(
																{
																	remarks: '',
																	revenue_cycle_supporting_documents:
																		'',
																},
															);
															setFileList([]);
															setRevenueCycleFormData(
																(
																	prevState,
																) => ({
																	...prevState,
																	revenue_cycle_updates:
																		{
																			...prevState.revenue_cycle_updates,
																			remarks:
																				'',
																			url: [],
																		},
																}),
															);
															if (
																e.target
																	.value ===
																'revenue_cycle_supporting_text'
															) {
																setSelectedOption(
																	'revenue_cycle_supporting_text',
																);
															} else if (
																e.target
																	.value ===
																'revenue_cycle_supporting_documents'
															) {
																setSelectedOption(
																	'revenue_cycle_supporting_documents',
																);
															}
														}}
														name="rejected_category"
													>
														<XRadio
															className="pb-2"
															value={
																'revenue_cycle_supporting_text'
															}
														>
															Enter Reason
														</XRadio>
														<XRadio
															className="pb-2"
															value={
																'revenue_cycle_supporting_documents'
															}
														>
															Upload Denial Letter
														</XRadio>
													</XRadio.Group>
												</XForm.Item>
											</Col>
										</Row>
									</Card>
								</Col>
								{selectedOption ===
									'revenue_cycle_supporting_text' && (
									<Col xs={24} className="text-end mt-4">
										<XForm.Item
											name="remarks"
											label="Enter Reason"
											rules={[
												{
													required: true,
													message:
														'Please enter reason',
												},
											]}
										>
											<TextArea
												name="remarks"
												showCount
												maxLength={100}
												style={{
													height: 120,
													marginBottom: 24,
												}}
												onChange={(e) =>
													handleInputChange(
														e,
														revenueCycleFormData,
														setRevenueCycleFormData,
													)
												}
												placeholder="Enter Reason"
											/>
										</XForm.Item>
									</Col>
								)}
								{selectedOption ===
									'revenue_cycle_supporting_documents' && (
									<>
										<Col xs={24} className="mt-4">
											<XForm.Item
												name="revenue_cycle_supporting_documents"
												label="Upload Denial Letter"
												rules={[
													{
														required: true,
														validator: (
															_,
															value,
														) => {
															if (
																fileList.length ===
																0
															) {
																return Promise.reject(
																	new Error(
																		'Please upload denial letter!',
																	),
																);
															}
															return Promise.resolve();
														},
													},
												]}
											>
												<Card>
													<Upload
														action={null}
														style={{
															padding: 0,
															margin: 0,
														}}
														showUploadList={{
															showPreviewIcon: false,
														}}
														className="supporting_files timeline-upload"
														listType="picture-card"
														fileList={fileList}
														onPreview={
															handlePreviewBeforUpload
														}
														multiple={false}
														maxCount={1}
														onChange={handleChange}
														accept=".pdf"
														beforeUpload={(
															file,
														) => {
															const isJPEG =
																file.type ===
																'image/jpeg';
															const isJPG =
																file.type ===
																'image/jpg';
															const isPNG =
																file.type ===
																'image/png';
															const isGIF =
																file.type ===
																'image/gif';
															const isVideo =
																file.type.startsWith(
																	'video/',
																);
															const isAudio =
																file.type.startsWith(
																	'audio/',
																);

															const isLt10M =
																file.size /
																	1024 /
																	1024 <
																10;

															if (!isLt10M) {
																notification.error(
																	{
																		message:
																			'File Size Exceeded',
																		description: `The file ${file.name} exceeds the maximum allowed size of 10MB.`,
																	},
																);
															}
															if (
																isGIF ||
																isVideo ||
																isAudio ||
																isJPEG ||
																isJPG ||
																isPNG
															) {
																notification.error(
																	{
																		message:
																			'File Type Error',
																		description: `GIF, video, images and audio files are not allowed.`,
																	},
																);
															}

															return (
																isLt10M &&
																!isGIF &&
																!isVideo &&
																!isAudio
															);
														}}
														onDownload={
															handleDownload
														}
													>
														{fileList.length >= 1
															? null
															: uploadButton}
													</Upload>
												</Card>
											</XForm.Item>
										</Col>
									</>
								)}
							</>
						)}
						{revenueCycleStatus === 'approved' && (
							<>
								<Col xs={24}>
									<Col xs={24}>
										<XForm.Item
											label="Select Approval Date"
											name="appeal_status_updated_at"
											rules={[
												{
													required: true,
													message:
														'Please select Date!',
												},
											]}
										>
											<DatePicker
												size="large"
												name="appeal_status_updated_at"
												className="w-100"
												onChange={(date) =>
													handleDateChange(
														date,
														'appeal_status_updated_at',
													)
												}
												disabledDate={
													disableFutureDates
												}
											/>
										</XForm.Item>
									</Col>
									<Card>
										<Row>
											<Col xs={24}>
												<XForm.Item
													label="Payment Received"
													name="is_claim_amount_settled"
													className="mb-3"
													rules={[
														{
															required: true,
															message:
																'Please select!',
														},
													]}
												>
													<XRadio.Group
														onChange={(e) => {
															handleInputChange(
																e,
																revenueCycleFormData,
																setRevenueCycleFormData,
															);
														}}
														name="is_claim_amount_settled"
													>
														<XRadio value={true}>
															Yes
														</XRadio>
														<XRadio value={false}>
															No
														</XRadio>
													</XRadio.Group>
												</XForm.Item>
											</Col>
											{revenueCycleFormData.is_claim_amount_settled ===
												true && (
												<>
													<Col xs={24}>
														<XForm.Item
															label="Payment Date"
															name="reimbursed_at"
															rules={[
																{
																	required: true,
																	message:
																		'Please select Reimbursed At!',
																},
															]}
														>
															<DatePicker
																name="reimbursed_at"
																size="large"
																className="w-100"
																onChange={(
																	date,
																) =>
																	handleDateChange(
																		date,
																		'reimbursed_at',
																	)
																}
																disabledDate={
																	disableFutureDates
																}
															/>
														</XForm.Item>
													</Col>
												</>
											)}
											<Col xs={24}>
												<XForm.Item
													label="Payment Type"
													name="claim_category"
													rules={[
														{
															required: true,
															message:
																'Please select Claim Type!',
														},
													]}
												>
													<XRadio.Group
														onChange={(e) => {
															handleInputChange(
																e,
																revenueCycleFormData,
																setRevenueCycleFormData,
															);
														}}
														name="claim_category"
													>
														<XRadio value={'full'}>
															Full
														</XRadio>
														<XRadio
															value={'partial'}
														>
															Partial
														</XRadio>
													</XRadio.Group>
												</XForm.Item>
											</Col>
											<Row gutter={[10]}>
												<Col xs={24}>
													<XForm.Item
														name="claim_amount"
														label="Claim Amount"
														rules={[
															{
																required: true,
																message:
																	'Please Enter Claim Amount!',
															},
														]}
													>
														<InputNumber
															addonBefore={'$'}
															size="large"
															min={0}
															placeholder="Enter Claim Amount"
															name="claim_amount"
															style={{
																width: '100%',
															}}
															// disabled={
															// 	revenueCycleFormData.claim_category ===
															// 	'full'
															// }
															onChange={(e) =>
																setRevenueCycleFormData(
																	(prev) => ({
																		...prev,
																		claim_amount:
																			e,
																	}),
																)
															}
														/>
													</XForm.Item>
												</Col>
											</Row>
										</Row>
									</Card>
								</Col>
								<Col xs={24} className="text-end mt-4">
									<XForm.Item
										name="remarks"
										label="Enter Remarks"
									>
										<TextArea
											name="remarks"
											showCount
											maxLength={100}
											style={{
												height: 120,
												marginBottom: 24,
											}}
											onChange={(e) =>
												handleInputChange(
													e,
													revenueCycleFormData,
													setRevenueCycleFormData,
												)
											}
											placeholder="Enter remarks"
										/>
									</XForm.Item>
								</Col>
							</>
						)}
					</Row>

					<Row className="mt-4">
						<Col xs={24} className="text-end">
							<XButtonConfirm
								type="primary"
								style={{
									background: '#000',
									color: '#fff',
								}}
								htmlType="submit"
								className="modalButton"
								loading={updateCaseLoading}
							>
								{updateModalButtonText}
							</XButtonConfirm>
						</Col>
					</Row>
				</XForm>
			</XModal>

			{/* uplodoaded Files Previews */}
			<Modal
				open={previewOpen}
				title={previewTitle}
				footer={null}
				onCancel={handleCancel}
			>
				{previewImage ? (
					<img
						alt="example"
						style={{
							width: '100%',
						}}
						src={previewImage}
					/>
				) : null}
			</Modal>
			{/* Modal for uplodoaded Files preview */}
			<Modal
				open={pdfPreviewOpen}
				title="Preview"
				footer={null}
				onCancel={() => setPdfPreviewOpen(false)}
				width={pdfWidth} // Set the width of the modal dynamically
				// Other modal props...
			>
				<div>
					{/* <Document
						file={previewImage}
						onLoadSuccess={onDocumentLoadSuccess}
						onLoadError={onDocumentLoadError}
					>
						<Page
							pageNumber={pageNumber}
							onLoadSuccess={(page) =>
								updateModalWidth(page.width)
							}
						/>
					</Document>
					<p>
						Page {pageNumber} of {numPages}
					</p> */}
					<Worker workerUrl="https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js">
						<div style={{ height: '750px' }}>
							<Viewer fileUrl={previewImage} />
						</div>
					</Worker>
				</div>
			</Modal>

			{/* Modal for Missing Fields */}
			<XModal
				title={
					<>
						{/* <EditOutlined /> */}
						<XModalTitle className="ms-2">
							Additional Information Required
						</XModalTitle>
						{missingFieldTitle && (
							<XModalSubtitle className="ms-2 mt-2">
								{missingFieldTitle}
							</XModalSubtitle>
						)}
						<XDivider dashed className="mt-3" />
					</>
				}
				centered
				open={missingFieldsModal}
				onOk={() => {
					missingFieldsForm
						.validateFields()
						.then((values) => {
							setLoading(true);
							reviseCoverageReset();
							reviseCoverage({
								case_id: patientData._id,
								feedbackId: feedbackId,
								clinicalHistory: pageContent.pastHistory,
								presentComplaint: pageContent.presentComplaint,
								recommendedProcedure:
									pageContent.recommendedProcedure,
								medicalCoverage: pageContent.medicalCoverage,
								state: patientData.state,
								provider: patientData.insurance_company?.name,
								missing_fields: values,
								last_analysis_data: lastAnalysisData,
							});

							missingFieldsForm.resetFields();
							setMissingFields([]);
							setMissingFieldInstructions([]);
							setMissingFieldsModal(false);
						})
						.catch((error) => {
							return;
						});
				}}
				onCancel={() => {
					missingFieldsForm.resetFields();
					setMissingFields([]);
					setMissingFieldInstructions([]);
					setMissingFieldsModal(false);
				}}
				className="modalButton"
				width={900}
				okText="Submit"
				style={{
					padding: '40px',
					borderRadius: '5px !important',
					'ant-modal-content': {
						padding: '30px !important',
						borderRadius: '5px !important',
					},
				}}
			>
				<XForm
					form={missingFieldsForm}
					name="groupForm"
					layout="vertical"
					autoComplete="off"
				>
					<Row gutter={(15, 0)} className="mt-4">
						{missingFieldInstructions.length > 0 && (
							<Col xs={24}>
								<XList
									size="small"
									header={<div>Instructions</div>}
									bordered
									dataSource={missingFieldInstructions}
									renderItem={(item, index) => (
										<List.Item>
											<XCircle>{index + 1}</XCircle>{' '}
											{item}
										</List.Item>
									)}
								/>
								<br />
							</Col>
						)}

						{missingFields.map((item, index) => {
							if (item.type == 'textarea') {
								return (
									<Col xs={24}>
										<XForm.Item
											label={
												<XLabel>{item.label}</XLabel>
											}
											name={item.name}
											rules={[
												item.hasOwnProperty(
													'required',
												) &&
													item.required && {
														required: true,
														message:
															item.required_error_message ??
															'Field is required',
													},
												item.hasOwnProperty('regex') &&
													item.regex && {
														validator: (
															rule,
															value,
														) => {
															let regex =
																new RegExp(
																	item.regex.slice(
																		1,
																		-1,
																	),
																);
															if (
																!regex.test(
																	value,
																)
															)
																return Promise.reject(
																	new Error(
																		item.regex_error_message ??
																			'Invalid Data',
																	),
																);
															return Promise.resolve();
														},
													},
											]}
										>
											<TextArea
												rows={3}
												name={item.name}
												showCount
												className="textArea"
												placeholder="Enter"
											></TextArea>
										</XForm.Item>
									</Col>
								);
							} else if (item.type == 'input') {
								return (
									<Col xs={24}>
										<XForm.Item
											label={
												<XLabel>{item.label}</XLabel>
											}
											name={item.name}
											rules={[
												item.hasOwnProperty(
													'required',
												) &&
													item.required && {
														required: true,
														message:
															item.required_error_message ??
															'Field is required',
													},
												item.hasOwnProperty('regex') &&
													item.regex && {
														validator: (
															rule,
															value,
														) => {
															let regex =
																new RegExp(
																	item.regex.slice(
																		1,
																		-1,
																	),
																);
															if (
																!regex.test(
																	value,
																)
															)
																return Promise.reject(
																	new Error(
																		item.regex_error_message ??
																			'Invalid Data',
																	),
																);
															return Promise.resolve();
														},
													},
											]}
										>
											<Input
												size="large"
												name={item.name}
												placeholder="Enter"
											></Input>
										</XForm.Item>
									</Col>
								);
							} else if (item.type == 'checkbox') {
								return (
									<>
										<Col xs={24} key={index}>
											<XForm.Item
												name={item.name}
												rules={[
													item.required && {
														required: true,
														message:
															item.required_error_message ??
															'Field is required',
													},
												]}
											>
												<XCheckboxGroup
													name={item.name}
													style={{
														display: 'flex',
														flexDirection: 'column',
													}}
												>
													<XList
														className="normal-header"
														size="small"
														bordered
														header={
															<div
																style={{
																	display:
																		'flex',
																	justifyContent:
																		'space-between',
																	alignItems:
																		'center',
																}}
															>
																<XLabel>
																	{item.label}
																</XLabel>
																<XRoundButton
																	shape="circle"
																	icon={
																		<PlusOutlined />
																	}
																	onClick={() =>
																		handleAddOption(
																			index,
																		)
																	}
																/>
															</div>
														}
														dataSource={
															item.options
														}
														renderItem={(
															option,
															idx,
														) => (
															<List.Item>
																<Checkbox
																	value={
																		option.value
																	}
																>
																	{
																		option.label
																	}
																</Checkbox>
															</List.Item>
														)}
													/>
												</XCheckboxGroup>
											</XForm.Item>
										</Col>
									</>
								);
							} else if (item.type == 'radio') {
								return (
									<Col xs={24} key={index}>
										<XForm.Item
											name={item.name}
											rules={[
												item.required && {
													required: true,
													message:
														'Field is required',
												},
											]}
										>
											<Radio.Group
												style={{
													display: 'flex',
													flexDirection: 'column',
												}}
											>
												<XList
													className="normal-header"
													size="small"
													bordered
													header={
														<div
															style={{
																display: 'flex',
																justifyContent:
																	'space-between',
																alignItems:
																	'center',
															}}
														>
															<XLabel>
																{item.label}
															</XLabel>
														</div>
													}
													dataSource={item.options}
													renderItem={(
														option,
														idx,
													) => (
														<List.Item>
															<Radio
																value={
																	option.value
																}
															>
																{option.label}
															</Radio>
														</List.Item>
													)}
												/>
											</Radio.Group>
										</XForm.Item>
									</Col>
								);
							}
						})}
					</Row>
				</XForm>
			</XModal>

			{/* Modal for adding new option */}
			<XModal
				title="Add New Option"
				open={newCheckBoxModal[0]}
				onOk={handleNewCheckBoxModal}
				okText="Submit"
				onCancel={() => {
					setNewCheckBoxModal([false, null]);
					setNewOptionText('');
					newOptionForm.setFieldsValue({});
					newOptionForm.resetFields();
				}}
			>
				<XForm
					form={newOptionForm}
					name="groupForm"
					layout="vertical"
					autoComplete="off"
				>
					<XForm.Item
						name={'new-option'}
						rules={[
							{
								required: true,
								message: 'Please input option',
							},
						]}
					>
						<Input
							size="large"
							className="mt-3 mb-3"
							value={newOptionText}
							onChange={(e) => setNewOptionText(e.target.value)}
							placeholder="Enter new option text"
						/>
					</XForm.Item>
				</XForm>
			</XModal>

			<XModal
				centered
				open={thanksModal[0]}
				onOk={() => setThanksModal([false, ''])}
				onCancel={() => {
					setThanksModal([false, '']);
				}}
				className="modalButton"
				okText="Submit"
				footer={null}
			>
				<Row gutter={(15, 0)} className="mt-4">
					<Col xs={24} lg={{ span: 12, offset: 6 }}>
						<XLottieMargin>
							<XLottie animationData={Thanks} loop={true} />
						</XLottieMargin>

						<XPara1 className="mt-5 mb-4">{thanksModal[1]}</XPara1>
					</Col>
				</Row>
			</XModal>
		</>
	);
};

const mapStateToProps = (state) => ({
	editCaseState: state.editCase,
	addCaseState: state.addCase,
	analyseCoverageState: state.analyseCoverage,
	reviseCoverageState: state.reviseCoverage,
	sendFeedbackState: state.sendFeedback,
	getAnalysisState: state.getAnalysis,
	getAppealLetterState: state.getAppealLetter,
	saveAppealLetterState: state.saveAppealLetter,
	generateAppealLetterState: state.generateAppealLetter,
	getConfigurationsState: state.getConfigurations,
});

const mapDispatchToProps = (dispatch) => ({
	editCase: (data) => dispatch(editCaseApi(data)),
	editCaseReset: () => dispatch(editCaseReset()),
	addCase: (data) => dispatch(addCaseApi(data)),
	addCaseReset: () => dispatch(addCaseReset()),
	sendFeedback: (params) => dispatch(sendFeedbackApi(params)),
	sendFeedbackReset: () => dispatch(sendFeedbackReset()),
	analyseCoverage: (params) => dispatch(analyseCoverageApi(params)),
	analyseCoverageReset: () => dispatch(analyseCoverageReset()),
	reviseCoverage: (params) => dispatch(reviseCoverageApi(params)),
	reviseCoverageReset: () => dispatch(reviseCoverageReset()),
	getAnalysis: (params) => dispatch(getAnalysisApi(params)),
	getAnalysisReset: (params) => dispatch(getAnalysisReset(params)),
	getAppealLetter: (params) => dispatch(getAppealLetterApi(params)),
	saveAppealLetter: (params) => dispatch(saveAppealLetterApi(params)),
	generateAppealLetter: (params) => dispatch(generateAppealLetterApi(params)),
	getConfigurations: (params) => dispatch(getConfigurationsApi(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CaseForm);
