import styled from 'styled-components';
import '../../styles/GlobalStyle';
import { Card, Button, Divider, List } from 'antd';

export const XListItem = styled(List.Item)`
	border: 2px solid #D1D7E5;
	border-radius: 15px;
	margin-bottom: 10px;
	padding: 10px !important;
	color: #212226 !important;
	border-block-end: 2px solid #D1D7E5 !important;
`;
