import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
	Row,
	Col,
	Input,
	Button,
	notification,
	Form,
	Card,
	Switch,
	Result,
} from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import {
	XButton,
	XCardsTransparent,
	XForm,
	XTag,
	XTag2,
} from '../../styles/GlobalStyle';
import { ArrowLeftOutlined } from '@ant-design/icons';
import {
	XCardBackBg,
	XButtonBack,
	XEditTitle,
} from '../CaseForm/CaseFormStyle';
import { XContainer } from '../../styles/GlobalStyle';
import HeaderFile from '../../components/Header/HeaderFile';
import moment from 'moment';

// actions

import {
	getCaseByIdApi,
	getCaseByIdReset,
} from '../../actions/getCaseByIdAction';
import {
	getFeedbackByIdApi,
	getFeedbackByIdReset,
} from '../../actions/getFeedbackByIdAction';
import {
	editFeedbackApi,
	editFeedbackReset,
} from '../../actions/editFeedbackAction';

import {
	checkModulePermissions,
	toTitleCase,
	truncateString,
} from '../../utils/helper';
import { XPara1 } from '../AnalyseCoverage/AnalyseCoverageStyle';
import Header from '../../components/Header/Header';

const FeedbackView = (props) => {
	const {
		getFeedbackById,
		getFeedbackByIdReset,
		getFeedbackByIdState,
		editFeedbackReset,
		editFeedback,
		editFeedbackState,
	} = props;
	const navigate = useNavigate();
	const id = useParams().id;
	const [form] = XForm.useForm();
	const [formData, setFormData] = useState({
		status: 'success',
		successful_claim_commission_criteria: 'percentage',
		successful_claim_commission_fixed_value: null,
		successful_claim_commission_percentage_value: null,
		rejected_claim_commission_criteria: 'percentage',
		rejected_claim_commission_fixed_value: null,
		rejected_claim_commission_percentage_value: null,
		case_amount: null,
		claim_amount: null,
		status_logs: [],
	});
	const [pageContent, setPageContent] = useState({
		original_medical_coverage: '',
		updated_medical_coverage: '',
		refined_medical_coverage: '',
		original_medical_coverage_applicable: '',
		updated_medical_coverage_applicable: 'Yes',
		refined_medical_coverage_applicable: 'Yes',
		status: '',
	});

	const handleSubmitFeedback = () => {
		editFeedback({ ...pageContent, status: 'analysed' });
	};
	// use effects
	useEffect(() => {
		if (id) getFeedbackById({ id: id });
	}, [id]);

	useEffect(() => {
		if (getFeedbackByIdState.apiState === 'success') {
			setFormData({
				...getFeedbackByIdState.data,
				id: getFeedbackByIdState.data.case_id?._id,
				allocated_to: getFeedbackByIdState.data.case_id?.allocated_to
					?.name
					? getFeedbackByIdState.data.case_id?.allocated_to.name
					: '-',
				status: getFeedbackByIdState.data.status,
			});

			setPageContent({
				...getFeedbackByIdState.data,
				id: getFeedbackByIdState.data._id,
				updated_medical_coverage_applicable:
					getFeedbackByIdState.data
						.updated_medical_coverage_applicable,
				refined_medical_coverage_applicable:
					getFeedbackByIdState.data
						.refined_medical_coverage_applicable ??
					getFeedbackByIdState.data
						.updated_medical_coverage_applicable,
			});

			form.setFieldsValue({
				...getFeedbackByIdState.data,
			});
			getFeedbackByIdReset();
		}
	}, [getFeedbackByIdState]);

	useEffect(() => {
		if (editFeedbackState.apiState === 'success') {
			notification.success({ message: editFeedbackState.message });
			editFeedbackReset();
			navigate(-1);
		}
	}, [editFeedbackState]);

	return (
		<>
			{props.displayHeader ? (
				<></>
			) : (
				<>
					<Col lg={24} xl={24} xs={0} md={0}>
						<Header headerTag="Feedback Analysis" />
					</Col>
					<Col lg={0} xl={0} xs={24} md={24}>
						<HeaderFile activeNav="feedbackAnalysis" />
					</Col>
				</>
			)}
			<XContainer fluid>
				{checkModulePermissions(
					'feedback-analysis',
				).sub_modules.includes('view-feedbacks') ? (
					<>
						<XCardBackBg className="mb-3">
							<Row>
								<Col xs={24}>
									<XButtonBack
										onClick={() => navigate(-1)}
										icon={<ArrowLeftOutlined />}
										size="large"
									/>

									<XEditTitle>View Case</XEditTitle>
								</Col>
							</Row>
						</XCardBackBg>

						<XCardsTransparent
							style={{ padding: '15px', paddingBottom: 0 }}
						>
							{formData.case_id ? (
								<Row>
									<Col xl={18} lg={18}>
										<label className="headerStyle me-4">
											{formData.case_id.patient_name}
										</label>
										<XTag className={formData.status}>
											{formData.status
												? truncateString(
														formData.status,
												  )
												: ''}
										</XTag>
									</Col>
									<Col xl={6} lg={6}>
										<p
											style={{
												color: '#171C26',
												fontFamily: 'Poppins',
												fontSize: '16px',
												fontStyle: 'normal',
												fontWeight: '600',
												lineHeight: '24px',
											}}
										>
											Treatment Date :&nbsp;
											<span style={{ fontWeight: 500 }}>
												{moment(
													formData.treatment_date,
												).format('MMMM Do, YYYY')}
											</span>
										</p>
									</Col>
								</Row>
							) : (
								<Row>
									<Col
										className="mt-2"
										md={{ span: 10 }}
										lg={{ span: 10 }}
										xl={{ span: 10 }}
										xxl={{ span: 10 }}
									>
										<XTag className={formData.status}>
											{formData.status
												? truncateString(
														formData.status,
												  )
												: ''}
										</XTag>
									</Col>
									<Col
										className="mt-2"
										md={{ span: 6, offset: 8 }}
										lg={{ span: 6, offset: 8 }}
										xl={{ span: 6, offset: 8 }}
										xxl={{ span: 6, offset: 8 }}
									>
										<p
											style={{
												color: '#171C26',
												fontFamily: 'Poppins',
												fontSize: '16px',
												fontStyle: 'normal',
												fontWeight: '600',
												lineHeight: '24px',
											}}
										>
											Feedback Date :&nbsp;
											<span style={{ fontWeight: 500 }}>
												{moment(
													formData.createdAt,
												).format('MMMM Do, YYYY')}
											</span>
										</p>
									</Col>
								</Row>
							)}

							<Form layout="vertical" className="mt-3">
								{formData.case_id && (
									<Row justify="space-between">
										<Col lg={4} xl={4} md={8} xs={12}>
											<Form.Item
												label="Gender"
												className="labelStyle"
											>
												<span className="subLabelStyle">
													{formData.case_id.gender
														? toTitleCase(
																formData.case_id
																	.gender,
														  )
														: ''}
												</span>
											</Form.Item>
										</Col>
										<Col lg={4} xl={4} md={8} xs={12}>
											<Form.Item
												className="labelStyle"
												label="Date Of Birth"
											>
												<span className="subLabelStyle">
													{moment(
														formData.case_id
															.date_of_birth,
													).format('MMMM Do, YYYY')}
												</span>
											</Form.Item>
										</Col>
										<Col lg={4} xl={4} md={8} xs={12}>
											<Form.Item
												className="labelStyle"
												label="Age"
											>
												{' '}
												<span className="subLabelStyle">
													{moment().diff(
														moment(
															formData.case_id
																.date_of_birth,
														),
														'years',
													)}
												</span>
											</Form.Item>
										</Col>
										<Col lg={4} xl={4} md={8} xs={12}>
											<Form.Item
												className="labelStyle"
												label="Allocated To"
											>
												{' '}
												<span className="subLabelStyle">
													{formData.allocated_to
														? formData.allocated_to
														: ''}
												</span>
											</Form.Item>
										</Col>
										<Col lg={4} xl={4} md={8} xs={12}>
											<Form.Item
												className="labelStyle"
												label="Created On"
											>
												{' '}
												<span className="subLabelStyle">
													{moment(
														formData.createdAt,
													).format('MMMM Do, YYYY')}
												</span>
											</Form.Item>
										</Col>
										<Col lg={4} xl={4} md={8} xs={12}>
											<Form.Item
												className="labelStyle"
												label="Last Date"
											>
												{' '}
												<span className="subLabelStyle">
													{moment(
														formData.updatedAt,
													).format('MMMM Do, YYYY')}
												</span>
											</Form.Item>
										</Col>
									</Row>
								)}
								<Col span={24} className="pb-0">
									<Form.Item
										label="Clinical Summary"
										className="labelStyle"
									>
										<XCardBackBg>
											<XPara1>
												{formData.original_query}
											</XPara1>
										</XCardBackBg>
									</Form.Item>
								</Col>
							</Form>
						</XCardsTransparent>

						<XCardsTransparent
							style={{ padding: '15px', marginTop: '20px' }}
						>
							<Form
								onFinish={handleSubmitFeedback}
								form={form}
								initialValues={pageContent}
							>
								<Row gutter={(15, 30)}>
									<Col
										xs={24}
										lg={
											formData.status === 'approved'
												? 24
												: 12
										}
										md={
											formData.status === 'approved'
												? 24
												: 12
										}
										xl={
											formData.status === 'approved'
												? 24
												: 12
										}
									>
										<Card
											title={
												<span
													style={{
														display: 'flex',
														justifyContent:
															'space-between',
														flexDirection: 'row',
														alignItems: 'center',
													}}
												>
													<span className="feedbackCardHeader">
														System Generated
													</span>
													<XTag2
														className={
															pageContent.original_medical_coverage_applicable
														}
													>
														{pageContent.original_medical_coverage_applicable ===
														'Yes'
															? toTitleCase(
																	'Covered',
															  )
															: toTitleCase(
																	'Not Covered',
															  )}
													</XTag2>
												</span>
											}
											style={{
												borderRadius:
													'20px 20px 20px 20px',
												height: '100%',
											}}
											headStyle={{
												background:
													pageContent.original_medical_coverage_applicable ===
													'Yes'
														? '#E1FCEF'
														: '#ff36361a',
												borderRadius:
													'20px 20px 0px 0px',
											}}
											prefix=""
										>
											<span className="subLabelStyle feedbackText">
												{
													pageContent.original_medical_coverage
												}
											</span>
										</Card>
									</Col>
									{formData.status === 'approved' ? (
										<></>
									) : (
										<>
											<Col
												xs={24}
												lg={12}
												md={12}
												xl={12}
											>
												<Card
													title={
														<span
															style={{
																display: 'flex',
																justifyContent:
																	'space-between',
																flexDirection:
																	'row',
																alignItems:
																	'center',
															}}
														>
															<span className="feedbackCardHeader">
																User Feedback
															</span>
															<XTag2
																className={
																	pageContent.updated_medical_coverage_applicable
																}
															>
																{!pageContent.feedback
																	? 'No Feedback'
																	: pageContent.updated_medical_coverage_applicable ===
																	  'Yes'
																	? toTitleCase(
																			'Covered',
																	  )
																	: toTitleCase(
																			'Not Covered',
																	  )}
															</XTag2>
														</span>
													}
													style={{
														borderRadius:
															'20px 20px 20px 20px',
														height: '100%',
													}}
													headStyle={{
														background:
															pageContent.updated_medical_coverage_applicable ===
															'Yes'
																? '#E1FCEF'
																: '#ff36361a',
														borderRadius:
															'20px 20px 0px 0px',
													}}
													className={
														pageContent.updated_medical_coverage_applicable
													}
												>
													<span className="subLabelStyle feedbackText">
														{
															pageContent.updated_medical_coverage
														}
													</span>
												</Card>
											</Col>

											<Col
												xs={24}
												lg={24}
												md={24}
												xl={24}
												className="mt-4"
											>
												<Form.Item
													label="Modify Coverage"
													name="refined_medical_coverage_applicable"
													rules={[
														{
															required: true,
															message:
																'Please input Coverage',
														},
													]}
												>
													<Switch
														checkedChildren={'Yes'}
														unCheckedChildren={'No'}
														name="refined_medical_coverage_applicable"
														checked={
															pageContent.refined_medical_coverage_applicable ===
															'Yes'
																? true
																: false
														}
														onChange={(e) => {
															const value = e
																? 'Yes'
																: 'No';
															setPageContent({
																...pageContent,
																refined_medical_coverage_applicable:
																	value,
															});
														}}
													/>
												</Form.Item>
											</Col>
											<Col
												xs={24}
												lg={24}
												md={24}
												xl={24}
											>
												<Form.Item
													name="refined_medical_coverage"
													rules={[
														{
															required: true,
															message:
																'Please input Intro',
														},
													]}
												>
													<Input.TextArea
														className="feedbackTextArea"
														placeholder="Modify the feedback"
														showCount
														onChange={(e) => {
															setPageContent({
																...pageContent,
																refined_medical_coverage:
																	e.target
																		.value,
															});
														}}
													/>
												</Form.Item>
											</Col>
											<Col
												className="mt-4 text-end"
												xs={24}
												lg={{ span: 6, offset: 0 }}
												md={{ span: 6, offset: 0 }}
												sm={{ span: 6, offset: 0 }}
											>
												<XButton htmlType="submit">
													Modify Feedback
												</XButton>
											</Col>
										</>
									)}
								</Row>
							</Form>
						</XCardsTransparent>
					</>
				) : (
					<Result
						status="403"
						title="403"
						subTitle="Sorry, you are not authorized to access this page."
						extra={
							<Button
								type="primary"
								onClick={() => navigate('/')}
							>
								Back Home
							</Button>
						}
					/>
				)}
			</XContainer>
		</>
	);
};

const mapStateToProps = (state) => ({
	getCaseByIdState: state.getCaseById,
	getFeedbackByIdState: state.getFeedbackById,
	editFeedbackState: state.editFeedback,
});

const mapDispatchToProps = (dispatch) => ({
	getCaseById: (data) => dispatch(getCaseByIdApi(data)),
	getCaseByIdReset: () => dispatch(getCaseByIdReset()),
	getFeedbackById: (data) => dispatch(getFeedbackByIdApi(data)),
	getFeedbackByIdReset: () => dispatch(getFeedbackByIdReset()),
	editFeedback: (data) => dispatch(editFeedbackApi(data)),
	editFeedbackReset: () => dispatch(editFeedbackReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackView);
