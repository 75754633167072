import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Input, Button, Result, Card, Form } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { XCardsTransparent, XForm, XModal } from '../../styles/GlobalStyle';
import { ArrowLeftOutlined, DeleteOutlined } from '@ant-design/icons';
import {
	XCardBackBg,
	XButtonBack,
	XEditTitle,
} from '../CaseForm/CaseFormStyle';
import { XContainer } from '../../styles/GlobalStyle';
import HeaderFile from '../../components/Header/HeaderFile';

// actions

import { checkModulePermissions } from '../../utils/helper';
import {
	getModuleByIdApi,
	getModuleByIdReset,
} from '../../actions/getModuleByIdAction';
import {
	getModulesListApi,
	getModulesListReset,
} from '../../actions/getModulesListAction';
import { editModuleApi, editModuleReset } from '../../actions/editModuleAction';
import { addModuleApi, addModuleReset } from '../../actions/addModuleAction';
import {
	deleteModuleApi,
	deleteModuleReset,
} from '../../actions/deleteModuleAction';
import Header from '../../components/Header/Header';

const ModuleForm = (props) => {
	const {
		getModuleByIdState,
		editModuleState,
		addModuleState,
		getModuleById,
		editModule,
		addModule,
		deleteModule,
		addModuleReset,
		editModuleReset,
		getModuleByIdReset,
	} = props;
	const navigate = useNavigate();
	const id = useParams().id;
	let modulePermissions = checkModulePermissions('module-management');
	const [form] = XForm.useForm();
	const [formData, setFormData] = useState({});

	const [dataLoaded, setDataLoaded] = useState(false);

	// use effects
	useEffect(() => {
		if (id) {
			getModuleById({ id: id });
		} else {
			setDataLoaded(true);
		}
	}, [id]);

	useEffect(() => {
		if (getModuleByIdState.apiState === 'success') {
			const moduleData = getModuleByIdState.data;
			setFormData({
				...moduleData,
				sub_modules: moduleData.sub_modules || [],
			});
			form.setFieldsValue({
				...moduleData,
				sub_modules: moduleData.sub_modules || [], // Set sub-modules data here
			});
			getModuleByIdReset();
		}
	}, [getModuleByIdState]);

	useEffect(() => {
		if (addModuleState.apiState === 'success') {
			addModuleReset();
			navigate(-1);
		}
	}, [addModuleState]);

	useEffect(() => {
		if (editModuleState.apiState === 'success') {
			editModuleReset();
			navigate(-1);
		}
	}, [editModuleState]);

	const handleSubModuleNameChange = (index, value, name) => {
		let slug = value.toLowerCase().split(' ').join('-');
		setFormData((prevFormData) => {
			const updatedSubModules =
				prevFormData && prevFormData.sub_modules
					? [...prevFormData.sub_modules]
					: [];
			// Check if the index is within the existing sub-modules array
			if (index < updatedSubModules.length) {
				// Update the existing sub-module's sub_module_name
				updatedSubModules[index].sub_module_name = value;
				updatedSubModules[index].sub_module_slug = slug;
			} else {
				// Add a new sub-module to the array
				updatedSubModules.push({
					sub_module_name: value,
					sub_module_slug: slug, // You may initialize other properties as needed
				});
			}

			form.setFieldsValue({ sub_modules: updatedSubModules });
			return {
				...(prevFormData || {}),
				sub_modules: updatedSubModules,
			};
		});
	};

	const handleDeleteSubModule = (index) => {
		setFormData((prevFormData) => {
			const updatedSubModules =
				prevFormData && prevFormData.sub_modules
					? [...prevFormData.sub_modules]
					: [];

			// Check if the index is within the existing sub-modules array
			if (index < updatedSubModules.length) {
				// Add the deletedAt field with the current date to the sub-module at the specified index
				updatedSubModules[index].delete_sub_module = true;
			}

			return {
				...prevFormData,
				sub_modules: updatedSubModules,
			};
		});
	};

	const handleInputChange = (e) => {
		let slug = e.target.value.toLowerCase().split(' ').join('-');
		setFormData((prevFormData) => {
			return {
				...(prevFormData || {}),
				module_name: e.target.value,
				module_slug: slug,
			};
		});
		form.setFieldValue('module_slug', slug);
	};

	const showDeleteConfirm = (_id, name) => {
		XModal.confirm({
			title: 'Delete Module?',
			content: (
				<p className="modalPara1">
					Are you sure you want to delete {name}?
				</p>
			),
			icon: <img className="me-3" src="/images/delete-icon.svg" alt="" />,
			okText: 'Yes, Confirm',
			okType: 'danger',
			cancelText: 'No, Cancel',
			className: 'confirmModal',
			onOk() {
				deleteModule({ id: _id });
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	return (
		<>
			{props.displayHeader ? (
				<></>
			) : (
				<>
					<Col lg={24} xl={24} xs={0} md={0}>
						<Header headerTag="Module Configuration" />
					</Col>
					<Col lg={0} xl={0} xs={24} md={24}>
						<HeaderFile activeNav="moduleConfiguration" />
					</Col>
				</>
			)}
			{modulePermissions.authorized ? (
				<XContainer fluid className="mt-3">
					<XCardBackBg className="mb-3">
						<Row>
							<Col xs={24}>
								<XButtonBack
									onClick={() => navigate(-1)}
									icon={<ArrowLeftOutlined />}
									size="large"
								/>
								{id ? (
									<XEditTitle>Edit Module</XEditTitle>
								) : (
									<XEditTitle>Add Module</XEditTitle>
								)}{' '}
							</Col>
						</Row>
					</XCardBackBg>

					<XCardsTransparent>
						<XForm
							form={form}
							name="roleForm"
							layout="vertical"
							autoComplete="off"
							onFinish={() => {
								if (id)
									editModule({
										id: id,
										...formData,
									});
								else
									addModule({
										...formData,
									});
							}}
						>
							<Row gutter={(15, 30)} className="mt-4">
								<Col xs={24} md={12} lg={12}>
									<XForm.Item
										name="module_name"
										label="Module Name"
										rules={[
											{
												required: true,
												message:
													'Please enter Module Name',
											},
										]}
									>
										<Input
											size="large"
											placeholder="Enter Module Name"
											name="module_name"
											onChange={(e) => {
												handleInputChange(e);
											}}
										/>
									</XForm.Item>
								</Col>
								<Col xs={24} md={12} lg={12}>
									<XForm.Item
										name="module_slug"
										label="Module Slug"
									>
										<Input
											size="large"
											placeholder="Module Slug"
											name="module_slug"
											disabled
											value={formData.module_slug}
										/>
									</XForm.Item>
								</Col>
							</Row>
							<Form.List name="sub_modules">
								{(fields, { add, remove }) => (
									<Row gutter={[15, 30]}>
										{fields.map(
											(
												{ key, name, ...restField },
												index,
											) => (
												<Col xs={24} md={8}>
													<Card
														style={{
															borderRadius:
																'20px',
															border: '1px solid rgba(0, 0, 0, 0.15)',
															background: '#FFF',
														}}
														headStyle={{
															height: '10px',
														}}
														className="module-card"
													>
														<DeleteOutlined
															onClick={() => {
																handleDeleteSubModule(
																	index,
																);
																remove(index);
															}}
															style={{
																display: 'flex',
																justifyContent:
																	'end',
															}}
														/>
														<XForm.Item
															name={[
																name,
																`sub_module_name`,
															]}
															{...restField}
															label="Sub Module Name"
															rules={[
																{
																	required: true,
																	message:
																		'Please enter Sub Module Name',
																},
															]}
														>
															<Input
																size="large"
																placeholder="Enter Sub Module Name"
																name={`sub_module_name`}
																onChange={(
																	e,
																) => {
																	handleSubModuleNameChange(
																		index,
																		e.target
																			.value,
																		e.target
																			.name,
																	);
																}}
															/>
														</XForm.Item>
														<XForm.Item
															name={[
																name,
																`sub_module_slug`,
															]}
															style={{
																marginBottom: 0,
															}}
															{...restField}
															label="Sub Module Slug"
														>
															<Input
																size="large"
																placeholder="Sub Module Slug"
																name={`sub_module_slug`}
																disabled
															/>
														</XForm.Item>
													</Card>
												</Col>
											),
										)}

										<Col xs={24} md={8}>
											<Form.Item>
												<Card
													headStyle={{
														border: 'none',
													}}
													style={{
														borderRadius: '20px',
														border: '1.5px dashed #000',
														background:
															'var(--Linear, linear-gradient(90deg, #69EACB 0%, #EACCF8 48%, #6654F1 100%))',

														opacity: 0.15,
														display: 'flex',
														alignItems: 'center',
														justifyContent:
															'center',
														minHeight: '249.2px',
													}}
													onClick={() => add()}
												>
													<div
														style={{
															textAlign: 'center',
														}}
													>
														<img
															src={`/images/module-plus.svg`}
															alt=""
														/>
													</div>

													<p
														style={{
															color: '#212226',
															fontFamily:
																'Poppins',
															fontSize: '16px',
															marginTop: '20px',
														}}
													>
														Add More Sub Modules
													</p>
												</Card>
											</Form.Item>
										</Col>
									</Row>
								)}
							</Form.List>

							<Row gutter={[15, 5]}>
								<Col
									xs={24}
									lg={24}
									md={24}
									sm={24}
									className="text-end"
								>
									{modulePermissions.sub_modules.includes(
										'delete-module',
									) ? (
										<Button
											onClick={() =>
												showDeleteConfirm(
													formData._id,
													formData.module_name,
												)
											}
											style={{
												background: '#fff',
												color: '#000',
											}}
											className="text-end"
										>
											Delete
										</Button>
									) : (
										<></>
									)}
									<Button htmlType="submit">Submit</Button>
								</Col>
							</Row>
						</XForm>
					</XCardsTransparent>
				</XContainer>
			) : (
				<Result
					status="403"
					title="403"
					subTitle="Sorry, you are not authorized to access this page."
					extra={
						<Button type="primary" onClick={() => navigate('/')}>
							Back Home
						</Button>
					}
				/>
			)}
		</>
	);
};

const mapStateToProps = (state) => ({
	getModuleByIdState: state.getModuleById,
	getModulesListState: state.getModulesList,
	editModuleState: state.editModule,
	addModuleState: state.addModule,
	deleteModuleState: state.deleteModule,
});

const mapDispatchToProps = (dispatch) => ({
	getModuleById: (params) => dispatch(getModuleByIdApi(params)),
	getModulesList: (params) => dispatch(getModulesListApi(params)),
	editModule: (params) => dispatch(editModuleApi(params)),
	addModule: (params) => dispatch(addModuleApi(params)),
	deleteModule: (params) => dispatch(deleteModuleApi(params)),
	getModulesListReset: (params) => dispatch(getModulesListReset(params)),
	deleteModuleReset: (params) => dispatch(deleteModuleReset(params)),
	addModuleReset: (params) => dispatch(addModuleReset()),
	editModuleReset: (params) => dispatch(editModuleReset()),
	getModuleByIdReset: (params) => dispatch(getModuleByIdReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModuleForm);
