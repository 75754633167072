import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Card, Result, Radio } from 'antd';
import { Button } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import {
	XTag,
	XForm,
	XContainer,
	XButton,
	FilterButton,
	XSearch,
} from '../../styles/GlobalStyle';

import moment from 'moment';
import CaseSearch from '../../components/CaseSearch/CaseSearch';
// actions
import { getFeedbacksApi } from '../../actions/getFeedbacksAction';
import { exportFeedbacksApi } from '../../actions/exportFeedbacksAction';
import {
	checkModulePermissions,
	toTitleCase,
	truncateString,
	urlPagination,
} from '../../utils/helper';
import { dateFilters } from '../../utils/constants';
import HeaderFile from '../../components/Header/HeaderFile';
import DataTable from '../../components/DataTable/DataTable';
import Header from '../../components/Header/Header';
var Excel = require('exceljs');

const CaseManagement = (props) => {
	const {
		getFeedbacks,
		getFeedbacksState,
		exportFeedbacks,
		exportFeedbacksState,
	} = props;

	const navigate = useNavigate();

	const defaultFilters = {
		search: '',
		page: 1,
		limit: 10,
		sort: '',
		sortDirection: 'descend',
		created_on: 'all',
		hospital: '',
		state: '',
		status: '',
		insurance_company: '',
		medical_coverage: '',
	};
	const [tableData, setTableData] = useState([]);
	const [totalRecords, setTotalRecords] = useState('');

	const [form] = XForm.useForm();

	const [exportedData, setExportedData] = useState([]);
	const caseStatuses = [
		{ label: 'Pending for Analysis', value: 'pending_for_analysis' },
		{ label: 'Approved', value: 'approved' },
	];
	const [open, setOpen] = useState(false);
	const getFiltersFromUrl = () => {
		const urlParams = new URLSearchParams(window.location.search);
		const urlFilters = {};

		// Iterate through each query parameter and set it in the state
		for (const [key, value] of urlParams.entries()) {
			urlFilters[key] = value;
		}

		// Merge with default filters or initial state as needed
		const filters = {
			...defaultFilters, // Replace with your default filters
			...urlFilters,
		};

		return filters;
	};
	const initialFilters = getFiltersFromUrl();

	const [filters, setFilters] = useState(initialFilters);

	const showDrawer = () => {
		setOpen(true);
	};
	const onClose = () => {
		setOpen(false);
	};

	const onChange = (key) => {
		setFilters({
			...filters,
			[key.target.name]: key.target.value,
		});
	};

	const columns = [
		{
			title: 'Case ID',
			dataIndex: 'case_id',
			fixed: 'left',
			width: 120,
			render: (case_id) => (
				<Link
					style={{ color: '#000', textDecoration: 'underline' }}
					to={`${case_id[2]}/${case_id[0]}`}
				>
					<div>{case_id[1]}</div>
				</Link>
			),
		},
		{
			title: 'Patient ID',
			dataIndex: 'mrn',
			fixed: 'left',
			width: 120,
			render: (mrn) => (
				<Link
					style={{ color: '#000', textDecoration: 'underline' }}
					to={`${mrn[2]}/${mrn[0]}`}
				>
					<div>{mrn[1]}</div>
				</Link>
			),
		},
		{
			title: 'Case Name',
			dataIndex: 'patient_name',
			fixed: 'left',
			width: 200,
			render: (patient_name) => (
				<Link
					style={{ color: '#000', textDecoration: 'underline' }}
					to={`${patient_name[2]}/${patient_name[0]}`}
				>
					<div> {truncateString(patient_name[1], 25)}</div>
				</Link>
			),
		},
		// {
		// 	title: 'ORIGINAL MEDICARE COVERAGE',
		// 	dataIndex: 'original_medical_coverage_applicable',
		// },
		// {
		// 	title: 'UPDATED MEDICARE COVERAGE',
		// 	dataIndex: 'updated_medical_coverage_applicable',
		// },
		{
			title: 'FEEDBACK TYPE',
			dataIndex: 'feedback_type',
			width: 120,
		},
		{
			title: 'Feedback',
			dataIndex: 'feedback',
			width: 650,
		},
		{
			title: 'FEEDBACK DATE',
			dataIndex: 'createdAt',
			width: 150,

			sorter: (a, b) => a.createdAt - b.createdAt,
			sortDirections: ['ascend', 'descend'],
		},
		{
			title: 'Status',
			dataIndex: 'status',
			width: 200,
		},
	];

	const handleExportFeedbacks = () => {
		// Extract field names from the first feedback object
		const fieldNames = Object.keys(exportedData[0]);

		var wb = new Excel.Workbook();
		const workbook = wb.addWorksheet('Sheet1');

		workbook.getRow(1).values = fieldNames;

		exportedData.forEach((element, index) => {
			const rowIndex = index + 2;
			const rows = [];
			rows[0] = element['Case ID'];
			rows[1] = element['Patient Name'];
			rows[2] = element['MRN'];
			rows[5] = element['Original Medicare Coverage'];
			rows[6] = element['Original Medicare Coverage Applicable'];
			rows[3] = element['Updated Medicare Coverage'];
			rows[4] = element['Updated Medicare Coverage Applicable'];
			rows[7] = element['Feedback'];
			rows[8] = element['Status'];
			rows[9] = element['Created At'];
			workbook.getRow(rowIndex).values = rows;
		});
		wb.xlsx.writeBuffer().then((buffer) => {
			const fileData = new Blob([buffer], {
				type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			});
			const fileUrl = URL.createObjectURL(fileData);
			const link = document.createElement('a');
			link.href = fileUrl;
			link.download = 'Feedback.xlsx';
			link.click();
		});
	};

	// use effects
	useEffect(() => {
		exportFeedbacks(filters);
	}, [filters]);

	useEffect(() => {
		if (getFeedbacksState.apiState === 'success') {
			if (getFeedbacksState.data.all_feedbacks.length > 0) {
				const updatedData = [];
				const updatedSearchOptions = [];
				setTotalRecords(getFeedbacksState.data.total_count);
				getFeedbacksState.data.all_feedbacks.map((item, index) => {
					updatedSearchOptions.push({ value: item.patient_name });
					updatedData.push({
						key: item._id,
						case_id: [item._id, item.case_id, '/feedback/view'],
						mrn: [item._id, item.mrn, '/feedback/view'],
						patient_name: [
							item._id,
							item.patient_name,
							'/feedback/view',
						],
						original_medical_coverage_applicable:
							item.original_medical_coverage_applicable,
						updated_medical_coverage_applicable:
							item.updated_medical_coverage_applicable,
						feedback: item.updated_medical_coverage
							? truncateString(item.updated_medical_coverage, 80)
							: truncateString(
									item.original_medical_coverage,
									80,
							  ),
						feedback_type: item.feedback
							? toTitleCase(item.feedback)
							: '',
						status: (
							<>
								<XTag className={item.status}>
									{item.status
										? truncateString(item.status)
										: ''}
								</XTag>
							</>
						),
						createdAt: (
							<div className="showDate">
								{moment(item.createdAt).format('DD-MM-YYYY')}
							</div>
						),
						statusMobile: item.status,
					});
				});
				setTableData(updatedData);
			} else {
				setTableData([]);
				setTotalRecords(0);
			}
		}
	}, [getFeedbacksState]);

	useEffect(() => {
		if (exportFeedbacksState.apiState === 'success') {
			if (exportFeedbacksState.data.length > 0) {
				const updatedData = [];
				const updatedSearchOptions = [];
				setExportedData(exportFeedbacksState.data);
			} else {
				setExportedData([]);
			}
		}
	}, [exportFeedbacksState]);

	const applyFilters = () => {
		const queryParams = new URLSearchParams(window.location.search);

		// Iterate through filters and update the query parameters
		Object.entries(filters).forEach(([key, value]) => {
			const currentValue = queryParams.get(key);

			// Check if the parameter exists in the current query and has a value, if yes, update its value
			if (currentValue !== null) {
				queryParams.set(key, value);
			} else if (queryParams.has(key)) {
				// If the parameter exists but has no value, keep its existing value
				queryParams.set(key, queryParams.get(key));
			} else {
				// If the parameter does not exist in the current query, append it with the current value
				queryParams.append(key, value);
			}
		});

		// Set the page to 1
		queryParams.set('page', '1');
		queryParams.set('sort', 'createdAt');
		queryParams.set('sortDirection', 'descend');
		filters.page = 1;

		// Update the URL
		const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
		window.history.pushState({}, '', newUrl);
	};

	useEffect(() => {
		// Retrieve filters from the updated URL
		const updatedFilters = getFiltersFromUrl();
		setFilters({ ...updatedFilters });

		// Make API call with updated filters and pagination
		checkModulePermissions('feedback-analysis').sub_modules.includes(
			'list-feedbacks',
		) && getFeedbacks({ ...updatedFilters });
	}, [window.location.search]);

	const clearFilters = () => {
		const queryParams = new URLSearchParams(window.location.search);

		// Iterate through defaultFilters and update the query parameters
		Object.entries(defaultFilters).forEach(([key, value]) => {
			const currentValue = queryParams.get(key);

			// Check if the parameter exists in the current query and has a value, if yes, update its value
			if (currentValue !== null) {
				queryParams.set(key, value);
			} else if (queryParams.has(key)) {
				// If the parameter exists but has no value, keep its existing value
				queryParams.set(key, queryParams.get(key));
			} else {
				// If the parameter does not exist in the current query, append it with the default value
				queryParams.append(key, value);
			}
		});

		// Clear the form fields
		form.resetFields();

		// Update the state with default values
		setFilters(defaultFilters);

		// Update the URL
		const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
		window.history.pushState({}, '', newUrl);
	};

	return (
		<>
			{props.displayHeader ? (
				<></>
			) : (
				<>
					<Col lg={24} xl={24} xs={0} md={0}>
						<Header headerTag="Feedback Analysis" />
					</Col>
					<Col lg={0} xl={0} xs={24} md={24}>
						<HeaderFile activeNav="feedbackAnalysis" />
					</Col>
				</>
			)}
			<XContainer fluid>
				{checkModulePermissions(
					'feedback-analysis',
				).sub_modules.includes('list-feedbacks') ? (
					<Card
						bodyStyle={{ padding: '10px' }}
						style={{ borderRadius: '30px' }}
					>
						<CaseSearch
							filters={[
								<XForm.Item
									name="status"
									colon={false}
									style={{
										display: 'flex',
										flexDirection: 'column',
									}}
									label="Status"
									className="removeFormMargin"
								>
									<Radio.Group
										onChange={onChange}
										name="status"
										style={{ width: '100%' }}
										defaultValue={
											filters.status
												? filters.status
												: null
										}
									>
										<Row>
											{caseStatuses.map((item, index) => (
												<Col
													xs={12}
													lg={12}
													md={12}
													xl={12}
												>
													<Radio
														value={item.value}
														className="filter-sub-item"
													>
														{item.label}
													</Radio>
												</Col>
											))}
										</Row>
									</Radio.Group>
								</XForm.Item>,
								<XForm.Item
									name="created_on"
									colon={false}
									label="Date Within"
									className="removeFormMargin"
								>
									<Radio.Group
										onChange={onChange}
										name="created_on"
										defaultValue={
											filters.created_on
												? filters.created_on
												: null
										}
									>
										<Row>
											{dateFilters.map((item, index) => (
												<Col
													xs={12}
													lg={12}
													md={12}
													xl={12}
												>
													<Radio
														value={item.value}
														className="filter-sub-item"
													>
														{item.label}
													</Radio>
												</Col>
											))}
										</Row>
									</Radio.Group>
								</XForm.Item>,
							]}
							onClose={onClose}
							open={open}
							applyFilters={applyFilters}
							form={form}
							clearFilters={clearFilters}
						/>
						<Row gutter={(15, 30)} style={{ padding: '20px ' }}>
							<Col
								style={{
									display: 'flex',
									alignItems: 'center',
								}}
								xs={24}
								md={10}
								lg={3}
								xl={3}
								xxl={3}
							>
								<p className="tableHeader">
									Total {totalRecords} Feedbacks
								</p>{' '}
							</Col>
							<Col
								xs={24}
								md={14}
								lg={{ span: 6 }}
								xl={{ span: 6 }}
								xxl={{ span: 6 }}
							>
								<XForm.Item>
									<XSearch
										placeholder="Search for Cases"
										allowClear
										enterButton="Search"
										size="large"
										defaultValue={
											filters.patient_name
												? filters.patient_name
												: null
										}
										onChange={(v) => {
											if (v.target.value === '') {
												urlPagination(
													filters,
													'patient_name',
													'',
												);
												setFilters({
													...filters,
													patient_name: '',
												});
											}
										}}
										onSearch={(v) => {
											urlPagination(
												filters,
												'patient_name',
												v,
											);
											setFilters({
												...filters,
												patient_name: v,
											});
										}}
									/>
								</XForm.Item>
							</Col>
							<Col
								xs={24}
								md={12}
								lg={{ span: 4, offset: 7 }}
								xl={{ span: 4, offset: 7 }}
								xxl={{ span: 4, offset: 7 }}
							>
								<XForm.Item className="text-right">
									<FilterButton
										onClick={() => showDrawer()}
										icon={
											<img
												src="/images/filter-add.svg"
												alt="bell"
												height={'20px'}
											/>
										}
									>
										Filters
									</FilterButton>
								</XForm.Item>
							</Col>
							<Col
								xs={24}
								md={12}
								lg={{ span: 4 }}
								xl={{ span: 4 }}
								xxl={{ span: 4 }}
							>
								<XForm.Item className="text-right">
									<XButton onClick={handleExportFeedbacks}>
										Export Analysed Data
									</XButton>
								</XForm.Item>
							</Col>
						</Row>
						<DataTable
							tableData={tableData}
							columns={columns}
							totalRecords={totalRecords}
							setFilters={setFilters}
							filters={filters}
							state={getFeedbacksState.apiState}
							page={'feedback'}
						/>
					</Card>
				) : (
					<Result
						status="403"
						title="403"
						subTitle="Sorry, you are not authorized to access this page."
						extra={
							<Button
								type="primary"
								onClick={() => navigate('/')}
							>
								Back Home
							</Button>
						}
					/>
				)}
			</XContainer>
		</>
	);
};

const mapStateToProps = (state) => ({
	getFeedbacksState: state.getFeedbacks,
	exportFeedbacksState: state.exportFeedbacks,
});

const mapDispatchToProps = (dispatch) => ({
	getFeedbacks: (data) => dispatch(getFeedbacksApi(data)),
	exportFeedbacks: (data) => dispatch(exportFeedbacksApi(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CaseManagement);
