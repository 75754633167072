import styled, { css } from 'styled-components';
import '../../styles/GlobalStyle';
import Lottie from 'lottie-react';
import { Card, Button, Divider, Checkbox } from 'antd';

export const XCardsGrdBG = styled(Card)`
	background: var(
		--Linear,
		linear-gradient(
			90deg,
			rgba(105, 234, 203, 0.15) 0%,
			rgba(234, 204, 248, 0.15) 48%,
			rgba(102, 84, 241, 0.15) 100%
		)
	);
	.ant-card-body {
		padding: 15px;
		@media only screen and (max-width: 768.99px) {
		}
	}
`;
export const XLottieMargin = styled.section`
	margin: -60px 0px -60px 0px;
	@media only screen and (max-width: 767.99px) {
		margin: -30px 0px -30px 0px;
	}
`;
export const XLottieOverlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.8);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999;
`;
export const Title = styled.h1`
	margin: 0px;
	text-align: center;
	background: linear-gradient(90deg, #69eacb 0%, #e2a3ff 48%, #6654f1 100%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-size: 50px;
	font-style: normal;
	font-weight: 600;
	line-height: 60px;
	letter-spacing: 1.25px;
	text-transform: capitalize;
	@media only screen and (max-width: 767.99px) {
	}
`;
export const XPara1 = styled.div`
	color: ${({ theme }) => theme.colors.menu2};
	font-family: ${({ theme }) => theme.colors.fontInter};
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 28px; /* 175% */
	@media only screen and (max-width: 769px) {
		font-size: 14px;
	}
`;
export const XButtonAdd = styled(Button)`
	background: ${({ theme }) => theme.colors.white};
	border: 1px solid ${({ theme }) => theme.colors.menu2};
	color: ${({ theme }) => theme.colors.menu2};
	svg {
		margin-top: 3px;
	}

	&:hover,
	svg {
		border-color: ${({ theme }) => theme.colors.menu2} !important;
		color: ${({ theme }) => theme.colors.menu2};
	}

	@media only screen and (max-width: 767.99px) {
	}
`;
export const XTitleHead = styled(Divider)`
	margin: 0px;
	cursor: text !important;
	height: auto !important;
	font-family: ${({ theme }) => theme.colors.fontInter};
	font-size: 22px !important;
	font-style: normal;
	font-weight: 600 !important;
	line-height: 28px;
	.ant-divider-inner-text {
		padding: 0px !important;
	}
	.color1 {
		color: #69b4fd;
	}
	.color2 {
		color: #fda171;
	}
	.color3 {
		color: #7a82fd;
	}
	.color4 {
		color: #68ca84;
	}
	.color5 {
		color: #cd4949;
	}
	@media only screen and (max-width: 768.99px) {
		font-size: 16px;
	}
`;
export const XDivider = styled(Divider)`
	border-color: rgba(0, 0, 0, 0.25);
	margin: 40px 0px 25px 0px;
	@media only screen and (max-width: 768.99px) {
	}
`;
export const XButtonLike = styled(Button)`
	background: ${({ theme }) => theme.colors.white};
	border: 1px solid ${({ theme }) => theme.colors.color6};
	color: ${({ theme }) => theme.colors.menu2};
	img,
	svg {
		width: 18px !important;
	}
	&:hover {
		background: ${({ theme }) => theme.colors.white} !important;
		border-color: ${({ theme }) => theme.colors.color6} !important;
		color: ${({ theme }) => theme.colors.menu2} !important;
	}
	.rotateButton {
		transform: rotate(3.142rad);
	}

	@media only screen and (max-width: 767.99px) {
	}
`;
export const XButtonConfirm = styled(Button)`
	background: ${({ theme }) => theme.colors.white};
	border: 1px solid ${({ theme }) => theme.colors.color6};
	color: ${({ theme }) => theme.colors.menu2};
	img,
	svg {
		width: 18px !important;
	}
	&[disabled] {
		background: rgba(0, 0, 0, 0.25) !important;
		border-color: rgba(0, 0, 0, 0.25) !important;
		color: rgba(0, 0, 0, 0.25);

		&:hover {
			background: rgba(0, 0, 0, 0.25) !important;
			border-color: rgba(0, 0, 0, 0.25) !important;
			color: rgba(0, 0, 0, 0.25);
		}
	}
	&:hover {
		background: ${({ theme }) => theme.colors.menu2} !important;
		border-color: ${({ theme }) => theme.colors.menu2} !important;

		color: ${({ theme }) => theme.colors.white} !important;
	}
	.rotateButton {
		transform: rotate(3.142rad);
	}

	@media only screen and (max-width: 767.99px) {
	}
`;

export const XNotificationBar = styled(Card)`
	border-radius: 20px;
	background: var(
		--Linear,
		linear-gradient(
			90deg,
			rgba(105, 234, 203, 0.15) 0%,
			rgba(234, 204, 248, 0.15) 48%,
			rgba(102, 84, 241, 0.15) 100%
		)
	);
	.ant-card-body {
		text-align: center;
		padding: 15px !important;
		border-radius: 20px;
		border: none;
		@media only screen and (max-width: 768.99px) {
			padding: 10px !important;
		}
	}
	z-index: 1111;
`;

export const XButtonCloseBar = styled(Button)`
	position: absolute;
	right: 1%;
	transform: translateY(-50%);
	background-color: transparent;
	border: none;
	font-size: 2em;
	color: grey;
	cursor: pointer;
	&:hover {
		color: inherit;
	}
`;

export const XButtonReload = styled(Button)`
	border-radius: 400px;
	background: #212226;
	color: #fff;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: -0.4px;
	border: none;
`;

export const XNotificationCard = styled(Card)`
	border-radius: 20px;
	background: var(
		--Linear,
		linear-gradient(
			90deg,
			rgba(105, 234, 203, 0.15) 0%,
			rgba(234, 204, 248, 0.15) 48%,
			rgba(102, 84, 241, 0.15) 100%
		)
	);
	.ant-card-body {
		padding: 15px !important;
		border-radius: 20px;
		border: none;
		@media only screen and (max-width: 768.99px) {
			padding: 10px !important;
		}
	}
	position: fixed;
	border-radius: 5px;
	box-sizing: border-box;
	color: black;
	font-size: 1em;
	margin: 0 auto;
	margin-bottom: 1em;
	padding: 0.5em;
	width: 100%;
	top: 0;
	z-index: 1;
`;

export const XCheckboxGroup = styled(Checkbox.Group)`
	.ant-checkbox-group-item {
		margin-bottom: 10px;
	}
`;
