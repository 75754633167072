import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
	Row,
	Col,
	Button,
	Input,
	Space,
	Divider,
	notification,
	Affix,
	Select,
	Tooltip,
	Tag,
	List,
	Checkbox,
	Radio,
} from 'antd';
import { XLottie } from '../Login/LoginStyle';
import { useNavigate } from 'react-router-dom';
import LoginAnimation from '../../json/Login.json';
// import ReloadingAnimation from '../../json/reloading.json';
import ReloadingAnimation from '../../json/decodex_updated05.json';
import Thanks from '../../json/thanks.json';
import {
	XContainer,
	XForm,
	XModal,
	XAlertBar,
	XButton,
	XSelect,
	XCardsCriteriaBg,
	XRoundButton,
	XModalTitle,
	XModalSubtitle,
	XList,
	XCircle,
	XLabel,
} from '../../styles/GlobalStyle';
import HeaderFile from '../../components/Header/HeaderFile';
import { XCardsCaseBg } from '../../styles/GlobalStyle';
import { XButtonImg } from '../Manage/ManageStyle';
import {
	XLottieMargin,
	Title,
	XPara1,
	XCardsGrdBG,
	XButtonAdd,
	XTitleHead,
	XDivider,
	XButtonLike,
	XButtonCloseBar,
	XButtonReload,
	XNotificationBar,
	XResults,
	XLottieOverlay,
	XButtonConfirm,
	XCheckboxGroup,
} from './AnalyseCoverageStyle';
import {
	PlusOutlined,
	EditOutlined,
	DeleteOutlined,
	ExclamationCircleFilled,
	CopyOutlined,
	MailOutlined,
	CloseOutlined,
	ReloadOutlined,
	SyncOutlined,
} from '@ant-design/icons';
// Actions
import {
	analyseCoverageApi,
	analyseCoverageReset,
} from '../../actions/analyseCoverageAction';
import {
	reviseCoverageApi,
	reviseCoverageReset,
} from '../../actions/reviseCoverageAction';
import {
	sendFeedbackApi,
	sendFeedbackReset,
} from '../../actions/sendFeedbackAction';
import {
	getStateDropdownApi,
	getStateDropdownReset,
} from '../../actions/getStateDropdownAction';
import Header from '../../components/Header/Header';
import { filterByLabel, handleSelectChange } from '../../utils/formFunctions';
import {
	getAnalysisReset,
	getAnalysisApi,
} from '../../actions/getAnalysisAction';
import { medicalCoverage } from '../../utils/constants';
import AnalysisLoader from '../../components/AnalysisLoader/AnalysisLoader';
const { TextArea } = Input;
const moment = require('moment');

const AnalyseCoverage = (props) => {
	const {
		analyseCoverage,
		analyseCoverageReset,
		analyseCoverageState,
		reviseCoverage,
		reviseCoverageReset,
		reviseCoverageState,
		sendFeedback,
		sendFeedbackReset,
		sendFeedbackState,
		getStateDropdownState,
		getStateDropdownReset,
		getStateDropdown,
		getAnalysis,
		getAnalysisState,
		getAnalysisReset,
	} = props;
	const [form] = XForm.useForm();
	const [newOptionForm] = XForm.useForm();
	const [summaryForm] = XForm.useForm();
	const [loading, setLoading] = useState(false);
	const [modalOpen, setModalOpen] = useState([false, '', '', '', '']);
	const [thanksModal, setThanksModal] = useState(false);
	const [summary, setSummary] = useState('');
	const [state, setState] = useState('');
	const [provider, setProvider] = useState('');
	const [modalTextArea, setModalTextArea] = useState('');
	const [coverage, setCoverage] = useState('Yes');
	const [feedbackId, setFeedbackId] = useState(null);
	const [pageContent, setPageContent] = useState({
		pastHistory: [],
		presentComplaint: [],
		recommendedProcedure: [],
		medicalCoverage: [],
		status: '',
		missing_points_for_coverage: [],
	});
	const [insuranceCompanies, setInsuranceCompanies] = useState([]);
	const [callGetAnalysisAPiBtn, setCallGetAnalysisAPiBtn] = useState(true);
	const [missingFieldsModal, setMissingFieldsModal] = useState(false);
	const [missingFields, setMissingFields] = useState([]);
	const [missingFieldInstructions, setMissingFieldInstructions] = useState(
		[],
	);
	const [missingFieldTitle, setMissingFieldTitle] = useState('');
	const [missingFieldsForm] = XForm.useForm();
	const [lastAnalysisData, setLastAnalysisData] = useState({});
	const [showAnalysisData, setShowAnalysisData] = useState(false);
	const [analysisCompleted, setAnalysisCompleted] = useState('');
	const [newOptionText, setNewOptionText] = useState('');
	const [newCheckBoxModal, setNewCheckBoxModal] = useState([false, null]);
	const [id, setId] = useState('');

	// Function to generate a random alphanumeric string of given length
	const generateRandomAlphaNumeric = (length) => {
		const characters =
			'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		let result = '';
		for (let i = 0; i < length; i++) {
			result += characters.charAt(
				Math.floor(Math.random() * characters.length),
			);
		}
		return result;
	};

	// Function to generate an ID with specified format
	const generateCustomId = () => {
		const randomPart = generateRandomAlphaNumeric(9); // First 9 characters alphanumeric
		const timestampPart = moment().format('YYDDMMHHMMSSMS'); // Last 15 characters formatted as YYDDMMHHMMSSMS
		const customId = randomPart + timestampPart;

		return customId;
	};
	const showDeleteConfirm = (title, id, content, item) => {
		XModal.confirm({
			title: `Delete ${title}?`,
			content: (
				<p className="modalPara1">
					Are you sure you want to delete - {content}?
				</p>
			),
			icon: <img className="me-2" src="/images/delete-icon.svg" alt="" />,
			okText: 'Yes, Confirm',
			okType: 'danger',
			cancelText: 'No, Cancel',
			className: 'confirmModal',
			onOk() {
				deletePageContent(item, id);
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	const updatePageContent = (title, id, content) => {
		if (id === null) {
			setPageContent({
				...pageContent,
				[title]: [...pageContent[[title]], content],
			});
		} else {
			const updatedPageContent = { ...pageContent };
			updatedPageContent[[title]][id] = content;
			setPageContent(updatedPageContent);
		}
		setModalOpen([false, '', ' ']);
		setModalTextArea('');
		form.resetFields();
		document.getElementById('notification').classList.remove('d-none');
	};

	const deletePageContent = (item, id) => {
		const updatedPageContent = { ...pageContent };
		updatedPageContent[[item]].splice(id, 1);
		setPageContent(updatedPageContent);
		setModalOpen([false, '', ' ']);
		setModalTextArea('');
		form.resetFields();
		document.getElementById('notification').classList.remove('d-none');
	};

	const handleAddOption = (index) => {
		setNewCheckBoxModal([true, index]);
		setNewOptionText('');
		newOptionForm.setFieldsValue({});
		newOptionForm.resetFields();
	};

	const handleNewCheckBoxModal = () => {
		// Create a new option object
		newOptionForm
			.validateFields()
			.then(() => {
				const newOption = {
					label: newOptionText,
					value: newOptionText,
				};

				if (
					newCheckBoxModal[1] !== null &&
					newCheckBoxModal[1] >= 0 &&
					newCheckBoxModal[1] < missingFields.length
				) {
					const updatedFields = [...missingFields];
					updatedFields[newCheckBoxModal[1]].options.push(newOption);
					setMissingFields(updatedFields);

					// Get the current form values
					const currentValues = missingFieldsForm.getFieldsValue();

					// Add the new option to the appropriate field and mark it as checked
					if (
						!currentValues[updatedFields[newCheckBoxModal[1]].name]
					) {
						currentValues[updatedFields[newCheckBoxModal[1]].name] =
							[];
					}
					currentValues[updatedFields[newCheckBoxModal[1]].name].push(
						newOptionText,
					);

					// Update the form with the new values
					missingFieldsForm.setFieldsValue(currentValues);
				}

				// Close the modal and reset the newOptionText state
				setNewCheckBoxModal([false, null]);
				setNewOptionText('');
				newOptionForm.setFieldsValue({});
				newOptionForm.resetFields();
			})
			.catch((info) => {
				console.log('Validate Failed:', info);
			});
	};

	// Function to handle form submission
	const handleShazamClick = () => {
		setLoading(true); // Assuming you have setLoading function declared somewhere
		const newId = generateCustomId();
		setId(newId); // Update state with generated ID

		// Assuming analyseCoverage function makes an API call
		analyseCoverage({
			clinicalSummary: summary,
			state: state,
			provider: provider,
			analysis_id: newId, // Use the newly generated ID here
		})
			.then(() => {
				setLoading(false);
			})
			.catch((error) => {
				setLoading(false);
				console.error('API Error:', error);
			});
	};

	useEffect(() => {
		if (showAnalysisData) {
			document.getElementById('getQust').classList.add('bottom-100');
			document.getElementById('getAns').classList.add('top-0');
			document.getElementById('getAns').classList.remove('top-100');
			document.getElementById('getAns')?.classList.remove('d-none');
		}
	}, [showAnalysisData]);

	useEffect(() => {
		if (analyseCoverageState.apiState === 'success') {
			setFeedbackId(analyseCoverageState.data.feedback_id);
			setLastAnalysisData(analyseCoverageState.data);
			setPageContent({
				...pageContent,
				originalQuery:
					analyseCoverageState.data['original_query'] ?? '',
				pastHistory: analyseCoverageState.data['Past History'] ?? [],
				presentComplaint:
					analyseCoverageState.data['Present Complaint'] ?? [],
				recommendedProcedure:
					analyseCoverageState.data['Suggested Treatment'] ?? [],
				medicalCoverage: [
					analyseCoverageState.data['Medicare Evaluation']
						? analyseCoverageState.data['Medicare Evaluation'][
								'Coverage'
						  ]
						: 'No',
					analyseCoverageState.data['Medicare Evaluation']
						? analyseCoverageState.data['Medicare Evaluation'][
								'Rationale'
						  ]
						: '',
					analyseCoverageState.data['Medicare Evaluation']
						? analyseCoverageState.data['Medicare Evaluation'][
								'reference'
						  ]
						: null,
				],
				missing_points_for_coverage:
					analyseCoverageState.data.missing_points_for_coverage ?? [],
			});
			setLoading(false);

			if (
				analyseCoverageState.data.hasOwnProperty('is_fields_missing') &&
				analyseCoverageState.data.is_fields_missing &&
				analyseCoverageState.data.hasOwnProperty('missing_fields')
			) {
				if (analyseCoverageState.data.missing_fields.length > 0) {
					setMissingFields(
						analyseCoverageState.data.missing_fields ?? [],
					);
					setMissingFieldInstructions(
						analyseCoverageState.data.missing_field_instructions ??
							[],
					);
					setMissingFieldTitle(
						analyseCoverageState.data.missing_field_title ?? '',
					);
					setMissingFieldsModal(true);
				}
			} else {
				setShowAnalysisData(true);
			}
		}

		if (analyseCoverageState.apiState === 'error') {
			setLoading(false);
			notification.error({
				message:
					'Hey, we are facing high traffic. Please check back in few minutes.',
			});
		}
		analyseCoverageReset();
	}, [analyseCoverageState]);

	useEffect(() => {
		if (getAnalysisState.apiState === 'success') {
			setFeedbackId(getAnalysisState.data.coverage_feedback_response._id);
			setPageContent({
				...pageContent,
				originalQuery: summary ?? '',
				pastHistory:
					getAnalysisState.data.coverage_feedback_response[
						'Past History'
					] ?? [],
				presentComplaint:
					getAnalysisState.data.coverage_feedback_response[
						'Present Complaint'
					] ?? [],
				recommendedProcedure:
					getAnalysisState.data.coverage_feedback_response[
						'Suggested Treatment'
					] ?? [],
				medicalCoverage: [
					getAnalysisState.data.coverage_feedback_response[
						'Medicare Evaluation'
					]
						? getAnalysisState.data.coverage_feedback_response[
								'Medicare Evaluation'
						  ]['Coverage']
						: 'No',
					getAnalysisState.data.coverage_feedback_response[
						'Medicare Evaluation'
					]
						? getAnalysisState.data.coverage_feedback_response[
								'Medicare Evaluation'
						  ]['Rationale']
						: '',
					getAnalysisState.data.coverage_feedback_response[
						'Medicare Evaluation'
					]
						? getAnalysisState.data.coverage_feedback_response[
								'Medicare Evaluation'
						  ]['reference']
						: null,
				],
				missing_points_for_coverage:
					getAnalysisState.data.coverage_feedback_response
						.missing_points_for_coverage ?? [],
				status: getAnalysisState.data.coverage_feedback_response.status,
			});
			setLoading(false);
			document.getElementById('getQust').classList.add('bottom-100');
			document.getElementById('getAns').classList.add('top-0');
			document.getElementById('getAns').classList.remove('top-100');
			document.getElementById('getAns')?.classList.remove('d-none');
		}

		if (analyseCoverageState.apiState === 'error') {
			setLoading(false);
			notification.error({
				message:
					'Hey, we are facing high traffic. Please check back in few minutes.',
			});
		}
		getAnalysisReset();
	}, [getAnalysisState]);

	useEffect(() => {
		if (reviseCoverageState.apiState === 'success') {
			setFeedbackId(reviseCoverageState.data.feedback_id);
			setLastAnalysisData(reviseCoverageState.data);
			setPageContent({
				...pageContent,
				originalQuery: reviseCoverageState.data['original_query'] ?? '',
				pastHistory: reviseCoverageState.data['Past History'] ?? [],
				presentComplaint:
					reviseCoverageState.data['Present Complaint'] ?? [],
				recommendedProcedure:
					reviseCoverageState.data['Suggested Treatment'] ?? [],
				medicalCoverage: [
					reviseCoverageState.data['Medicare Evaluation']
						? reviseCoverageState.data['Medicare Evaluation'][
								'Coverage'
						  ]
						: 'No',
					reviseCoverageState.data['Medicare Evaluation']
						? reviseCoverageState.data['Medicare Evaluation'][
								'Rationale'
						  ]
						: '',
					reviseCoverageState.data['Medicare Evaluation']
						? reviseCoverageState.data['Medicare Evaluation'][
								'reference'
						  ]
						: null,
				],
				missing_points_for_coverage:
					reviseCoverageState.data.missing_points_for_coverage ?? [],
				status: reviseCoverageState.data.status,
			});
			setLoading(false);
			if (
				reviseCoverageState.data.hasOwnProperty('is_fields_missing') &&
				reviseCoverageState.data.is_fields_missing &&
				reviseCoverageState.data.hasOwnProperty('missing_fields')
			) {
				if (reviseCoverageState.data.missing_fields.length > 0) {
					setMissingFields(
						reviseCoverageState.data.missing_fields ?? [],
					);
					setMissingFieldInstructions(
						reviseCoverageState.data.missing_field_instructions ??
							[],
					);
					setMissingFieldTitle(
						reviseCoverageState.data.missing_field_title ?? '',
					);
					setMissingFieldsModal(true);
				}
			} else {
				setShowAnalysisData(true);
			}
		}
		if (reviseCoverageState.apiState === 'error') {
			setLoading(false);
			notification.error({
				message:
					'Hey, we are facing high traffic. Please check back in few minutes.',
			});
			document.getElementById('notification')?.classList.remove('d-none');
			document.getElementById('getAns')?.classList.remove('d-none');
		}
		reviseCoverageReset();
	}, [reviseCoverageState]);

	useEffect(() => {
		
		if (sendFeedbackState.apiState === 'success') {
			setModalOpen([false, '', '']);
			setThanksModal(true);
			sendFeedbackReset();
			if (callGetAnalysisAPiBtn) {
				getAnalysis({ feedback_id: feedbackId });
				setCallGetAnalysisAPiBtn(true);
			}
		} else if (sendFeedbackState.apiState === 'error') {
			// notification.error({ message: sendFeedbackState.message });
			setModalOpen([false, '', '']);
			sendFeedbackReset();
			setCallGetAnalysisAPiBtn(true);
		}
	}, [sendFeedbackState]);

	useEffect(() => {
		document.getElementById('getQust').classList.remove('bottom-100');
		document.getElementById('getAns').classList.remove('top-0');
		document.getElementById('getAns').classList.add('top-100');
		document.getElementById('getAns')?.classList.add('d-none');
		getStateDropdown();
	}, []);

	return (
		<>
			<XContainer>
				<div className="marginTopMobile "></div>
				<Affix offsetTop={10} id="notification" className="d-none">
					<div className="marginBottomMobile"></div>
					<div
						className="mb-4"
						style={{
							backgroundColor: 'white',
							borderRadius: '20px',
							zIndex: '1110',
						}}
					>
						<XNotificationBar>
							Clinical Summary has been changed !! Click to{' '}
							<XButtonReload
								type="button"
								className="refreshBtn"
								onClick={() => {
									setLoading(true);
									document
										.getElementById('notification')
										.classList.add('d-none');
									reviseCoverage({
										feedbackId: feedbackId,
										clinicalHistory:
											pageContent.pastHistory,
										presentComplaint:
											pageContent.presentComplaint,
										recommendedProcedure:
											pageContent.recommendedProcedure,
										last_analysis_data: lastAnalysisData,
										state: state,
										provider: provider,
										analysis_id: id,
									});
								}}
							>
								Reanalyse it !!
							</XButtonReload>
							<XButtonCloseBar type="button" className="closeBar">
								<CloseOutlined
									onClick={() => {
										document
											.getElementById('notification')
											.classList.add('d-none');
									}}
									style={{ fontSize: '20px' }}
								/>
							</XButtonCloseBar>
						</XNotificationBar>
					</div>
					<div className="marginNone"></div>
				</Affix>
			</XContainer>
			<>
				<Col lg={24} xl={24} xs={0} md={0}>
					<Header headerTag="Analyse Coverage" />
				</Col>
				<Col lg={0} xl={0} xs={24} md={24}>
					<HeaderFile activeNav="analyseCoverage" />
				</Col>
			</>

			<XContainer className="slideBox">
				{/* Old Lottie */}
				{/* {loading && (
					<XLottieOverlay>
						<XLottie
							animationData={ReloadingAnimation}
							loop={true}
						/>
					</XLottieOverlay>
				)} */}

				{loading && (
					<AnalysisLoader
						analysisId={id}
						analysisCompleted={analysisCompleted}
						setAnalysisCompleted={setAnalysisCompleted}
						setLoading={setLoading}
						loading={loading}
					/>
				)}

				<div id="getQust" className="">
					<Row>
						<Col
							xs={{ span: 24, offset: 0 }}
							lg={{ span: 6, offset: 9 }}
							className="text-center"
						>
							<XLottieMargin>
								<XLottie
									animationData={LoginAnimation}
									loop={true}
								/>
							</XLottieMargin>
						</Col>
					</Row>
					<XForm
						form={summaryForm}
						name="loginForm"
						layout="vertical"
						autoComplete="off"
						onFinish={handleShazamClick}
					>
						<Row gutter={[30, 15]}>
							<Col
								xs={{ span: 24, offset: 0 }}
								lg={{ span: 8, offset: 8 }}
							>
								<Title>DeCodeX</Title>
							</Col>
							<Col
								xs={{ span: 24, offset: 0 }}
								lg={{ span: 20, offset: 2 }}
								className="my-4"
							>
								<XPara1 className="text-center">
									DeCodeX utilizes comprehensive standardized
									clinical notes to generate recommended
									best-fit diagnostic ICD-10, procedural
									CPT/HCPCS or drug NDC codes. It also
									provides insights into Medicare coverage.
								</XPara1>
							</Col>
							<Col xs={{ span: 24 }}>
								<XForm.Item
									name="summary"
									rules={[
										{
											required: true,
											message: 'Please input summary!',
										},
									]}
									className="mb-1"
								>
									<TextArea
										name="summery"
										showCount
										style={{
											marginBottom: 24,
										}}
										autoSize={{
											minRows: 5,
										}}
										className="textArea mt-2"
										placeholder="Enter Clinical Summary"
										onChange={(e) =>
											setSummary(e.target.value)
										}
									/>
								</XForm.Item>
							</Col>
							<Col xs={{ span: 24 }} lg={{ span: 12 }}>
								<XForm.Item
									name="state"
									label="State"
									rules={[
										{
											required: true,
											message: 'Please select State',
										},
									]}
								>
									<XSelect
										size="large"
										placeholder="Select State"
										name="state"
										showSearch={true}
										// onFocus={() => {
										// 	 getStateDropdown();
										// }}
										onSearch={(search) => {
											console.log('on search called')
											if (search.length >= 3) {
												getStateDropdown({
													search,
												});
											} else if (search.length === 0) {
												getStateDropdown();
											}
										}}
										filterOption={filterByLabel}
										options={
											getStateDropdownState.apiState ===
												'success' &&
											getStateDropdownState.data
												.all_states?.length > 0 &&
											getStateDropdownState.data.all_states.map(
												(itm) => {
													return {
														label: itm.state,
														value: itm.state,
														providers:
															itm.providers,
													};
												},
											)
										}
										onSelect={(value, options) => {
											setState(value);
											summaryForm.setFieldValue(
												'insurance_company',
												null,
											);
											setInsuranceCompanies(
												options.providers,
											);
										}}
									/>
								</XForm.Item>
							</Col>
							<Col xs={{ span: 24 }} lg={{ span: 12 }}>
								<XForm.Item
									name="insurance_company"
									label="Insurance Company"
								>
									<XSelect
										size="large"
										placeholder="Select Insurance Company"
										name="insurance_company"
										showSearch={true}
										filterOption={filterByLabel}
										options={
											insuranceCompanies.length > 0 &&
											insuranceCompanies.map((itm) => {
												return {
													label: itm.name,
													value: itm.name,
												};
											})
										}
										onSelect={(value) => {
											setProvider(value);
										}}
									/>
								</XForm.Item>
							</Col>
							<Col xs={24} className="text-center mt-3">
								<Button htmlType="submit" type="primary">
									Shazaam !!
								</Button>
							</Col>
						</Row>
					</XForm>
				</div>
				<div id="getAns" className="slideUp top-100 mt-4 d-none">
					<XCardsGrdBG>
						<Row className="align-items-center">
							<Col xs={20} lg={22}>
								<XPara1>{pageContent.originalQuery}</XPara1>
							</Col>
							<Col xs={4} lg={2} className="text-end">
								<Button
									shape="circle"
									type="button"
									onClick={() => {
										document
											.getElementById('getAns')
											.classList.remove('top-0');
										document
											.getElementById('getAns')
											.classList.add('top-100');

										document
											.getElementById('notification')
											.classList.add('d-none');
										document
											.getElementById('getAns')
											.classList.add('d-none');
										document
											.getElementById('getQust')
											.classList.add('slideDown');

										document
											.getElementById('getQust')
											.classList.remove('bottom-100');
										document
											.getElementById('getQust')
											.classList.add('bottom-0');
									}}
								>
									<img
										className="img-fluid"
										src="/images/reload-icon.svg"
										alt=""
									/>
								</Button>
							</Col>
						</Row>
					</XCardsGrdBG>
					<XCardsCaseBg className="mt-4">
						<Row gutter={(15, 15)} className="align-items-center">
							<Col xs={20} lg={20}>
								<Space align="center">
									<img
										className="iconResp"
										src="/images/past-icon.svg"
										alt=""
									/>
									<XTitleHead>
										<span className="color1 d-none d-sm-block">
											Past Clinical History
										</span>

										<span className="color1 d-block d-sm-none text-start">
											Past Clinical <br /> History
										</span>
									</XTitleHead>
								</Space>
							</Col>
							<Col xs={4} lg={4} className="text-end">
								<XButtonAdd
									shape="circle"
									icon={<PlusOutlined />}
									onClick={() => {
										form.setFieldsValue({
											modal_text_area: '',
										});
										setModalTextArea('');
										setModalOpen([
											true,
											'Add Past Clinical History',
											'',
											null,
											'pastHistory',
										]);
									}}
								></XButtonAdd>
							</Col>
						</Row>
						{pageContent.pastHistory.length > 0 &&
							pageContent.pastHistory.map((row, index) => {
								const splitPastHistory =
									row.split('- ICD-10 Code:');
								return (
									<Row
										gutter={(15, 15)}
										className="mt-3 align-items-center"
									>
										<Col xs={24} lg={20}>
											{splitPastHistory.length > 1 ? (
												<XPara1
													style={{
														fontStyle: 'italic',
													}}
												>
													{splitPastHistory[0]}
													<span
														style={{
															padding: '3px',
															background:
																'var(--Linear, linear-gradient(90deg, #d4f8eb 0%, #fff3fc 48%, #e4d3fc 100%))',
														}}
													>
														ICD-10:
														{splitPastHistory[1]}
													</span>
												</XPara1>
											) : (
												<XPara1
													style={{
														fontStyle: 'italic',
													}}
												>
													{row}
												</XPara1>
											)}
										</Col>
										<Col
											xs={24}
											lg={4}
											className="text-end"
										>
											<XButtonImg
												type="text"
												onClick={() => {
													form.setFieldsValue({
														modal_text_area: row,
													});
													setModalTextArea(row);
													setModalOpen([
														true,
														`Edit Past Clinical History`,
														row,
														index,
														'pastHistory',
													]);
												}}
											>
												<EditOutlined />
											</XButtonImg>
											<XButtonImg
												type="text"
												onClick={() => {
													showDeleteConfirm(
														'Past Clinical History',
														index,
														row,
														'pastHistory',
													);
												}}
											>
												<DeleteOutlined />
											</XButtonImg>
										</Col>
									</Row>
								);
							})}
						<XDivider dashed="true" />
						<Row gutter={(15, 15)} className="align-items-center">
							<Col xs={20} lg={20}>
								<Space align="center">
									<img
										className="iconResp"
										src="/images/present-icon.svg"
										alt=""
									/>
									<XTitleHead>
										<span className="color2 d-none d-sm-block">
											Present Complaint
										</span>

										<span className="color2 d-block d-sm-none text-start">
											Present <br /> Complaint
										</span>
									</XTitleHead>
								</Space>
							</Col>
							<Col xs={4} lg={4} className="text-end">
								<XButtonAdd
									shape="circle"
									icon={<PlusOutlined />}
									onClick={() => {
										form.setFieldsValue({
											modal_text_area: '',
										});
										setModalTextArea('');
										setModalOpen([
											true,
											'Add Present Complaint',
											'',
											null,
											'presentComplaint',
										]);
									}}
								></XButtonAdd>
							</Col>
						</Row>
						{pageContent.presentComplaint.length > 0 &&
							pageContent.presentComplaint.map((row, index) => {
								const splitPresentComplaint =
									row.split('- ICD-10 Code:');

								return (
									<Row
										gutter={(15, 15)}
										className="mt-3 align-items-center"
									>
										<Col xs={24} lg={20}>
											{splitPresentComplaint.length >
											1 ? (
												<XPara1
													style={{
														fontStyle: 'italic',
													}}
												>
													{splitPresentComplaint[0]}
													<span
														style={{
															padding: '3px',
															background:
																'var(--Linear, linear-gradient(90deg, #d4f8eb 0%, #fff3fc 48%, #e4d3fc 100%))',
														}}
													>
														ICD-10:
														{
															splitPresentComplaint[1]
														}
													</span>
												</XPara1>
											) : (
												<XPara1
													style={{
														fontStyle: 'italic',
													}}
												>
													{row}
												</XPara1>
											)}{' '}
										</Col>
										<Col
											xs={24}
											lg={4}
											className="text-end"
										>
											<XButtonImg
												type="text"
												onClick={() => {
													form.setFieldsValue({
														modal_text_area: row,
													});
													setModalTextArea(row);
													setModalOpen([
														true,
														`Edit Present Complaint`,
														row,
														index,
														'presentComplaint',
													]);
												}}
											>
												<EditOutlined />
											</XButtonImg>
											<XButtonImg
												type="text"
												onClick={() => {
													showDeleteConfirm(
														'Present Complaint',
														index,
														row,
														'presentComplaint',
													);
												}}
											>
												<DeleteOutlined />
											</XButtonImg>
										</Col>
									</Row>
								);
							})}
						<XDivider dashed="true" />
						<Row gutter={(15, 15)} className="align-items-center">
							<Col xs={20} lg={20}>
								<Space align="center">
									<img
										className="iconResp"
										src="/images/procedure-icon.svg"
										alt=""
									/>
									<XTitleHead>
										<span className="color3 d-none d-sm-block">
											Recommended Procedure
										</span>

										<span className="color3 d-block d-sm-none text-start">
											Recommended <br /> Procedure
										</span>
									</XTitleHead>
								</Space>
							</Col>
							<Col xs={4} lg={4} className="text-end">
								<XButtonAdd
									shape="circle"
									icon={<PlusOutlined />}
									onClick={() => {
										form.setFieldsValue({
											modal_text_area: '',
										});
										setModalTextArea('');
										setModalOpen([
											true,
											'Add Recommended Procedure',
											'',
											null,
											'recommendedProcedure',
										]);
									}}
								></XButtonAdd>
							</Col>
						</Row>
						{pageContent.recommendedProcedure.length > 0 &&
							pageContent.recommendedProcedure.map(
								(row, index) => {
									const splitRecommendedProcedure =
										row.split('- CPT Code:');
									return (
										<Row
											gutter={(15, 15)}
											className="mt-3 align-items-center"
										>
											<Col xs={24} lg={20}>
												{splitRecommendedProcedure.length >
												1 ? (
													<XPara1
														style={{
															fontStyle: 'italic',
														}}
													>
														{
															splitRecommendedProcedure[0]
														}
														<span
															style={{
																padding: '3px',
																background:
																	'var(--Linear, linear-gradient(90deg, #d4f8eb 0%, #fff3fc 48%, #e4d3fc 100%))',
															}}
														>
															CPT:
															{
																splitRecommendedProcedure[1]
															}
														</span>
													</XPara1>
												) : (
													<XPara1
														style={{
															fontStyle: 'italic',
														}}
													>
														{row}
													</XPara1>
												)}{' '}
											</Col>
											<Col
												xs={24}
												lg={4}
												className="text-end"
											>
												<XButtonImg
													type="text"
													onClick={() => {
														form.setFieldsValue({
															modal_text_area:
																row,
														});
														setModalTextArea(row);
														setModalOpen([
															true,
															`Edit Recommended Procedure`,
															row,
															index,
															'recommendedProcedure',
														]);
													}}
												>
													<EditOutlined />
												</XButtonImg>
												<XButtonImg
													type="text"
													onClick={() => {
														showDeleteConfirm(
															'Recommended Procedure',
															index,
															row,
															'recommendedProcedure',
														);
													}}
												>
													<DeleteOutlined />
												</XButtonImg>
											</Col>
										</Row>
									);
								},
							)}
						<XDivider dashed="true" />
						<Row gutter={(15, 15)} className="align-items-center">
							<Col xs={24}>
								<Space align="center">
									<img
										className="iconResp"
										src={
											pageContent.medicalCoverage[0] ===
											'Yes'
												? '/images/coverage-icon.svg'
												: '/images/cross-icon.svg'
										}
										alt=""
									/>
									<XTitleHead>
										<span
											className={
												pageContent
													.medicalCoverage[0] ===
												'Yes'
													? 'color4'
													: 'color5'
											}
										>
											Medicare Coverage
										</span>
									</XTitleHead>
								</Space>
							</Col>
						</Row>
						<Row
							gutter={(15, 15)}
							className="mt-3 align-items-center"
						>
							<Col xs={24}>
								<XPara1>
									{pageContent.medicalCoverage[1]}
									{pageContent.medicalCoverage[2] &&
									Object.keys(pageContent.medicalCoverage[2])
										.length > 0 ? (
										<>
											<br />
											<br />
											<i>
												Reference:{' '}
												<a
													href={
														pageContent
															.medicalCoverage[2][
															'target'
														]
													}
													rel="noreferrer"
													target="_blank"
												>
													{
														pageContent
															.medicalCoverage[2][
															'display_text'
														]
													}
												</a>
											</i>
										</>
									) : (
										''
									)}
								</XPara1>
							</Col>
						</Row>
					</XCardsCaseBg>
					{pageContent.missing_points_for_coverage &&
						pageContent.missing_points_for_coverage.length > 0 && (
							<XCardsCriteriaBg className="mt-4">
								<div className="criteria_list">
									<Col xs={24}>
										<Space align="center">
											<img
												src={'/images/criteria.svg'}
												alt=""
											/>
											<XTitleHead>
												<span className="criteria_list_title">
													Missing Inclusion Criteria
												</span>
												&nbsp;
												<Tooltip title="This feature is experimental and may contain errors as it evolves. Please exercise discretion, especially when reviewing critical information.">
													<Tag
														style={{
															border: '1px solid #9B68BF',
															color: '#9B68BF',
															fontSize: '11px',
														}}
													>
														Alpha
													</Tag>
												</Tooltip>
											</XTitleHead>
										</Space>
									</Col>
								</div>

								<Row
									gutter={(15, 15)}
									className="mt-3 align-items-center criteria_sub_list"
								>
									<Col xs={24}>
										<ul className="listCircule">
											{pageContent.missing_points_for_coverage?.map(
												(row, index) => {
													return <li>{row}</li>;
												},
											)}
										</ul>
									</Col>
								</Row>
							</XCardsCriteriaBg>
						)}

					<Row className=" text-end">
						<Col xs={24} className="mt-4">
							<Space>
								{/* <Divider type="vertical" /> */}
								<XButtonLike
									type="primary"
									shape="round"
									icon={
										<img
											className="rotateButton"
											src="/images/like-icon.svg"
											alt=""
										/>
									}
									size="large"
									onClick={() => {
										form.setFieldsValue({
											modal_text_area:
												pageContent.medicalCoverage
													? pageContent
															.medicalCoverage[1]
													: '',
											medicare_flag:
												pageContent.medicalCoverage
													? pageContent
															.medicalCoverage[0]
													: '',
										});
										setModalTextArea(
											pageContent.medicalCoverage[1],
										);
										setModalOpen([
											true,
											`Edit Medicare Coverage`,
											pageContent.medicalCoverage[1],
											1,
											'feedbackSummary',
										]);
										setCallGetAnalysisAPiBtn(true);
									}}
								>
									Refine
								</XButtonLike>
								<XButtonLike
									onClick={() => {
										sendFeedback({
											feedback_id: feedbackId,
											feedback: 'positive',
											status: 'approved',
											response:
												pageContent.medicalCoverage,
										});

										setCallGetAnalysisAPiBtn(false);
									}}
									type="primary"
									shape="round"
									icon={
										<img
											src="/images/like-icon.svg"
											alt=""
										/>
									}
									size="large"
								>
									Looks Good
								</XButtonLike>
							</Space>
						</Col>
					</Row>
				</div>
			</XContainer>

			<XModal
				title={
					<>
						<EditOutlined />
						<span className="ms-2">{modalOpen[1]}</span>
					</>
				}
				centered
				open={modalOpen[0]}
				onOk={() => {
					form.validateFields()
						.then((res) => {
							if (modalOpen[4] === 'feedbackSummary') {
								sendFeedback({
									feedback_id: feedbackId,
									feedback: 'negative',
									response: [coverage, modalTextArea],
								});
							} else {
								updatePageContent(
									modalOpen[4],
									modalOpen[3],
									modalTextArea,
								);
							}
						})
						.catch((err) => {
							console.log('error', err);
						});
				}}
				onCancel={() => {
					form.resetFields();
					setModalTextArea('');
					setModalOpen([false, '', ' ']);
				}}
				className="modalButton"
				okText="Submit"
			>
				<XForm
					form={form}
					name="groupForm"
					layout="vertical"
					autoComplete="off"
				>
					<Row gutter={(15, 0)} className="mt-4">
						<Col xs={24}>
							{modalOpen[4] === 'feedbackSummary' && (
								<XForm.Item
									name="medicare_flag"
									label="Coverage"
									rules={[
										{
											required: true,
											message: 'Field is required',
										},
									]}
								>
									<Select
										showSearch
										placeholder="Select Coverage"
										optionFilterProp="children"
										onChange={(e) => {
											setCoverage(e);
										}}
										options={medicalCoverage}
									/>
								</XForm.Item>
							)}
							<XForm.Item
								name="modal_text_area"
								rules={[
									{
										required: true,
										message: 'Field is required',
									},
								]}
							>
								<TextArea
									name="modal_text_area"
									showCount
									style={{
										height: 120,
										marginBottom: 24,
									}}
									className="textArea mt-2"
									placeholder="Enter"
									onChange={(e) =>
										setModalTextArea(e.target.value)
									}
								>
									{/* modalOpen[2] */}
								</TextArea>
							</XForm.Item>
						</Col>
					</Row>
				</XForm>
			</XModal>

			<XModal
				centered
				open={thanksModal}
				onOk={() => setThanksModal(false)}
				onCancel={() => {
					setThanksModal(false);
				}}
				className="modalButton"
				okText="Submit"
				footer={null}
			>
				<Row gutter={(15, 0)} className="mt-4">
					<Col xs={24} lg={{ span: 12, offset: 6 }}>
						<XLottieMargin>
							<XLottie animationData={Thanks} loop={true} />
						</XLottieMargin>

						<XPara1 className="mt-5 mb-4">
							Thanks for making us better
						</XPara1>
					</Col>
				</Row>
			</XModal>

			{/* Modal for Missing Fields */}
			<XModal
				title={
					<>
						{/* <EditOutlined /> */}
						<XModalTitle className="ms-2">
							Additional Information Required
						</XModalTitle>
						{missingFieldTitle && (
							<XModalSubtitle className="ms-2 mt-2">
								{missingFieldTitle}
							</XModalSubtitle>
						)}
						<XDivider dashed className="mt-3" />
					</>
				}
				centered
				open={missingFieldsModal}
				onOk={() => {
					missingFieldsForm
						.validateFields()
						.then((values) => {
							setLoading(true);
							reviseCoverageReset();
							reviseCoverage({
								feedbackId: feedbackId,
								clinicalHistory: pageContent.pastHistory,
								presentComplaint: pageContent.presentComplaint,
								recommendedProcedure:
									pageContent.recommendedProcedure,
								medicalCoverage: pageContent.medicalCoverage,
								state: state,
								provider: provider,
								missing_fields: values,
								last_analysis_data: lastAnalysisData,
								analysis_id: id,
							});

							missingFieldsForm.resetFields();
							setMissingFields([]);
							setMissingFieldInstructions([]);
							setMissingFieldsModal(false);
						})
						.catch((error) => {
							return;
						});
				}}
				onCancel={() => {
					missingFieldsForm.resetFields();
					setMissingFields([]);
					setMissingFieldInstructions([]);
					setMissingFieldsModal(false);
				}}
				className="modalButton"
				width={900}
				okText="Submit"
				style={{
					padding: '40px',
					borderRadius: '5px !important',
					'ant-modal-content': {
						padding: '30px !important',
						borderRadius: '5px !important',
					},
				}}
			>
				<XForm
					form={missingFieldsForm}
					name="groupForm"
					layout="vertical"
					autoComplete="off"
				>
					<Row gutter={(15, 0)} className="mt-4">
						{missingFieldInstructions.length > 0 && (
							<Col xs={24}>
								<XList
									size="small"
									header={<div>Instructions</div>}
									bordered
									dataSource={missingFieldInstructions}
									renderItem={(item, index) => (
										<List.Item>
											<XCircle>{index + 1}</XCircle>{' '}
											{item}
										</List.Item>
									)}
								/>
								<br />
							</Col>
						)}

						{missingFields.map((item, index) => {
							if (item.type == 'textarea') {
								return (
									<Col xs={24}>
										<XForm.Item
											label={
												<XLabel>{item.label}</XLabel>
											}
											name={item.name}
											rules={[
												item.hasOwnProperty(
													'required',
												) &&
													item.required && {
														required: true,
														message:
															item.required_error_message ??
															'Field is required',
													},
												item.hasOwnProperty('regex') &&
													item.regex && {
														validator: (
															rule,
															value,
														) => {
															let regex =
																new RegExp(
																	item.regex.slice(
																		1,
																		-1,
																	),
																);
															if (
																!regex.test(
																	value,
																)
															)
																return Promise.reject(
																	new Error(
																		item.regex_error_message ??
																			'Invalid Data',
																	),
																);
															return Promise.resolve();
														},
													},
											]}
										>
											<TextArea
												rows={3}
												name={item.name}
												showCount
												className="textArea"
												placeholder="Enter"
											></TextArea>
										</XForm.Item>
									</Col>
								);
							} else if (item.type == 'input') {
								return (
									<Col xs={24}>
										<XForm.Item
											label={
												<XLabel>{item.label}</XLabel>
											}
											name={item.name}
											rules={[
												item.hasOwnProperty(
													'required',
												) &&
													item.required && {
														required: true,
														message:
															item.required_error_message ??
															'Field is required',
													},
												item.hasOwnProperty('regex') &&
													item.regex && {
														validator: (
															rule,
															value,
														) => {
															let regex =
																new RegExp(
																	item.regex.slice(
																		1,
																		-1,
																	),
																);
															if (
																!regex.test(
																	value,
																)
															)
																return Promise.reject(
																	new Error(
																		item.regex_error_message ??
																			'Invalid Data',
																	),
																);
															return Promise.resolve();
														},
													},
											]}
										>
											<Input
												size="large"
												name={item.name}
												placeholder="Enter"
											></Input>
										</XForm.Item>
									</Col>
								);
							} else if (item.type == 'checkbox') {
								return (
									<>
										<Col xs={24} key={index}>
											<XForm.Item
												name={item.name}
												rules={[
													item.required && {
														required: true,
														message:
															item.required_error_message ??
															'Field is required',
													},
												]}
											>
												<XCheckboxGroup
													name={item.name}
													style={{
														display: 'flex',
														flexDirection: 'column',
													}}
												>
													<XList
														className="normal-header"
														size="small"
														bordered
														header={
															<div
																style={{
																	display:
																		'flex',
																	justifyContent:
																		'space-between',
																	alignItems:
																		'center',
																}}
															>
																<XLabel>
																	{item.label}
																</XLabel>
																<XRoundButton
																	shape="circle"
																	icon={
																		<PlusOutlined />
																	}
																	onClick={() =>
																		handleAddOption(
																			index,
																		)
																	}
																/>
															</div>
														}
														dataSource={
															item.options
														}
														renderItem={(
															option,
															idx,
														) => (
															<List.Item>
																<Checkbox
																	value={
																		option.value
																	}
																>
																	{
																		option.label
																	}
																</Checkbox>
															</List.Item>
														)}
													/>
												</XCheckboxGroup>
											</XForm.Item>
										</Col>
									</>
								);
							} else if (item.type == 'radio') {
								return (
									<Col xs={24} key={index}>
										<XForm.Item
											name={item.name}
											rules={[
												item.required && {
													required: true,
													message:
														'Field is required',
												},
											]}
										>
											<Radio.Group
												style={{
													display: 'flex',
													flexDirection: 'column',
												}}
											>
												<XList
													className="normal-header"
													size="small"
													bordered
													header={
														<div
															style={{
																display: 'flex',
																justifyContent:
																	'space-between',
																alignItems:
																	'center',
															}}
														>
															<XLabel>
																{item.label}
															</XLabel>
														</div>
													}
													dataSource={item.options}
													renderItem={(
														option,
														idx,
													) => (
														<List.Item>
															<Radio
																value={
																	option.value
																}
															>
																{option.label}
															</Radio>
														</List.Item>
													)}
												/>
											</Radio.Group>
										</XForm.Item>
									</Col>
								);
							}
						})}
					</Row>
				</XForm>
			</XModal>

			{/* Modal for adding new option */}
			<XModal
				title="Add New Option"
				open={newCheckBoxModal[0]}
				onOk={handleNewCheckBoxModal}
				okText="Submit"
				onCancel={() => {
					setNewCheckBoxModal([false, null]);
					setNewOptionText('');
					newOptionForm.setFieldsValue({});
					newOptionForm.resetFields();
				}}
			>
				<XForm
					form={newOptionForm}
					name="groupForm"
					layout="vertical"
					autoComplete="off"
				>
					<XForm.Item
						name={'new-option'}
						rules={[
							{
								required: true,
								message: 'Please input option',
							},
						]}
					>
						<Input
							size="large"
							className="mt-3 mb-3"
							value={newOptionText}
							onChange={(e) => setNewOptionText(e.target.value)}
							placeholder="Enter new option text"
						/>
					</XForm.Item>
				</XForm>
			</XModal>
		</>
	);
};

const mapStateToProps = (state) => ({
	analyseCoverageState: state.analyseCoverage,
	reviseCoverageState: state.reviseCoverage,
	sendFeedbackState: state.sendFeedback,
	getStateDropdownState: state.getStateDropdown,
	getAnalysisState: state.getAnalysis,
});

const mapDispatchToProps = (dispatch) => ({
	analyseCoverage: (params) => dispatch(analyseCoverageApi(params)),
	analyseCoverageReset: () => dispatch(analyseCoverageReset()),
	reviseCoverage: (params) => dispatch(reviseCoverageApi(params)),
	reviseCoverageReset: () => dispatch(reviseCoverageReset()),
	sendFeedback: (params) => dispatch(sendFeedbackApi(params)),
	sendFeedbackReset: () => dispatch(sendFeedbackReset()),
	getStateDropdown: () => dispatch(getStateDropdownApi()),
	getStateDropdownReset: () => dispatch(getStateDropdownReset()),
	getAnalysis: (params) => dispatch(getAnalysisApi(params)),
	getAnalysisReset: () => dispatch(getAnalysisReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AnalyseCoverage);
