import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	Timeline,
	Tag,
	Row,
	Col,
	Button,
	Tooltip,
	Card,
	Space,
	Image,
	Modal,
	Divider,
} from 'antd';
import './index.css';
import {
	XFilled,
	EditOutlined,
	ClockCircleOutlined,
	FileOutlined,
	CheckCircleOutlined,
	FileTextOutlined,
} from '@ant-design/icons';
import { checkModulePermissions, toTitleCase } from '../../utils/helper';
import config from '../../config';
import { Document, Page, pdfjs } from 'react-pdf'; // Import necessary components from react-pdf
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { XButtonRounded } from '../../pages/AppealLetter/AppealLetterViewStyle';
import { XButtonLike } from '../../pages/AnalyseCoverage/AnalyseCoverageStyle';

// Dynamic Timeline
const CustomTimeline = ({
	status,
	setUpdateModalOpen,
	setRevenueCycleStatus,
	timelineData,
	revenueCycleFormData,
	setRevenueCycleFormData,
	id,
	togglePrepareLetter,
	setTogglePrepareLetter,
	setUpdatedFileList,
	setFileList,
	setSelectedOption,
	medicareCoverage,
	case_status,
	setFlag,
	setRevenueCycleStage,
	revenueCycleStage,
}) => {
	const [documentText, setDocumentText] = useState(null);
	const [statusName, setStatusName] = useState(null);
	const [pdfPreviewOpen, setPdfPreviewOpen] = useState(false);
	const [pdfWidth, setPdfWidth] = useState(700); // Initial width of the modal
	const [previewImage, setPreviewImage] = useState('');
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);

	const navigate = useNavigate();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const showModal = () => {
		setIsModalOpen(true);
	};
	const handleOk = () => {
		setIsModalOpen(false);
	};
	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const handleModalPreviewRemarks = (text, status) => {
		setDocumentText(text);
		setStatusName(status);
		showModal();
	};
	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages);
	};

	const onDocumentLoadError = (error) => {
		console.error('Error loading document:', error);
		// Handle error
	};

	// Function to update the width of the modal based on the width of the document
	const updateModalWidth = (width) => {
		setPdfWidth(width + 40);
	};

	const handlePreview = async (file) => {
		setPdfPreviewOpen(true);
		console.log(file, 'pdf');
		setPreviewImage(file);
	};

	const getTagColor = (filingStatus) => {
		switch (filingStatus) {
			case 'In Process':
				return '#1f9254';
			case 'At Risk':
				return '#e5aa11';
			case 'Untimely':
				return '#e53711';
			case 'Escalation':
				return 'orange';
			default:
				return 'green'; // default color
		}
	};

	const revenueStatusToStage = (revenueStatus) => {
		return revenueStatus.toLowerCase().replace(/ /g, '_');
	};

	// Function to generate timeline items dynamically based on timelineData
	const generateTimelineItems = () => {
		return timelineData.map((item, index) => {
			let itemClassName = '';
			let itemDot = null;
			let itemChildren = null;

			// Determine className, dot, and children based on item properties
			if (item.action_taken) {
				if (item.appeal_status == 'Rejected') {
					itemClassName = 'rejected';
					itemDot = (
						<img
							alt=""
							className="img-fluid"
							src="/images/icon-rejected.svg"
						/>
					);
					itemChildren = (
						<>
							<Row>
								<Col span={12}>
									<p>{item.revenue_status}</p>
								</Col>
								<Col span={12} className="text-end">
									<Space className="">
										{item && item.edit_denial_case && (
											<EditOutlined
												onClick={() => {
													setRevenueCycleStage(
														revenueStatusToStage(
															item.revenue_status,
														),
													);
													setRevenueCycleStatus('');
													setUpdateModalOpen([
														true,
														'',
													]);
													setRevenueCycleFormData({
														prev_update: true,
														id: id,
														reimbursed_at: '',
														appeal_status: '',
														claim_category: '',
														claim_amount: null,
														status: '',
														revenue_cycle_stage: '',
														is_claim_amount_settled:
															null,
														appeal_status_updated_at:
															'',
														revenue_cycle_updates: {
															revenue_cycle_status:
																'',
															appeal_status: '',
															remarks: '',
															url: [],
														},
													});
													setFileList([]);
													setUpdatedFileList([]);
													setSelectedOption('');
												}}
												style={{
													fontSize: '12px',
												}}
											/>
										)}
										<Tag
											className="tagRejected"
											icon={
												<XFilled
													style={{
														fontSize: '8px',
														lineHeight: '20px',
													}}
												/>
											}
										>
											Rejected
										</Tag>
									</Space>
								</Col>
							</Row>
							<div className="d-flex flex-wrap gap-3 justify-content-end">
								{item.appeal_letter && (
									<Row
										className="my-4 text-end"
										gutter={[30, 30]}
									>
										<Col xs={24}>
											{Array.isArray(
												item.appeal_letter,
											) &&
												item.appeal_letter.map(
													(item, index) => {
														return (
															<>
																<>
																	<Card
																		hoverable
																		style={{
																			height: 40,
																		}}
																		onClick={() =>
																			handlePreview(
																				`${config.s3_url}${item.url}`,
																			)
																		}
																		id="timeline-documents-hover"
																	>
																		<Space className="flex-wrap">
																			<img
																				src="/images/appeal-letter-icon.svg"
																				style={{
																					width: '20px',
																				}}
																				alt="appeal letter"
																			/>
																			<p
																				style={{
																					color: 'black',
																					marginBottom: 0,
																					width: 'max-content',
																				}}
																			>
																				Appeal
																				Letter
																			</p>
																		</Space>
																	</Card>
																</>
															</>
														);
													},
												)}
										</Col>
									</Row>
								)}
								<Row
									className="my-4 text-end"
									gutter={[30, 30]}
								>
									<Col xs={24}>
										{item.remarks && (
											<>
												{Array.isArray(item.remarks) ? (
													item.remarks.map(
														(item, index) => {
															return (
																<>
																	{item.name.split(
																		'.',
																	)[1] ===
																	'pdf' ? (
																		<>
																			<Card
																				hoverable
																				style={{
																					height: 40,
																				}}
																				onClick={() =>
																					handlePreview(
																						`${config.s3_url}${item.url}`,
																					)
																				}
																				id="timeline-documents-hover"
																			>
																				<Space className="flex-wrap">
																					<img
																						src="/images/denial-letter-icon.svg"
																						style={{
																							width: '20px',
																						}}
																						alt="denial letter"
																					/>
																					<p
																						style={{
																							color: 'black',
																							marginBottom: 0,
																							width: 'max-content',
																						}}
																					>
																						Denial
																						Letter
																					</p>
																				</Space>
																			</Card>
																		</>
																	) : (
																		<Image
																			style={{
																				borderRadius:
																					'10px',
																			}}
																			height={
																				40
																			}
																			width={
																				40
																			}
																			src={`${config.s3_url}${item.url}`}
																		/>
																	)}
																</>
															);
														},
													)
												) : (
													<Card
														hoverable
														style={{
															height: 40,
														}}
														onClick={() => {
															handleModalPreviewRemarks(
																item.remarks,
																item.revenue_status,
															);
														}}
														id="timeline-documents-hover"
													>
														<Space className="flex-wrap">
															{/* <FileTextOutlined
																style={{
																	fontSize:
																		'20px',
																}}
															/> */}
															<img
																src="/images/denial-letter-icon.svg"
																style={{
																	width: '20px',
																}}
																alt="denial letter"
															/>
															<p
																style={{
																	color: 'black',
																	marginBottom: 0,
																	width: 'max-content',
																}}
															>
																Denial Letter
															</p>
														</Space>
													</Card>
													// <Card
													// 	hoverable
													// 	style={{
													// 		width: 40,
													// 		height: 40,
													// 	}}
													// 	onClick={() => {
													// 		handleModalPreviewRemarks(
													// 			item.remarks,
													// 			item.revenue_status,
													// 		);
													// 	}}
													// 	className="timeline-documents-hover"
													// >
													// 	<FileTextOutlined />
													// </Card>
												)}
											</>
										)}
									</Col>
								</Row>
							</div>
						</>
					);
				} else {
					itemClassName = 'success';
					itemDot = (
						<img
							alt=""
							className="img-fluid"
							src="/images/icon-approved.svg"
						/>
					);
					itemChildren = (
						<>
							<Row>
								<Col span={12}>
									<p>{item.revenue_status}</p>
								</Col>
								<Col span={12} className="text-end">
									<Tag
										className="tagSuccess"
										icon={
											<CheckCircleOutlined
												style={{
													fontSize: '12px',
													lineHeight: '20px',
												}}
											/>
										}
									>
										Approved
									</Tag>
								</Col>
							</Row>
						</>
					);
				}
			} else {
				if (item.appeal_status == 'Sent') {
					itemClassName = 'inprogress';
					itemDot = (
						<img
							alt=""
							className="img-fluid"
							src="/images/icon-success.svg"
						/>
					);
					itemChildren = (
						<>
							<Row>
								<Col span={10}>
									<p>{item.revenue_status}</p>
								</Col>
								<Col span={14} className="text-end">
									{item.days_ago && (
										<Tag
											color={getTagColor(
												item.filing_status,
											)}
											className="tagSuccess"
											icon={
												<ClockCircleOutlined
													style={{
														fontSize: '12px',
														lineHeight: '20px',
													}}
												/>
											}
										>
											{' '}
											{item.filing_status !==
											'Untimely' ? (
												<>
													{`Awaiting Payer Response.`}{' '}
													<br />
													{item.days_left}{' '}
													{item.timeline_type
														? toTitleCase(
																item.timeline_type,
														  )
														: item.timeline_type}{' '}
													{`Until Overdue`}
												</>
											) : (
												'Awaiting Payer Response. Timeline Overdue.'
											)}
										</Tag>
									)}
								</Col>
							</Row>
							<Row>
								<Col xxl={20} xs={24} md={16} lg={24} xl={20}>
									<div className="timeLineButton d-flex flex-direction-row align-items-center">
										{checkModulePermissions(
											'ma-plan-appeals-processing',
										).sub_modules.includes(
											'prepare-appeal-letter',
										) ||
										checkModulePermissions(
											'case-filing',
										).sub_modules.includes(
											'prepare-appeal-letter',
										) ? (
											<>
												<Button
													type="primary"
													onClick={() => {
														setRevenueCycleStage(
															revenueStatusToStage(
																item.revenue_status,
															),
														);
														setRevenueCycleStatus(
															'',
														);
														setUpdateModalOpen([
															true,
															'',
														]);
														setRevenueCycleFormData(
															{
																id: id,
																reimbursed_at:
																	'',
																appeal_status:
																	'',
																claim_category:
																	'',
																claim_amount:
																	null,
																status: '',
																revenue_cycle_stage:
																	'',
																is_claim_amount_settled:
																	null,
																appeal_status_updated_at:
																	'',
																revenue_cycle_updates:
																	{
																		revenue_cycle_status:
																			'',
																		appeal_status:
																			'',
																		remarks:
																			'',
																		url: [],
																	},
															},
														);
														setFileList([]);
														setUpdatedFileList([]);
														setSelectedOption('');
													}}
												>
													Update Status
												</Button>
												<Divider
													type="vertical"
													style={{
														borderColor: '#bebfd0',
														fontSize: 'large',
													}}
												/>
											</>
										) : (
											<></>
										)}
										<small
											style={{
												fontSize: '10px',
												color: '#7C7C7C',
											}}
										>
											{item.days_ago
												? toTitleCase(item.days_ago)
												: item.days_ago}{' '}
										</small>
									</div>
								</Col>
							</Row>
						</>
					);
				} else if (item.appeal_status === null) {
					itemClassName = 'pending';
					itemDot = (
						<img
							alt=""
							className="img-fluid"
							src="/images/icon-success.svg"
						/>
					);
					itemChildren = (
						<>
							<Row>
								<Col xs={13} md={10}>
									<p>{item.revenue_status}</p>
								</Col>
								<Col xs={11} md={14} className="text-end">
									{item.days_left && (
										<Tag
											color={getTagColor(
												item.filing_status,
											)}
											className="tagSuccess"
											icon={
												<ClockCircleOutlined
													style={{
														fontSize: '12px',
														lineHeight: '20px',
													}}
												/>
											}
										>
											{item.filing_status !==
											'Untimely' ? (
												<>
													{item.days_left}{' '}
													{item.timeline_type
														? toTitleCase(
																item.timeline_type,
														  )
														: item.timeline_type}{' '}
													{`Left To Respond`}
												</>
											) : (
												'Awaiting Payer Response. Timeline Overdue.'
											)}
										</Tag>
									)}
								</Col>
							</Row>
							<Row>
								{checkModulePermissions(
									'ma-plan-appeals-processing',
								).sub_modules.includes(
									'prepare-appeal-letter',
								) ||
								checkModulePermissions(
									'case-filing',
								).sub_modules.includes(
									'prepare-appeal-letter',
								) ? (
									<Col span={12}>
										<div className="timeLineButton">
											<Button
												type="primary"
												onClick={() => {
													setTogglePrepareLetter(
														true,
													);
													setFlag(false);
												}}
											>
												Prepare Letter
											</Button>
										</div>
									</Col>
								) : (
									<></>
								)}
							</Row>
						</>
					);
				} else if (item.appeal_status == 'Approved') {
					itemClassName = 'success';
					itemDot = (
						<img
							alt=""
							className="img-fluid"
							src="/images/icon-approved.svg"
						/>
					);
					itemChildren = (
						<>
							<Row>
								<Col span={12}>
									<p>{item.revenue_status}</p>
								</Col>
								<Col span={12} className="text-end">
									<Tag
										className="tagSuccess"
										icon={
											<CheckCircleOutlined
												style={{
													fontSize: '12px',
													lineHeight: '20px',
												}}
											/>
										}
									>
										Approved
									</Tag>
								</Col>
							</Row>
						</>
					);
				} else if (item.appeal_status == 'Pending') {
					itemClassName = 'pending';
					itemDot = (
						<img
							class="img-fluid"
							alt=""
							src="/images/icon-pending.svg"
						/>
					);
					itemChildren = (
						<>
							<Row>
								<Col span={12}>
									<p>{item.revenue_status}</p>
								</Col>
							</Row>
						</>
					);
				}
			}

			// Return generated timeline item
			return {
				key: index.toString(), // Provide a unique key
				className: itemClassName,
				dot: itemDot,
				children: itemChildren,
			};
		});
	};

	return (
		<div className="scrollAfix">
			{case_status == 'allocated' ? (
				<>
					<h6
					// style={{color: "#cd4949"}}
					>
						Case Analysis Awaited ...
					</h6>
				</>
			) : medicareCoverage == 'Yes' ? (
				<>
					<Timeline items={generateTimelineItems()} />
					{/* Modal for preview */}
					<Modal
						open={pdfPreviewOpen}
						title="Preview"
						footer={null}
						onCancel={() => setPdfPreviewOpen(false)}
						width={pdfWidth} // Set the width of the modal dynamically
						// Other modal props...
					>
						<div>
							<Worker workerUrl="https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js">
								<div style={{ height: '750px' }}>
									<Viewer fileUrl={previewImage} />
								</div>
							</Worker>
							{/* <Document
								file={previewImage}
								onLoadSuccess={onDocumentLoadSuccess}
								onLoadError={onDocumentLoadError}
							>
								{Array.from(
									new Array(numPages),
									(el, index) => (
										<Page
											key={`page_${index + 1}`}
											pageNumber={index + 1}
											onLoadSuccess={(page) =>
												updateModalWidth(page.width)
											}
											renderTextLayer={false}
											renderAnnotationLayer={false}
										/>
									),
								)}
							</Document> */}
							{/* <p>
								Page {pageNumber} of {numPages}
							</p> */}
						</div>
					</Modal>
					<Modal
						title={`Denial Reason for ${statusName}`}
						open={isModalOpen}
						onOk={handleOk}
						onCancel={handleCancel}
						okButtonProps={{
							style: {
								display: 'none',
							},
						}}
						cancelButtonProps={{ style: { display: 'none' } }}
					>
						<p>{documentText}</p>
					</Modal>
				</>
			) : (
				<>
					<h6 style={{ color: '#cd4949' }}>
						This case is not covered under Medicare Coverage
					</h6>
				</>
			)}
		</div>
	);
};

export default CustomTimeline;
