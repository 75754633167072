import React, { useState } from 'react';
import { Row, Col, Divider, Dropdown } from 'antd';
import {
	XTable,
	XCardsData,
	XTag,
	XPagination,
} from '../../styles/GlobalStyle';
import { MoreOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { checkModulePermissions, toTitleCase } from '../../utils/helper';
import { XButtonImg } from '../../pages/Manage/ManageStyle';

const DataTable = (props) => {
	const {
		columns,
		tableData,
		totalRecords,
		setFilters,
		filters,
		state,
		page,
		showDeleteConfirm,
		handleEdit,
	} = props;

	const [id, setId] = useState('');
	const [name, setName] = useState('');
	const [itemData, setItemData] = useState({});
	const filteredColumns = columns.filter(
		(col) => col.dataIndex !== 'statusMobile',
	);

	const fetchCases = (pagination, _, sorter, ___) => {
		const queryParams = new URLSearchParams();

		// Append each filter to the query string
		Object.entries(filters).forEach(([key, value]) => {
			queryParams.append(key, value);
		});

		//Setting page filters: page size, sort, sort direction and limit in the query string
		queryParams.set(
			'page',
			pagination.current ? pagination.current : pagination,
		);
		queryParams.set('limit', pagination?.pageSize ?? 10);

		if (sorter && Object.keys(sorter).length > 0) {
			queryParams.set('sort', sorter ? sorter.field : 'createdAt');
			queryParams.set(
				'sortDirection',
				sorter ? sorter.order : filters.sortDirection,
			);
		} else {
			queryParams.set('sort', 'createdAt');
			queryParams.set('sortDirection', 'descend');
		}

		const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
		window.history.pushState({}, '', newUrl);
		setFilters({
			...filters,
			sort: sorter ? sorter.field : 'createdAt',
			sortDirection: sorter ? sorter.order : 'descend',
			page: pagination.current ? pagination.current : pagination,
			limit: pagination.pageSize ? pagination.pageSize : 10,
		});
	};

	const desiredColumnOrder = [
		'case_id',
		'createdAt',
		'hospital',
		'state',
		'insurance_company',
		'medical_coverage',
		'allocated',
	];
	const items = [
		{
			key: '1',
			label: (
				<div
					onClick={() => {
						handleEdit(itemData);
					}}
				>
					Edit
				</div>
			),
			icon: <img src="./images/pencil.svg" alt="" />,
		},
		{
			key: '2',
			label: (
				<div
					onClick={() => {
						showDeleteConfirm(id, name);
					}}
				>
					Delete
				</div>
			),
			icon: <img src="./images/delete-icon-card.svg" alt="" />,
		},
	];
	// Filter and sort the columns based on the desired order
	const orderedColumns = columns
		.filter((col) => desiredColumnOrder.includes(col.dataIndex))
		.sort(
			(a, b) =>
				desiredColumnOrder.indexOf(a.dataIndex) -
				desiredColumnOrder.indexOf(b.dataIndex),
		);

	const cardsToRender = (item) => {
		switch (page) {
			case 'case':
				return (
					<div style={{ padding: 5 }}>
						<Row
							className="p-3"
							style={{
								alignItems: 'center',
							}}
							gutter={[10, 0]}
						>
							<Col xs={12}>
								<Link
									to={
										Array.isArray(item.case_id)
											? `${item.case_id[2]}/${item.case_id[0]}`
											: ''
									}
								>
									<p
										style={{
											color: '#212226',
											fontFamily: 'Inter',
											fontSize: '16px',
											fontStyle: 'normal',
											fontWeight: '600',
											lineHeight: '30px',
											letterSpacing: '-0.32px',
											marginBottom: '7px',
										}}
									>
										{item.patient_name &&
										Array.isArray(item.patient_name)
											? item.patient_name[1]
											: item.patient_name}
									</p>
								</Link>
							</Col>
							<Col
								xs={12}
								style={{
									display: 'flex',
									justifyContent: 'end',
								}}
							>
								{item.statusMobile ? (
									<XTag
										className={`${item.statusMobile} mb-2`}
									>
										{toTitleCase(item.statusMobile)}
									</XTag>
								) : item.action ? (
									item.action
								) : (
									<></>
								)}
							</Col>
							<Col xs={12}>
								<p
									style={{
										color: '#212226',
										fontFamily: 'Inter',
										fontSize: '14px',
										fontStyle: 'normal',
										fontWeight: '400',
										lineHeight: '14px',
										letterSpacing: '-0.28px',
									}}
								>
									{Array.isArray(item.mrn)
										? item.mrn[1]
										: item.mrn}
								</p>
							</Col>

							<Divider dashed className="mt-1 mb-3" />

							{orderedColumns.map((col, index) => {
								if (
									col.dataIndex === 'action' ||
									col.dataIndex === 'mrn' ||
									col.dataIndex === 'patient_name' ||
									col.title === 'status'
								)
									return null;
								const dataIndex = col.dataIndex;
								const dataValue = item[dataIndex];
								return (
									<>
										<Col
											xs={
												dataIndex ===
													'insurance_company' ||
												dataIndex === 'medical_coverage'
													? 24
													: 12
											}
											className="my-1"
										>
											<p
												style={{
													color: '#212226',
													fontFamily: 'Inter',
													fontSize: '12px',
													fontStyle: 'normal',
													fontWeight: '600',
													lineHeight: '14px',
													letterSpacing: '-0.24px',
													marginBottom: '9px',
												}}
											>
												{col.title}:
											</p>

											<p
												style={{
													color: '#212226',
													fontFamily: 'Inter',
													fontSize: '14px',
													fontStyle: 'normal',
													fontWeight: '400',
													lineHeight: '14px',
													letterSpacing: '-0.28px',
												}}
											>
												{Array.isArray(dataValue)
													? dataValue[1]
													: dataValue}
											</p>
										</Col>
									</>
								);
							})}
							<Col xs={12} className="my-1">
								{item.action ? item.action : ''}
							</Col>
						</Row>
					</div>
				);
			case 'user-management':
				return (
					<div style={{ padding: 5 }}>
						<Row
							className="p-3"
							style={{
								alignItems: 'center',
							}}
							gutter={[10, 0]}
						>
							<Col xs={20}>
								<p
									style={{
										color: '#212226',
										fontFamily: 'Inter',
										fontSize: '16px',
										fontStyle: 'normal',
										fontWeight: '600',
										lineHeight: '30px',
										letterSpacing: '-0.32px',
										marginBottom: '7px',
									}}
								>
									{item.userName ? item.userName : item.role}
								</p>
							</Col>
							<Col
								xs={4}
								style={{
									display: 'flex',
									justifyContent: 'end',
								}}
							>
								{item.action ? (
									checkModulePermissions(
										'user-management',
									).sub_modules.includes('update-users') ? (
										<XButtonImg
											onClick={() => {
												handleEdit(item);
											}}
											type="text"
										>
											<img
												src="./images/pencil.svg"
												alt=""
											/>
										</XButtonImg>
									) : (
										<></>
									)
								) : (
									<></>
								)}
							</Col>

							{columns.map((col, index) => {
								if (
									col.dataIndex === 'userName' ||
									col.dataIndex === 'action'
								)
									return null;
								const dataIndex = col.dataIndex;
								const dataValue = item[dataIndex];
								return (
									<>
										<Col xs={24}>
											<p
												style={{
													color: '#212226',
													fontFamily: 'Inter',
													fontSize: '14px',
													fontStyle: 'normal',
													fontWeight: '400',
													lineHeight: '14px',
													letterSpacing: '-0.28px',
												}}
											>
												{dataValue &&
												Array.isArray(dataValue)
													? dataValue[1]
													: dataValue}
											</p>
										</Col>
									</>
								);
							})}
						</Row>
					</div>
				);
			case 'role-management':
				return (
					<div style={{ padding: 5 }}>
						<Row
							className="p-3"
							style={{
								alignItems: 'center',
							}}
							gutter={[10, 0]}
						>
							<Col xs={20}>
								<p
									style={{
										color: '#212226',
										fontFamily: 'Inter',
										fontSize: '16px',
										fontStyle: 'normal',
										fontWeight: '600',
										lineHeight: '30px',
										letterSpacing: '-0.32px',
										marginBottom: '7px',
									}}
								>
									{item.userName ? item.userName : item.role}
								</p>
							</Col>
							<Col
								xs={4}
								style={{
									display: 'flex',
									justifyContent: 'end',
								}}
							>
								{item.action ? (
									checkModulePermissions(
										'user-management',
									).sub_modules.includes('update-users') ? (
										<XButtonImg
											onClick={() => {
												handleEdit(item);
											}}
											type="text"
										>
											<img
												src="./images/pencil.svg"
												alt=""
											/>
										</XButtonImg>
									) : (
										<></>
									)
								) : (
									<></>
								)}
							</Col>

							{columns.map((col, index) => {
								if (
									col.dataIndex === 'role' ||
									col.dataIndex === 'action' ||
									col.dataIndex === 'modules'
								)
									return null;
								const dataIndex = col.dataIndex;
								const dataValue = item[dataIndex];
								return (
									<>
										<Col xs={24}>
											<p
												style={{
													color: '#212226',
													fontFamily: 'Inter',
													fontSize: '14px',
													fontStyle: 'normal',
													fontWeight: '400',
													lineHeight: '14px',
													letterSpacing: '-0.28px',
												}}
											>
												{dataValue &&
												Array.isArray(dataValue)
													? dataValue[1]
													: dataValue}
											</p>
										</Col>
									</>
								);
							})}
						</Row>
					</div>
				);
			case 'insurance-company':
			case 'hospital':
				return (
					<div style={{ padding: 5 }}>
						<Row
							className="p-3"
							style={{
								alignItems: 'center',
							}}
							gutter={[10, 0]}
						>
							<Col xs={20}>
								<p
									style={{
										color: '#212226',
										fontFamily: 'Inter',
										fontSize: '16px',
										fontStyle: 'normal',
										fontWeight: '600',
										lineHeight: '30px',
										letterSpacing: '-0.32px',
										marginBottom: '7px',
									}}
								>
									{item.name && Array.isArray(item.name)
										? item.name[1]
										: item.name}
								</p>
							</Col>
							<Col
								xs={4}
								style={{
									display: 'flex',
									justifyContent: 'end',
								}}
							>
								{item.action ? (
									checkModulePermissions(
										'hospital-management',
									).sub_modules.includes(
										'update-hospitals',
									) ? (
										<XButtonImg
											onClick={() => {
												handleEdit(item);
											}}
											type="text"
										>
											<img
												src="./images/pencil.svg"
												alt=""
											/>
										</XButtonImg>
									) : (
										<></>
									)
								) : (
									<></>
								)}
							</Col>

							<Col xs={24}>
								<p
									style={{
										color: '#212226',
										fontFamily: 'Inter',
										fontSize: '14px',
										fontStyle: 'normal',
										fontWeight: '400',
										lineHeight: '14px',
										letterSpacing: '-0.28px',
									}}
								>
									{item.contact_person_email_address || ''}
								</p>
							</Col>

							<Divider dashed className="mt-1 mb-3" />

							{columns.map((col, index) => {
								if (
									col.dataIndex === 'action' ||
									col.dataIndex === 'name' ||
									col.dataIndex ===
										'contact_person_email_address'
								)
									return null;
								const dataIndex = col.dataIndex;
								const dataValue = item[dataIndex];
								return (
									<>
										<Col
											xs={
												dataIndex === 'address'
													? 24
													: 12
											}
											className="my-1"
										>
											<p
												style={{
													color: '#212226',
													fontFamily: 'Inter',
													fontSize: '12px',
													fontStyle: 'normal',
													fontWeight: '600',
													lineHeight: '14px',
													letterSpacing: '-0.24px',
													marginBottom: '9px',
												}}
											>
												{col.title}:
											</p>

											<p
												style={{
													color: '#212226',
													fontFamily: 'Inter',
													fontSize: '14px',
													fontStyle: 'normal',
													fontWeight: '400',
													lineHeight: '14px',
													letterSpacing: '-0.28px',
												}}
											>
												{dataValue &&
												Array.isArray(dataValue)
													? dataValue[1]
													: dataValue}
											</p>
										</Col>
									</>
								);
							})}
						</Row>
					</div>
				);
			case 'feedback':
				return (
					<div style={{ padding: 5 }}>
						<Row
							className="p-3"
							style={{
								alignItems: 'center',
							}}
							gutter={[10, 0]}
						>
							<Col xs={12}>
								<Link
									to={`${item.case_id[2]}/${item.case_id[0]}`}
								>
									<p
										style={{
											color: '#212226',
											fontFamily: 'Inter',
											fontSize: '16px',
											fontStyle: 'normal',
											fontWeight: '600',
											lineHeight: '30px',
											letterSpacing: '-0.32px',
											marginBottom: '7px',
										}}
									>
										{item.patient_name &&
										Array.isArray(item.patient_name)
											? item.patient_name[1]
											: item.patient_name}
									</p>
								</Link>
							</Col>
							<Col
								xs={12}
								style={{
									display: 'flex',
									justifyContent: 'end',
								}}
							>
								{item.statusMobile ? (
									<XTag
										className={`${item.statusMobile} mb-2`}
									>
										{toTitleCase(item.statusMobile)}
									</XTag>
								) : item.action ? (
									item.action
								) : (
									<></>
								)}
							</Col>
							<Col xs={12}>
								<p
									style={{
										color: '#212226',
										fontFamily: 'Inter',
										fontSize: '14px',
										fontStyle: 'normal',
										fontWeight: '400',
										lineHeight: '14px',
										letterSpacing: '-0.28px',
									}}
								>
									{Array.isArray(item.mrn)
										? item.mrn[1]
										: item.mrn}
								</p>
							</Col>

							<Divider dashed className="mt-1 mb-3" />

							{columns.map((col, index) => {
								if (
									col.dataIndex === 'action' ||
									col.dataIndex === 'mrn' ||
									col.dataIndex === 'patient_name' ||
									col.dataIndex === 'status'
								)
									return null;
								const dataIndex = col.dataIndex;
								const dataValue = item[dataIndex];

								return (
									<>
										<Col
											xs={
												dataIndex === 'feedback'
													? 24
													: 12
											}
											className="my-1"
										>
											<p
												style={{
													color: '#212226',
													fontFamily: 'Inter',
													fontSize: '12px',
													fontStyle: 'normal',
													fontWeight: '600',
													lineHeight: '14px',
													letterSpacing: '-0.24px',
													marginBottom: '9px',
												}}
											>
												{col.title}:
											</p>
											<p
												style={{
													color: '#212226',
													fontFamily: 'Inter',
													fontSize: '14px',
													fontStyle: 'normal',
													fontWeight: '400',
													lineHeight: '14px',
													letterSpacing: '-0.28px',
												}}
											>
												{Array.isArray(dataValue)
													? dataValue[1]
													: dataValue}
											</p>
										</Col>
									</>
								);
							})}
							<Col xs={12} className="my-1">
								{item.action ? item.action : ''}
							</Col>
						</Row>
					</div>
				);
			case 'module':
				return (
					<div style={{ padding: 5 }}>
						<Row
							className="p-3"
							style={{
								alignItems: 'center',
							}}
							gutter={[10, 0]}
						>
							<Col xs={20}>
								<p
									style={{
										color: '#212226',
										fontFamily: 'Inter',
										fontSize: '16px',
										fontStyle: 'normal',
										fontWeight: '600',
										lineHeight: '30px',
										letterSpacing: '-0.32px',
										marginBottom: '7px',
									}}
								>
									{item.module_name}
								</p>
							</Col>
							<Col
								xs={4}
								style={{
									display: 'flex',
									justifyContent: 'end',
								}}
							>
								{item.statusMobile ? (
									<XTag
										className={`${item.statusMobile} mb-2`}
									>
										{toTitleCase(item.statusMobile)}
									</XTag>
								) : item.action ? (
									item.action
								) : (
									<></>
								)}
							</Col>

							<Divider dashed className="mt-1 mb-3" />

							{columns.map((col, index) => {
								if (
									col.dataIndex === 'action' ||
									col.dataIndex === 'module_name'
								)
									return null;
								const dataIndex = col.dataIndex;
								const dataValue = item[dataIndex];

								return (
									<>
										<Col xs={24} className="my-1">
											<p
												style={{
													color: '#212226',
													fontFamily: 'Inter',
													fontSize: '12px',
													fontStyle: 'normal',
													fontWeight: '600',
													lineHeight: '14px',
													letterSpacing: '-0.24px',
													marginBottom: '9px',
												}}
											>
												{col.title}:
											</p>
											<p
												style={{
													color: '#212226',
													fontFamily: 'Inter',
													fontSize: '14px',
													fontStyle: 'normal',
													fontWeight: '400',
													lineHeight: '14px',
													letterSpacing: '-0.28px',
												}}
											>
												{dataValue}
											</p>
										</Col>
									</>
								);
							})}
						</Row>
					</div>
				);
			case 'region':
				return (
					<div style={{ padding: 5 }}>
						<Row
							className="p-3"
							style={{
								alignItems: 'center',
							}}
							gutter={[10, 0]}
						>
							<Col xs={20}>
								<p
									style={{
										color: '#212226',
										fontFamily: 'Inter',
										fontSize: '16px',
										fontStyle: 'normal',
										fontWeight: '600',
										lineHeight: '30px',
										letterSpacing: '-0.32px',
										marginBottom: '7px',
									}}
								>
									{item.name}
								</p>
							</Col>
							<Col
								xs={4}
								style={{
									display: 'flex',
									justifyContent: 'end',
								}}
							>
								{item.action ? (
									<Dropdown
										menu={{
											items,
										}}
									>
										<MoreOutlined
											onClick={() => {
												setId(item.key);
												setName(item.userName);
												setItemData(item);
											}}
										/>
									</Dropdown>
								) : (
									<></>
								)}
							</Col>

							{columns.map((col, index) => {
								if (
									col.dataIndex === 'name' ||
									col.dataIndex === 'action'
								)
									return null;
								const dataIndex = col.dataIndex;
								const dataValue = item[dataIndex];
								return (
									<>
										<Col xs={24}>
											<p
												style={{
													color: '#212226',
													fontFamily: 'Inter',
													fontSize: '14px',
													fontStyle: 'normal',
													fontWeight: '400',
													lineHeight: '14px',
													letterSpacing: '-0.28px',
												}}
											>
												{dataValue &&
												Array.isArray(dataValue)
													? dataValue[1]
													: dataValue}
											</p>
										</Col>
									</>
								);
							})}
						</Row>
					</div>
				);
			case 'organization':
				return (
					<div style={{ padding: 5 }}>
						<Row
							className="p-3"
							style={{
								alignItems: 'center',
							}}
							gutter={[10, 0]}
						>
							<Col xs={20}>
								<p
									style={{
										color: '#212226',
										fontFamily: 'Inter',
										fontSize: '16px',
										fontStyle: 'normal',
										fontWeight: '600',
										lineHeight: '30px',
										letterSpacing: '-0.32px',
										marginBottom: '7px',
									}}
								>
									{item.name && Array.isArray(item.name)
										? item.name[1]
										: item.name}
								</p>
							</Col>
							<Col
								xs={4}
								style={{
									display: 'flex',
									justifyContent: 'end',
								}}
							>
								{item.action ? (
									checkModulePermissions(
										'organization-management',
									).sub_modules.includes(
										'update-organization',
									) ? (
										<XButtonImg
											onClick={() => {
												handleEdit(item);
											}}
											type="text"
										>
											<img
												src="./images/pencil.svg"
												alt=""
											/>
										</XButtonImg>
									) : (
										<></>
									)
								) : (
									<></>
								)}
							</Col>
							<Col xs={24}>
								<p
									style={{
										color: '#212226',
										fontFamily: 'Inter',
										fontSize: '14px',
										fontStyle: 'normal',
										fontWeight: '400',
										lineHeight: '14px',
										letterSpacing: '-0.28px',
									}}
								>
									{item.contact_person_email_address || ''}
								</p>
							</Col>

							<Divider dashed className="mt-1 mb-3" />

							{columns.map((col, index) => {
								if (
									col.dataIndex === 'action' ||
									col.dataIndex === 'name' ||
									col.dataIndex ===
										'contact_person_email_address'
								)
									return null;
								const dataIndex = col.dataIndex;
								const dataValue = item[dataIndex];
								return (
									<>
										<Col
											xs={
												dataIndex === 'address'
													? 24
													: 12
											}
											className="my-1"
										>
											<p
												style={{
													color: '#212226',
													fontFamily: 'Inter',
													fontSize: '12px',
													fontStyle: 'normal',
													fontWeight: '600',
													lineHeight: '14px',
													letterSpacing: '-0.24px',
													marginBottom: '9px',
												}}
											>
												{col.title}:
											</p>

											<p
												style={{
													color: '#212226',
													fontFamily: 'Inter',
													fontSize: '14px',
													fontStyle: 'normal',
													fontWeight: '400',
													lineHeight: '14px',
													letterSpacing: '-0.28px',
												}}
											>
												{dataValue &&
												Array.isArray(dataValue)
													? dataValue[1]
													: dataValue}
											</p>
										</Col>
									</>
								);
							})}
						</Row>
					</div>
				);
			case 'reimbursements':
				return (
					<div style={{ padding: 5 }}>
						<Row
							className="p-3"
							style={{
								alignItems: 'center',
							}}
							gutter={[10, 0]}
						>
							<Col xs={12}>
								<Link
									to={
										Array.isArray(item.case_id)
											? `${item.case_id[2]}/${item.case_id[0]}`
											: ''
									}
								>
									<p
										style={{
											color: '#212226',
											fontFamily: 'Inter',
											fontSize: '16px',
											fontStyle: 'normal',
											fontWeight: '600',
											lineHeight: '30px',
											letterSpacing: '-0.32px',
											marginBottom: '7px',
										}}
									>
										{item.patient_name &&
										Array.isArray(item.patient_name)
											? item.patient_name[1]
											: item.patient_name}
									</p>
								</Link>
							</Col>
							<Col
								xs={12}
								style={{
									display: 'flex',
									justifyContent: 'end',
								}}
							>
								{item.statusMobile ? (
									<XTag
										className={`${item.statusMobile} mb-2`}
									>
										{toTitleCase(item.statusMobile)}
									</XTag>
								) : item.action ? (
									item.action
								) : (
									<></>
								)}
							</Col>
							<Col xs={12}>
								<p
									style={{
										color: '#212226',
										fontFamily: 'Inter',
										fontSize: '14px',
										fontStyle: 'normal',
										fontWeight: '400',
										lineHeight: '14px',
										letterSpacing: '-0.28px',
									}}
								>
									{Array.isArray(item.mrn)
										? item.mrn[1]
										: item.mrn}
								</p>
							</Col>

							<Divider dashed className="mt-1 mb-3" />

							{columns.map((col, index) => {
								if (
									['action','mrn','patient_name', 'reimbursement'].includes(col.dataIndex) ||
									col.title === 'status'
								)
									return null;
								const dataIndex = col.dataIndex;
								const dataValue = item[dataIndex];
								return (
									<>
										<Col
											xs={
												dataIndex ===
													'insurance_company' ||
												dataIndex === 'medical_coverage'
													? 24
													: 12
											}
											className="my-1"
										>
											<p
												style={{
													color: '#212226',
													fontFamily: 'Inter',
													fontSize: '12px',
													fontStyle: 'normal',
													fontWeight: '600',
													lineHeight: '14px',
													letterSpacing: '-0.24px',
													marginBottom: '9px',
												}}
											>
												{col.title}:
											</p>

											<p
												style={{
													color: '#212226',
													fontFamily: 'Inter',
													fontSize: '14px',
													fontStyle: 'normal',
													fontWeight: '400',
													lineHeight: '14px',
													letterSpacing: '-0.28px',
												}}
											>
												{Array.isArray(dataValue)
													? dataValue[1]
													: dataValue}
											</p>
										</Col>
									</>
								);
							})}
							<Col xs={12} className="my-1">
								{item.action ? item.action : ''}
							</Col>
						</Row>
					</div>
				);
			case 'payer-configuration':
				return (
					<div style={{ padding: 5 }}>
						<Row
							className="p-3"
							style={{
								alignItems: 'center',
							}}
							gutter={[10, 0]}
						>
							{columns.map((col, index) => {
								if (
									['action','mrn','patient_name', 'reimbursement'].includes(col.dataIndex) ||
									col.title === 'status'
								)
									return null;
								const dataIndex = col.dataIndex;
								const dataValue = item[dataIndex];
								return (
									<>
										<Col
											xs={
												dataIndex ===
													'insurance_company' ||
												dataIndex === 'medical_coverage'
													? 24
													: 12
											}
											className="my-1"
										>
											<p
												style={{
													color: '#212226',
													fontFamily: 'Inter',
													fontSize: '12px',
													fontStyle: 'normal',
													fontWeight: '600',
													lineHeight: '14px',
													letterSpacing: '-0.24px',
													marginBottom: '9px',
												}}
											>
												{col.title}:
											</p>

											<p
												style={{
													color: '#212226',
													fontFamily: 'Inter',
													fontSize: '14px',
													fontStyle: 'normal',
													fontWeight: '400',
													lineHeight: '14px',
													letterSpacing: '-0.28px',
												}}
											>
												{Array.isArray(dataValue)
													? dataValue[1]
													: dataValue}
											</p>
										</Col>
									</>
								);
							})}
							<Col xs={{offset:18, span: 4}} className="my-1">
								{item.action ? item.action : ''}
							</Col>
						</Row>
					</div>
				);
			
			default:
				<></>;
				break;
		}
	};

	return (
		<>
			<Row gutter={16}>
				<Col xs={0} sm={0} md={24} lg={24} xl={24}>
					{/* Render the table for larger screens */}
					<XTable
						columns={filteredColumns}
						dataSource={tableData}
						loading={state === 'loading' ? true : false}
						scroll={{ x: 991 }}
						pagination={{	
							total: totalRecords,
							current: Number(filters.page),
							showSizeChanger: true,
							pageSize: filters.limit,
						}}
						className="customHorizontalScroll"
						onChange={fetchCases}
						showSorterTooltip={false}
					/>
				</Col>
				<Col xs={24} sm={24} md={0} lg={0} xl={0} className="p-0">
					{/* Render the cards for smaller screens */}
					{tableData.map((item, index) => {
						return (
							<XCardsData key={index}>
								{cardsToRender(item)}
							</XCardsData>
						);
					})}
				</Col>
				<Col
					xs={24}
					sm={24}
					md={0}
					lg={0}
					xl={0}
					className="text-center my-4"
				>
					<XPagination
						current={filters.page ? Number(filters.page) : 1}
						total={totalRecords}
						onChange={fetchCases}
						showSizeChanger
					/>
				</Col>
			</Row>
		</>
	);
};

export default DataTable;
