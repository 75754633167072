import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
	Row,
	Col,
	Input,
	Button,
	DatePicker,
	InputNumber,
	notification,
	Result,
	Select,
	Upload,
	Modal,
	Card,
	Image,
} from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import {
	XCardsCaseBg,
	XCardsTransparent,
	XForm,
	XModal,
	XSelect,
} from '../../styles/GlobalStyle';
import {
	ArrowLeftOutlined,
	FieldTimeOutlined,
	DownloadOutlined,
	PlusOutlined,
} from '@ant-design/icons';
import { XCardBackBg, XButtonBack, XEditTitle, XRadio } from './CaseFormStyle';
import { XContainer } from '../../styles/GlobalStyle';
import HeaderFile from '../../components/Header/HeaderFile';
import dayjs from 'dayjs';

import {
	filterByLabel,
	futureDateDisable,
	handleDateChanged,
	handleInputChange,
	handleNumberInputChange,
	handleSelectChange,
	validateNonFutureDate,
} from '../../utils/formFunctions';
// actions
import {
	getUserDropdownApi,
	getUserDropdownReset,
} from '../../actions/getUserDropdownAction';
import {
	getCaseByIdApi,
	getCaseByIdReset,
} from '../../actions/getCaseByIdAction';
import { editCaseApi, editCaseReset } from '../../actions/editCaseAction';
import { addCaseApi, addCaseReset } from '../../actions/addCaseAction';
import {
	checkModulePermissions,
	disableFutureDates,
	getUserToken,
} from '../../utils/helper';
import { deleteCaseApi, deleteCaseReset } from '../../actions/deleteCaseAction';
import { getCasesApi } from '../../actions/getCasesAction';
import {
	getStateDropdownApi,
	getStateDropdownReset,
} from '../../actions/getStateDropdownAction';
import {
	getHospitalDropdownApi,
	getHospitalDropdownReset,
} from '../../actions/getHospitalDropdownAction';
import {
	getConfigurationOptionsApi,
	getConfigurationOptionsReset,
} from '../../actions/getConfigurationOptionsAction';
import {
	getOrganizationsApi,
	getOrganizationsReset,
} from '../../actions/getOrganizationsAction';
import { analyseCoverageApi } from '../../actions/analyseCoverageAction';
import Header from '../../components/Header/Header';
import { getConfigurationsApi } from '../../actions/getConfigurationsAction';
import axios from 'axios';
import config from '../../config';
import { getRegionsApi } from '../../actions/getRegionsAction';
import {
	processTypes,
	revenueCycleOptions,
	revenueCycleStage,
} from '../../utils/constants';
import { Document, Page, pdfjs } from 'react-pdf'; // Import necessary components from react-pdf
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { XButtonConfirm } from '../AnalyseCoverage/AnalyseCoverageStyle';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const { TextArea } = Input;

const CaseForm = (props) => {
	const {
		getUserDropdown,
		getUserDropdownState,
		getCaseById,
		getCaseByIdReset,
		getCaseByIdState,
		editCase,
		editCaseReset,
		editCaseState,
		addCase,
		addCaseReset,
		addCaseState,
		deleteCase,
		deleteCaseReset,
		deleteCaseState,
		getCases,
		getStateDropdownState,
		getStateDropdown,
		getHospitalDropdown,
		getHospitalDropdownState,
		getRegions,
		getRegionsState,
		getConfigurationsState,
		getConfigurations,
		getOrganizations,
		getOrganizationsState,
		getOrganizationsReset,
	} = props;
	const navigate = useNavigate();
	const id = useParams().id;
	let modulePermissions = checkModulePermissions('case-management');

	const [form] = XForm.useForm();
	const [formData, setFormData] = useState({
		status: 'pending',
		successful_claim_commission_criteria: 'percentage',
		successful_claim_commission_fixed_value: 0,
		successful_claim_commission_percentage_value: 0,
		rejected_claim_commission_fixed_value: 0,
		case_amount: null,
		claim_amount: null,
		mrn: '',
		state: '',
		hospital: '',
		title: '',
		revenue_cycle_updates: {
			remarks: '',
			status: '',
		},
	});

	const [insuranceCompanies, setInsuranceCompanies] = useState([]);
	const [visitOptions, setVisitOptions] = useState([]);
	const [titleData, setTitleData] = useState([]);
	const [denialReasonData, setDenialReasonData] = useState([]);
	const [genderData, setGenderData] = useState([]);
	const [caseDropdown, setCaseDropdown] = useState([]);
	const [displayOrganization, setDisplayOrganization] = useState(true);
	const [displayRegion, setDisplayRegion] = useState(true);
	const [displayHospital, setDisplayHospital] = useState(true);
	const [fileList, setFileList] = useState([]);
	const [previewOpen, setPreviewOpen] = useState(false);
	const [pdfPreviewOpen, setPdfPreviewOpen] = useState(false);
	const [previewImage, setPreviewImage] = useState('');
	const [previewTitle, setPreviewTitle] = useState('Upload Acknowledgement');
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
	const [pdfWidth, setPdfWidth] = useState(700); // Initial width of the modal
	const [updatedFileList, setUpdatedFileList] = useState([]);
	const [displayDenialInputs, setDisplayDenialInputs] = useState(
		'organization_determination',
	);
	const [selectedOption, setSelectedOption] = useState(
		'revenue_cycle_supporting_text',
	); // Initial value can be 'textarea' or 'upload'
	const [submitCase, setSubmitCase] = useState(false); // Initial value can be 'textarea' or 'upload'

	const onDOBChange = (date, otherData) => {
		if (date) {
			// Calculate age based on the selected date of birth
			const today = new Date();
			const birthDate = new Date(date);

			// Calculate the difference in years
			var years = today.getFullYear() - birthDate.getFullYear();

			// Calculate the difference in months
			var months = today.getMonth() - birthDate.getMonth();

			// Calculate the difference in days
			var days = today.getDate() - birthDate.getDate();

			// Adjust for negative months or days
			if (months < 0 || (months === 0 && days < 0)) {
				years--;
				months += 12;
			}

			if (days < 0) {
				months--;
				// Calculate the days difference considering the month's length
				var tempDate = new Date(today);
				tempDate.setMonth(today.getMonth() - 1);
				var daysInLastMonth = new Date(
					tempDate.getFullYear(),
					tempDate.getMonth() + 1,
					0,
				).getDate();
				days += daysInLastMonth;
			}
			if (otherData) {
				setFormData({
					...otherData,
					id: otherData._id,
				});
				form.setFieldsValue({
					...otherData,
					id: otherData._id,
					allocated_to: otherData.allocated_to?.name
						? otherData.allocated_to.name
						: '',
					insurance_company: otherData.insurance_company?.name
						? otherData.insurance_company.name
						: '',
					treatment_date: dayjs(otherData.treatment_date),
					hearing_date: otherData.hearing_date
						? dayjs(otherData.hearing_date)
						: '',
					date_of_birth: dayjs(otherData.date_of_birth),
					date_of_admission: otherData.date_of_admission
						? dayjs(otherData.date_of_admission)
						: '',
					date_of_discharge: otherData.date_of_discharge
						? dayjs(otherData.date_of_discharge)
						: '',
					date_of_eob: otherData.date_of_eob
						? dayjs(otherData.date_of_eob)
						: '',
					deadline_date: otherData.deadline_date
						? dayjs(otherData.deadline_date)
						: '',
					denial_date: otherData.denial_date
						? dayjs(otherData.denial_date)
						: '',

					years: years,
					months: months,
					days: days,
				});
			} else {
				form.setFieldsValue({
					years: years,
					months: months,
					days: days,
				});
				setFormData({ ...formData, date_of_birth: date });
			}
		} else {
			form.setFieldsValue({ years: null, months: null, days: null });
			setFormData({ ...formData, date_of_birth: date, age: null });
		}
	};

	const showDeleteConfirm = (_id) => {
		XModal.confirm({
			title: 'Delete Case?',
			content: (
				<p className="modalPara1">
					Are you sure you want to delete this case?
				</p>
			),
			icon: <img className="me-3" src="/images/delete-icon.svg" alt="" />,
			okText: 'Yes, Confirm',
			okType: 'danger',
			cancelText: 'No, Cancel',
			className: 'confirmModal',
			onOk() {
				deleteCase({ id: _id });
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	//This is for demo purposes only, will be removed on production
	const showCaseIdDropdown = () => {
		axios
			.get(`${config.api.base_url}/case/all-ids`, {
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				let dropdown = [];
				response.data.data.map((ele) => {
					return dropdown.push({
						label: `${ele.mrn} | ${ele.patient_first_name} | ${ele.case_id}`,
						value: ele._id,
					});
				});
				setCaseDropdown(dropdown);
			})
			.catch((error) => {
				console.log('error', error);
			});
	};

	const getBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});
	const handleCancel = () => setPreviewOpen(false);

	const handlePreview = async (file) => {
		if (file && file.name && file.name.split('.')[1] === 'pdf') {
			console.log(file.url);
			setPreviewImage(file.url || file.preview);
			setPdfPreviewOpen(true);
		} else {
			if (!file.url && !file.preview) {
				file.preview = await getBase64(file.originFileObj);
			}
			setPreviewImage(file.url || file.preview);
			setPreviewOpen(true);
			setPreviewTitle(
				file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
			);
		}
	};

	const handleChange = ({ fileList: newFileList }) => {
		const updatedFileList = newFileList.map((file) => {
			if (file.status === 'error') {
				return { ...file, status: 'done' };
			}
			return file;
		});
		setFileList(updatedFileList);
	};
	// Function to handle changes in the form fields
	const handleRemarkChange = (e) => {
		const { name, value } = e.target;
		if (name === 'denail_remarks') {
			setFormData((prevState) => ({
				...prevState,
				revenue_cycle_updates: {
					...prevState.revenue_cycle_updates,
					revenue_cycle_status: formData.revenue_cycle_stage,
					appeal_status: null,
					remarks: value,
				},
			}));
		}
	};

	const uploadButton = (
		<Button
			style={{
				border: 0,
				background: 'none',
			}}
		>
			<PlusOutlined style={{ color: '#000' }} />
			<div style={{ color: '#000' }}>Upload</div>
		</Button>
	);

	const handleDownload = (file) => {
		// Assuming 'file' contains the URL of the file to download
		window.open(file.url, '_blank');
	};

	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages);
	};

	const onDocumentLoadError = (error) => {
		console.error('Error loading document:', error);
		// Handle error
	};

	// Function to update the width of the modal based on the width of the document
	const updateModalWidth = (width) => {
		setPdfWidth(width + 40);
	};
	const handleSubmit = async () => {
		try {
			if (fileList.length > 0) {
				const fileListUid = fileList?.[0]?.uid;
				const formDataUid =
					formData?.revenue_cycle_updates?.url?.[0]?.uid;

				if (fileListUid && formDataUid && fileListUid !== formDataUid) {
					await Promise.all(
						fileList.map(async (fileData) => {
							let get_ext =
								fileData.originFileObj.name.split('.');
							let ext = get_ext[get_ext.length - 1];
							const res = await axios.get(
								`${config.api.base_url}/settings/get-signed-url?action=putObject&folder_name=case_supporting_documents&file_name=${formData.patient_last_name}&ext=${ext}&id=${formData.mrn}${fileData.originFileObj.uid}`,
								{
									headers: {
										'Content-type':
											'application/json; charset=UTF-8',
										Authorization:
											'Bearer ' + getUserToken(),
									},
								},
							);
							const result = await axios.put(
								res.data.data,
								fileData.originFileObj,
							);
							if (result.status === 200) {
								const documentPath = `/case_supporting_documents/${formData.mrn}${fileData.originFileObj.uid}/${formData.patient_last_name}.${ext}`;
								const newFile = {
									uid: fileData.originFileObj.uid,
									name: fileData.originFileObj.name,
									status: 'done',
									url: documentPath,
								};
								setUpdatedFileList((prevFileList) => [
									...prevFileList,
									newFile,
								]);
							}
						}),
					).then((res) => {
						setSubmitCase(true);
					});
				}
			}
			setSubmitCase(true);
		} catch (error) {
			// Handle error if any
			console.error('Error occurred:', error);
		}
	};

	useEffect(() => {
		if (submitCase) {
			if (id)
				editCase({
					id: id,
					...formData,
					revenue_cycle_updates: {
						...formData.revenue_cycle_updates,
						url: updatedFileList,
						revenue_cycle_status: formData.revenue_cycle_stage,
						// appeal_status: null,
						// remarks: null,
					},
				});
			else {
				const {
					_id,
					createdAt,
					updatedAt,
					supporting_docs,
					...formDataWithoutId
				} = formData;
				addCase({
					...formDataWithoutId,
					revenue_cycle_updates: {
						...formDataWithoutId.revenue_cycle_updates,
						url: updatedFileList,
						revenue_cycle_status: formData.revenue_cycle_stage,
					},
				});
			}
		}
	}, [submitCase]);

	useEffect(() => {
		if (getConfigurationsState.apiState === 'success') {
			if (getConfigurationsState.data.all_configurations.length > 0) {
				getConfigurationsState.data.all_configurations.map((option) => {
					if (option.key === 'title') {
						const optionsList = option.options.map((opt) => ({
							value: opt.value,
							label: opt.label,
						}));
						setTitleData(optionsList);
					}
					if (option.key === 'denial_reason') {
						const optionsList = option.options.map((opt) => ({
							value: opt.value,
							label: opt.label,
						}));
						setDenialReasonData(optionsList);
					}
					if (option.key === 'gender') {
						const optionsList = option.options.map((opt) => ({
							value: opt.value,
							label: opt.label,
						}));
						setGenderData(optionsList);
					}
				});
			}
		}
	}, [getConfigurationsState]);

	useEffect(() => {
		if (deleteCaseState.apiState === 'success') {
			notification.success({ message: deleteCaseState.message });
			getCases();
			deleteCaseReset();

			navigate('/case-management');
		}
	}, [deleteCaseState]);

	// use effects
	useEffect(() => {
		if (id) getCaseById({ id: id });
		getUserDropdown();
		getStateDropdown();
		getConfigurations({});
	}, [id]);

	useEffect(() => {
		if (getCaseByIdState.apiState === 'success') {
			setFormData({
				...getCaseByIdState.data,
				id: getCaseByIdState.data._id,
			});
			form.setFieldsValue({
				...getCaseByIdState.data,
				id: getCaseByIdState.data._id,
				allocated_to: getCaseByIdState.data.allocated_to?.name
					? getCaseByIdState.data.allocated_to.name
					: '',
				insurance_company: getCaseByIdState.data.insurance_company?.name
					? getCaseByIdState.data.insurance_company.name
					: '',
				treatment_date: dayjs(getCaseByIdState.data.treatment_date),
				hearing_date: dayjs(getCaseByIdState.data.hearing_date),
				denial_date: dayjs(getCaseByIdState.data.denial_date),
				date_of_birth: dayjs(getCaseByIdState.data.date_of_birth),
				rejected_category: 'revenue_cycle_supporting_text',
			});
			// if (getCaseByIdState.data.revenue_cycle_updates.url.length > 0) {
			// 	setFileList(getCaseByIdState.data.revenue_cycle_updates.url);
			// 	setSelectedOption('revenue_cycle_supporting_documents');
			// }

			if (
				getCaseByIdState.data.revenue_cycle_updates &&
				getCaseByIdState.data.revenue_cycle_updates.remarks &&
				id
			) {
				setFormData((old_data) => {
					let new_data = {
						...old_data,
						denail_remarks:
							getCaseByIdState.data.revenue_cycle_updates.remarks,
					};
					return new_data;
				});

				form.setFieldsValue({
					denail_remarks:
						getCaseByIdState.data.revenue_cycle_updates.remarks,
				});
			}

			const supportingDocs =
				getCaseByIdState.data.revenue_cycle_updates &&
				getCaseByIdState.data.revenue_cycle_updates.url;

			console.log(supportingDocs);

			if (supportingDocs && supportingDocs.length > 0) {
				// Modify fileList to add the prefix to URLs
				const modifiedFileList = supportingDocs.map((file) => ({
					...file,
					url: `${config.s3_url}${file.url}`,
				}));
				setFileList([...modifiedFileList]);
				setUpdatedFileList([...supportingDocs]);
				form.setFieldsValue({
					rejected_category: 'revenue_cycle_supporting_documents',
				});
				setSelectedOption('revenue_cycle_supporting_documents');
			} else {
				form.setFieldsValue({
					rejected_category: 'revenue_cycle_supporting_text',
				});
				setSelectedOption('revenue_cycle_supporting_text');
			}
			setDisplayDenialInputs(getCaseByIdState.data.revenue_cycle_stage);

			onDOBChange(
				getCaseByIdState.data.date_of_birth,
				getCaseByIdState.data,
			);
			displayOrganization && getOrganizations({});
			displayHospital &&
				getHospitalDropdown({
					organization_id: getCaseByIdState.data.organization,
					region_id: getCaseByIdState.data.region,
				});
			if (displayRegion) {
				getRegions({
					organization_id: getCaseByIdState.data.organization,
				});
			}

			getCaseByIdReset();
		}
	}, [getCaseByIdState]);

	useEffect(() => {
		if (addCaseState.apiState === 'success') {
			setSubmitCase(false);
			notification.success({ message: 'Case created successfully' });
			addCaseReset();
			navigate(-1);
		} else if (addCaseState.apiState === 'error') {
			notification.error({ message: addCaseState.message });
		}
	}, [addCaseState]);

	useEffect(() => {
		if (editCaseState.apiState === 'success') {
			setSubmitCase(false);
			notification.success({ message: 'Case updated successfully' });
			editCaseReset();
			navigate(-1);
		} else if (editCaseState.apiState === 'error') {
			notification.error({ message: editCaseState.message });
		}
	}, [editCaseState]);

	useEffect(() => {
		if (localStorage.getItem('role') === 'organization-admin') {
			setDisplayOrganization(false);
			getRegions();
		}
		if (localStorage.getItem('role') === 'region-admin') {
			setDisplayOrganization(false);
			setDisplayRegion(false);
			getHospitalDropdown();
		}
		if (
			localStorage.getItem('role') === 'hospital-admin' ||
			localStorage.getItem('role') === 'case-creator' ||
			localStorage.getItem('role') === 'case-analyser' ||
			localStorage.getItem('role') === 'case-manager'
		) {
			setDisplayOrganization(false);
			setDisplayRegion(false);
			setDisplayHospital(false);
		} else {
			setDisplayOrganization(true);
			setDisplayRegion(true);
			setDisplayHospital(true);
		}
		getOrganizations();
		getStateDropdown();
	}, []);

	return (
		<>
			{props.displayHeader ? (
				<></>
			) : (
				<>
					<Col lg={24} xl={24} xs={0} md={0}>
						<Header headerTag="Case Management" />
					</Col>
					<Col lg={0} xl={0} xs={24} md={24}>
						<HeaderFile activeNav="caseManagement" />
					</Col>
				</>
			)}

			<XContainer fluid className="mt-3">
				<XCardBackBg className="mb-3">
					<Row>
						<Col xs={24}>
							<XButtonBack
								onClick={() => navigate(-1)}
								icon={<ArrowLeftOutlined />}
								size="large"
							/>
							{id ? (
								<XEditTitle>Edit Case</XEditTitle>
							) : (
								<XEditTitle>Add Case</XEditTitle>
							)}{' '}
						</Col>
					</Row>
				</XCardBackBg>

				<XCardsTransparent>
					{modulePermissions.sub_modules.includes(
						'add-cases',
						'update-cases',
					) ? (
						<>
							{/* This is for demo purposes only will be removed on production */}
							{id ? (
								<></>
							) : (
								<Row
									gutter={(15, 30)}
									align={'middle'}
									style={{
										display: 'grid',
										placeItems: 'center',
									}}
								>
									<div
										style={{
											width: '80%',
											marginBottom: '30px',
											textAlign: 'center',
										}}
									>
										<label
											name="mrn"
											style={{ margin: '5px' }}
										>
											Select Case from EHR
										</label>
										<Select
											size="large"
											placeholder="Search Case"
											name="mrn"
											allowClear
											onClear={() => {
												form.resetFields();
											}}
											showSearch={true}
											filterOption={(input, option) =>
												option.label
													.toLowerCase()
													.includes(
														input.toLowerCase(),
													)
											}
											style={{
												width: '25%',
												textAlign: 'left',
											}}
											className="demo-case-css"
											options={caseDropdown}
											onFocus={showCaseIdDropdown}
											onSelect={(value) => {
												getCaseById({
													id: value,
													new_case: true,
												});
											}}
										/>
									</div>
								</Row>
							)}
							<XForm
								form={form}
								name="loginForm"
								layout="vertical"
								autoComplete="off"
								onFinish={handleSubmit}
							>
								<Row gutter={(15, 30)}>
									<Col xs={24} sm={12} md={12} lg={8}>
										<Row gutter={16}>
											<Col
												xs={24}
												sm={12}
												md={12}
												lg={12}
											>
												<XForm.Item
													name="mrn"
													label="Patient's Unique Id"
													rules={[
														{
															required: true,
															message:
																'Please enter patient ID',
														},
													]}
												>
													<Input
														size="large"
														placeholder="Enter patient ID"
														name="mrn"
														onChange={(e) =>
															handleInputChange(
																e,
																formData,
																setFormData,
															)
														}
													/>
												</XForm.Item>
											</Col>

											<Col
												xs={24}
												sm={12}
												md={12}
												lg={12}
											>
												<XForm.Item
													name="patient_title"
													label={'Title'}
													rules={[
														{
															required: true,
															message:
																'Please select title',
														},
													]}
												>
													<XSelect
														size="large"
														placeholder="Select Title"
														name="patient_title"
														showSearch={true}
														filterOption={(
															input,
															option,
														) =>
															option.label
																.toLowerCase()
																.includes(
																	input.toLowerCase(),
																)
														}
														options={titleData}
														onSelect={(value) => {
															handleSelectChange(
																'patient_title',
																value,
																formData,
																setFormData,
															);
														}}
													/>
												</XForm.Item>
											</Col>
										</Row>
									</Col>
									<Col xs={24} sm={12} md={12} lg={8}>
										<XForm.Item
											name="patient_first_name"
											label="Patient First Name"
											rules={[
												{
													required: true,
													message:
														'Please enter patient first name',
												},
											]}
										>
											<Input
												size="large"
												placeholder="Enter patient first name"
												name="patient_first_name"
												onChange={(e) =>
													handleInputChange(
														e,
														formData,
														setFormData,
													)
												}
											/>
										</XForm.Item>
									</Col>
									<Col xs={24} sm={12} md={12} lg={8}>
										<XForm.Item
											name="patient_last_name"
											label="Patient Last Name"
											rules={[
												{
													required: true,
													message:
														'Please enter patient last name',
												},
											]}
										>
											<Input
												size="large"
												placeholder="Enter patient last name"
												name="patient_last_name"
												onChange={(e) =>
													handleInputChange(
														e,
														formData,
														setFormData,
													)
												}
											/>
										</XForm.Item>
									</Col>
									<Col xs={24} sm={12} md={12} lg={8}>
										<XForm.Item
											name="gender"
											label="Gender"
											rules={[
												{
													required: true,
													message:
														'Please select gender',
												},
											]}
										>
											<XRadio.Group
												onChange={(e) =>
													handleInputChange(
														e,
														formData,
														setFormData,
													)
												}
												name="gender"
											>
												{genderData.length > 0 &&
													genderData.map((opt) => (
														<XRadio
															value={opt.value}
														>
															{opt.label}
														</XRadio>
													))}
											</XRadio.Group>
										</XForm.Item>
									</Col>
									<Col xs={24} sm={12} md={12} lg={8}>
										<XForm.Item
											name="date_of_birth"
											label="Date of Birth"
											rules={[
												{
													required: true,
													message:
														'Please select date of birth',
												},
												{
													validator:
														validateNonFutureDate,
												},
											]}
										>
											<DatePicker
												className="w-100"
												size="large"
												name="date_of_birth"
												disabledDate={futureDateDisable}
												onChange={(date) => {
													onDOBChange(date);
												}}
											/>
										</XForm.Item>
									</Col>
									<Col xs={24} sm={12} md={12} lg={8}>
										<Row gutter={16}>
											<Col xs={24} sm={12} md={8} lg={8}>
												<XForm.Item
													name="years"
													label="Years"
												>
													<InputNumber
														size="large"
														placeholder="Years"
														name="years"
														style={{
															width: '100%',
														}}
														disabled
													/>
												</XForm.Item>
											</Col>
											<Col xs={24} sm={12} md={8} lg={8}>
												<XForm.Item
													name="months"
													label="Months"
												>
													<InputNumber
														size="large"
														placeholder="Months"
														name="months"
														style={{
															width: '100%',
														}}
														disabled
													/>
												</XForm.Item>
											</Col>
											<Col xs={24} sm={12} md={8} lg={8}>
												<XForm.Item
													name="days"
													label="Days"
												>
													<InputNumber
														size="large"
														placeholder="Days"
														name="days"
														style={{
															width: '100%',
														}}
														disabled
													/>
												</XForm.Item>
											</Col>
										</Row>
									</Col>
									{displayOrganization ? (
										<Col xs={24} lg={8} md={12}>
											<XForm.Item
												name="organization"
												label={'Organization'}
												rules={[
													{
														required: true,
														message:
															'Please enter Region name',
													},
												]}
											>
												<XSelect
													size="large"
													placeholder="Select Organization"
													name="organization"
													showSearch={true}
													className="removeBg"
													onSearch={(search) => {
														if (
															search.length >= 3
														) {
															getOrganizations({
																search,
															});
														} else if (
															search.length === 0
														) {
															getOrganizations();
														}
													}}
													// onFocus={(search) => {
													// 	getOrganizations({
													// 		search,
													// 	});
													// }}
													filterOption={filterByLabel}
													options={
														getOrganizationsState.apiState ===
															'success' &&
														getOrganizationsState
															.data.list?.length >
															0 &&
														getOrganizationsState.data.list.map(
															(itm) => {
																return {
																	label: itm.name,
																	value: itm._id,
																};
															},
														)
													}
													onSelect={(
														value,
														options,
													) => {
														handleSelectChange(
															'organization',
															value,
															formData,
															setFormData,
														);
														getRegions({
															organization_id:
																value,
														});
														getHospitalDropdown({
															organization: value,
														});
														form.setFieldValue(
															'region',
															null,
														);
														form.setFieldValue(
															'hospital',
															null,
														);
													}}
												></XSelect>
											</XForm.Item>
										</Col>
									) : (
										<></>
									)}
									{displayRegion ? (
										<Col xs={24} lg={8} md={12}>
											<XForm.Item
												name="region"
												label={'Region'}
												rules={[
													{
														required: true,
														message:
															'Please enter Region name',
													},
												]}
											>
												<XSelect
													size="large"
													placeholder="Select Region"
													name="region"
													className="removeBg"
													filterOption={filterByLabel}
													showSearch={true}
													options={
														getRegionsState.apiState ===
															'success' &&
														getRegionsState.data
															.regions?.length >
															0 &&
														getRegionsState.data.regions.map(
															(itm) => {
																return {
																	label: itm.name,
																	value: itm._id,
																};
															},
														)
													}
													onSelect={(
														value,
														options,
													) => {
														handleSelectChange(
															'region',
															value,
															formData,
															setFormData,
														);
														getHospitalDropdown({
															region_id: value,
														});
														form.setFieldValue(
															'hospital',
															null,
														);
													}}
												></XSelect>
											</XForm.Item>
										</Col>
									) : (
										<></>
									)}
									{displayHospital ? (
										<Col xs={24} sm={12} md={12} lg={8}>
											<XForm.Item
												name="hospital"
												label={'Hospital'}
												rules={[
													{
														required: true,
														message:
															'Please select hospital',
													},
												]}
											>
												<XSelect
													size="large"
													placeholder="Select Hospital"
													name="hospital"
													filterOption={filterByLabel}
													options={
														getHospitalDropdownState.apiState ===
															'success' &&
														getHospitalDropdownState
															.data.hospitals
															?.length > 0 &&
														getHospitalDropdownState.data.hospitals.map(
															(itm) => {
																return {
																	label: itm.name,
																	value: itm._id,
																	visit_types:
																		itm.visit_types,
																};
															},
														)
													}
													onSelect={(
														value,
														options,
													) => {
														handleSelectChange(
															'hospital',
															value,
															formData,
															setFormData,
														);
														form.setFieldValue(
															'visit_type',
															null,
														);
														setVisitOptions(
															options.visit_types,
														);
													}}
												/>
											</XForm.Item>
										</Col>
									) : (
										<></>
									)}
									<Col xs={24} sm={12} md={12} lg={8}>
										<XForm.Item
											name="visit_type"
											label={'Visit Type'}
											rules={[
												{
													required: true,
													message:
														'Please select visit type',
												},
											]}
										>
											<XSelect
												size="large"
												placeholder="Select Visit Type"
												name="visit_type"
												filterOption={filterByLabel}
												options={visitOptions?.map(
													(itm) => {
														return {
															label: itm,
															value: itm,
														};
													},
												)}
												onSelect={(value) => {
													handleSelectChange(
														'visit_type',
														value,
														formData,
														setFormData,
													);
												}}
											/>
										</XForm.Item>
									</Col>
									<Col xs={24} sm={12} md={12} lg={8}>
										<XForm.Item
											name="treatment_date"
											label="Treatment Date"
											rules={[
												{
													required: true,
													message:
														'Please select treatment date',
												},
											]}
										>
											<DatePicker
												className="w-100"
												size="large"
												name="treatment_date"
												onChange={(date) => {
													handleDateChanged(
														date,
														'treatment_date',
														formData,
														setFormData,
													);
												}}
											/>
										</XForm.Item>
									</Col>
									<Col xs={24} sm={12} md={12} lg={8}>
										<XForm.Item
											name="date_of_eob"
											label="EOB Date"
											rules={[
												{
													required: true,
													message:
														'Please select EOB date',
												},
											]}
										>
											<DatePicker
												className="w-100"
												size="large"
												name="date_of_eob"
												onChange={(date) => {
													handleDateChanged(
														date,
														'date_of_eob',
														formData,
														setFormData,
													);
												}}
											/>
										</XForm.Item>
									</Col>
									<Col xs={24} sm={12} md={12} lg={8}>
										<XForm.Item
											name="deadline_date"
											label="Deadline Date"
											rules={[
												{
													required: true,
													message:
														'Please select Deadline date',
												},
											]}
										>
											<DatePicker
												className="w-100"
												size="large"
												name="deadline_date"
												onChange={(date) => {
													handleDateChanged(
														date,
														'deadline_date',
														formData,
														setFormData,
													);
												}}
											/>
										</XForm.Item>
									</Col>
									<Col xs={24} sm={12} md={12} lg={8}>
										<XForm.Item
											name="date_of_admission"
											label="Admission Date"
										>
											<DatePicker
												className="w-100"
												size="large"
												name="date_of_admission"
												onChange={(date) => {
													handleDateChanged(
														date,
														'date_of_admission',
														formData,
														setFormData,
													);
												}}
											/>
										</XForm.Item>
									</Col>
									<Col xs={24} sm={12} md={12} lg={8}>
										<XForm.Item
											name="date_of_discharge"
											label="Discharge Date"
										>
											<DatePicker
												className="w-100"
												size="large"
												name="date_of_discharge"
												onChange={(date) => {
													handleDateChanged(
														date,
														'date_of_discharge',
														formData,
														setFormData,
													);
												}}
											/>
										</XForm.Item>
									</Col>
									<Col xs={24} sm={12} md={12} lg={8}>
										<XForm.Item
											name="hearing_date"
											label="Hearing Date"
										>
											<DatePicker
												className="w-100"
												size="large"
												name="hearing_date"
												onChange={(date) => {
													handleDateChanged(
														date,
														'hearing_date',
														formData,
														setFormData,
													);
												}}
											/>
										</XForm.Item>
									</Col>
									<Col xs={24} sm={12} md={12} lg={8}>
										<XForm.Item
											name="state"
											label={'State'}
											rules={[
												{
													required: true,
													message:
														'Please select State',
												},
											]}
										>
											<XSelect
												size="large"
												placeholder="Select State"
												name="state"
												showSearch={true}
												// onFocus={() => {
												// 	getStateDropdown();
												// }}
												onSearch={(search) => {
													if (search.length >= 3) {
														getStateDropdown({
															search,
														});
													} else if (
														search.length === 0
													) {
														getStateDropdown();
													}
												}}
												filterOption={filterByLabel}
												options={
													getStateDropdownState.apiState ===
														'success' &&
													getStateDropdownState.data
														.all_states?.length >
														0 &&
													getStateDropdownState.data.all_states.map(
														(itm) => {
															return {
																label: itm.state,
																value: itm.state,
																providers:
																	itm.providers,
															};
														},
													)
												}
												onSelect={(value, options) => {
													handleSelectChange(
														'state',
														value,
														formData,
														setFormData,
													);
													form.setFieldValue(
														'insurance_company',
														null,
													);
													setInsuranceCompanies(
														options.providers,
													);
												}}
											/>
										</XForm.Item>
									</Col>
									<Col xs={24} sm={12} md={12} lg={8}>
										<XForm.Item
											name="insurance_company"
											label="Insurance Company"
											rules={[
												{
													required: true,
													message:
														'Please enter insurance company',
												},
											]}
										>
											<XSelect
												size="large"
												placeholder="Select Insurance Company"
												name="insurance_company"
												showSearch={true}
												filterOption={filterByLabel}
												options={
													insuranceCompanies.length >
														0 &&
													insuranceCompanies.map(
														(itm) => {
															return {
																label: itm.name,
																value: itm._id,
															};
														},
													)
												}
												onSelect={(value) => {
													handleSelectChange(
														'insurance_company',
														value,
														formData,
														setFormData,
													);
												}}
											/>
										</XForm.Item>
									</Col>
									<Col xs={24} sm={12} md={12} lg={8}>
										<XForm.Item
											name="allocated_to"
											label={
												id
													? 'Reallocate To'
													: 'Allocate To'
											}
										>
											<XSelect
												disabled={
													!modulePermissions.sub_modules.includes(
														'allocate-cases',
													)
												}
												size="large"
												placeholder="Allocate To"
												name="allocated_to"
												showSearch={true}
												onFocus={() => {
													getUserDropdown();
												}}
												onSearch={(search) => {
													if (search.length >= 3) {
														getUserDropdown({
															search,
														});
													} else if (
														search.length === 0
													) {
														getUserDropdown();
													}
												}}
												filterOption={filterByLabel}
												options={
													getUserDropdownState.apiState ===
														'success' &&
													getUserDropdownState.data
														.all_users?.length >
														0 &&
													getUserDropdownState.data.all_users.map(
														(user) => {
															return {
																label: user.name,
																value: user._id,
															};
														},
													)
												}
												onSelect={(value) => {
													handleSelectChange(
														'allocated_to',
														{ _id: value },
														formData,
														setFormData,
													);
												}}
											/>
										</XForm.Item>
									</Col>
									<Col xs={24} sm={12} md={12} lg={8}>
										<XForm.Item
											name="case_amount"
											label="Claim Amount"
											rules={[
												{
													required: true,
													message:
														'Please enter claim amount',
												},
											]}
										>
											<InputNumber
												size="large"
												placeholder="Claim Amount"
												name="case_amount"
												style={{ width: '100%' }}
												prefix="$"
												onChange={(v) => {
													handleNumberInputChange(
														'case_amount',
														v,
														formData,
														setFormData,
													);
												}}
											/>
										</XForm.Item>
									</Col>
									<Col xs={24} sm={12} md={8} lg={8}>
										<XForm.Item
											name="process_type"
											label={'Process Type'}
										>
											<XSelect
												size="large"
												placeholder="Select Process Type"
												name="process_type"
												showSearch={true}
												filterOption={(input, option) =>
													option.label
														.toLowerCase()
														.includes(
															input.toLowerCase(),
														)
												}
												options={processTypes}
												onSelect={(value) => {
													handleSelectChange(
														'process_type',
														value,
														formData,
														setFormData,
													);
												}}
											/>
										</XForm.Item>
									</Col>
									<Col xs={24} sm={12} md={8} lg={8}>
										<XForm.Item
											name="revenue_cycle"
											label={'Revenue Cycle'}
										>
											<XSelect
												size="large"
												placeholder="Select Revenue Cycle"
												name="revenue_cycle"
												showSearch={true}
												filterOption={(input, option) =>
													option.label
														.toLowerCase()
														.includes(
															input.toLowerCase(),
														)
												}
												// options={revenueCycleOptions}
												options={
													formData.process_type ===
													'ma_expedited'
														? revenueCycleOptions.filter(
																(option) =>
																	option.value !==
																	'post_claim',
														  )
														: revenueCycleOptions
												}
												onSelect={(value) => {
													handleSelectChange(
														'revenue_cycle',
														value,
														formData,
														setFormData,
													);
												}}
											/>
										</XForm.Item>
									</Col>
									<Col xs={24} sm={12} md={8} lg={8}>
										<XForm.Item
											name="revenue_cycle_stage"
											label={'Revenue Cycle Stage'}
										>
											<XSelect
												size="large"
												placeholder="Select Revenue Cycle Stage"
												name="revenue_cycle_stage"
												showSearch={true}
												filterOption={(input, option) =>
													option.label
														.toLowerCase()
														.includes(
															input.toLowerCase(),
														)
												}
												options={revenueCycleStage}
												onSelect={(value) => {
													handleSelectChange(
														'revenue_cycle_stage',
														value,
														formData,
														setFormData,
													);

													setDisplayDenialInputs(
														value,
													);
												}}
											/>
										</XForm.Item>
									</Col>
									{displayDenialInputs !==
									'organization_determination' ? (
										<>
											<Col xs={24} sm={12} md={12} lg={8}>
												<XForm.Item
													name="denial_reason"
													label={'Denial Type'}
												>
													<XSelect
														size="large"
														placeholder="Select Denial Type"
														name="denial_reason"
														filterOption={
															filterByLabel
														}
														options={
															denialReasonData
														}
														onSelect={(value) => {
															handleSelectChange(
																'denial_reason',
																value,
																formData,
																setFormData,
															);
														}}
													/>
												</XForm.Item>{' '}
											</Col>
											<Col xs={24} sm={12} md={12} lg={8}>
												<XForm.Item
													name="denial_date"
													label="Denial Date"
												>
													<DatePicker
														className="w-100"
														size="large"
														name="denial_date"
														onChange={(date) => {
															handleDateChanged(
																date,
																'denial_date',
																formData,
																setFormData,
															);
														}}
														disabledDate={
															disableFutureDates
														}
													/>
												</XForm.Item>
											</Col>
											<Col xs={24} sm={12} md={12} lg={8}>
												<XForm.Item
													name="denial_code1"
													label="Denial Code 1"
												>
													<Input
														size="large"
														placeholder="Enter denial code 1"
														name="denial_code1"
														onChange={(e) =>
															handleInputChange(
																e,
																formData,
																setFormData,
															)
														}
													/>
												</XForm.Item>
											</Col>

											<Col xs={24} sm={12} md={12} lg={8}>
												<XForm.Item
													name="denial_code2"
													label="Denial Code 2"
												>
													<Input
														size="large"
														placeholder="Enter denial code 2"
														name="denial_code2"
														onChange={(e) =>
															handleInputChange(
																e,
																formData,
																setFormData,
															)
														}
													/>
												</XForm.Item>
											</Col>
											<Col xs={24}>
												<Row gutter={[15, 15]}>
													<Col xs={24}>
														<XForm.Item
															className="mb-1"
															name="rejected_category"
															label="Denial Reason"
															rules={[
																{
																	required: true,
																	message:
																		'Please select option!',
																},
															]}
														>
															<XRadio.Group
																defaultValue={
																	selectedOption
																}
																onChange={(
																	e,
																) => {
																	handleInputChange(
																		e,
																		formData,
																		setFormData,
																	);
																	form.setFieldsValue(
																		{
																			remarks:
																				'',
																		},
																	);
																	setFormData(
																		{
																			...formData,
																			remarks:
																				'',
																		},
																	);
																	if (
																		e.target
																			.value ===
																		'revenue_cycle_supporting_text'
																	) {
																		setSelectedOption(
																			'revenue_cycle_supporting_text',
																		);
																	} else if (
																		e.target
																			.value ===
																		'revenue_cycle_supporting_documents'
																	) {
																		setSelectedOption(
																			'revenue_cycle_supporting_documents',
																		);
																	}
																}}
																name="rejected_category"
																className=""
															>
																<XRadio
																	className="pb-2"
																	value={
																		'revenue_cycle_supporting_text'
																	}
																>
																	Enter Reason
																</XRadio>
																<XRadio
																	className="pb-2"
																	value={
																		'revenue_cycle_supporting_documents'
																	}
																>
																	Upload
																	Denial
																	Letter
																</XRadio>
															</XRadio.Group>
														</XForm.Item>
													</Col>

													{selectedOption ===
														'revenue_cycle_supporting_text' && (
														<Col
															xs={24}
															className="text-end"
														>
															<XForm.Item
																name="denail_remarks"
																// label="Denial Text"
																rules={[
																	{
																		required: true,
																		message:
																			'Please enter Denial Reason',
																	},
																]}
															>
																<TextArea
																	name="denail_remarks"
																	showCount
																	maxLength={
																		100
																	}
																	style={{
																		height: 120,
																		marginBottom: 24,
																	}}
																	onChange={
																		handleRemarkChange
																	}
																	disabled={
																		id
																			? true
																			: false
																	}
																	placeholder="Enter Denial Reason"
																/>
															</XForm.Item>
														</Col>
													)}

													{selectedOption ===
														'revenue_cycle_supporting_documents' && (
														<Col
															xs={24}
															md={24}
															lg={12}
														>
															<>
																<XForm.Item
																// label="Upload Revenue Cycle Supporting Documents"
																>
																	<Card
																		style={{
																			padding:
																				'8px',
																		}}
																		className="no-padding-card"
																	>
																		<Upload
																			action={
																				null
																			}
																			style={{
																				padding: 0,
																				margin: 0,
																			}}
																			showUploadList={{
																				showPreviewIcon: true,
																				showRemoveIcon: false,
																			}}
																			className="supporting_files"
																			listType="picture-card"
																			fileList={
																				fileList
																			}
																			onPreview={
																				handlePreview
																			}
																			onChange={
																				handleChange
																			}
																			accept=".pdf,.jpeg,.jpg,.png"
																			beforeUpload={(
																				file,
																			) => {
																				const isGIF =
																					file.type ===
																					'image/gif';
																				const isVideo =
																					file.type.startsWith(
																						'video/',
																					);
																				const isAudio =
																					file.type.startsWith(
																						'audio/',
																					);

																				const isLt10M =
																					file.size /
																						1024 /
																						1024 <
																					10;

																				if (
																					!isLt10M
																				) {
																					notification.error(
																						{
																							message:
																								'File Size Exceeded',
																							description: `The file ${file.name} exceeds the maximum allowed size of 10MB.`,
																						},
																					);
																				}
																				if (
																					isGIF ||
																					isVideo ||
																					isAudio
																				) {
																					notification.error(
																						{
																							message:
																								'File Type Error',
																							description: `GIF, video, and audio files are not allowed.`,
																						},
																					);
																				}

																				return (
																					isLt10M &&
																					!isGIF &&
																					!isVideo &&
																					!isAudio
																				);
																			}}
																			onDownload={
																				handleDownload
																			}
																		>
																			{fileList.length >=
																			1
																				? null
																				: uploadButton}
																		</Upload>
																	</Card>
																</XForm.Item>
																<Modal
																	open={
																		previewOpen
																	}
																	title={
																		previewTitle
																	}
																	footer={
																		null
																	}
																	onCancel={
																		handleCancel
																	}
																>
																	{previewImage ? (
																		<img
																			alt="example"
																			style={{
																				width: '100%',
																			}}
																			src={
																				previewImage
																			}
																		/>
																	) : null}
																</Modal>
																<Modal
																	open={
																		pdfPreviewOpen
																	}
																	title="Preview"
																	footer={
																		null
																	}
																	onCancel={() =>
																		setPdfPreviewOpen(
																			false,
																		)
																	}
																	width={
																		pdfWidth
																	}
																>
																	<div>
																		{/* <Document
																			file={
																				previewImage
																			}
																			onLoadSuccess={
																				onDocumentLoadSuccess
																			}
																			onLoadError={
																				onDocumentLoadError
																			}
																		>
																			<Page
																				pageNumber={
																					pageNumber
																				}
																				onLoadSuccess={(
																					page,
																				) =>
																					updateModalWidth(
																						page.width,
																					)
																				}
																			/>
																		</Document>
																		<p>
																			Page{' '}
																			{
																				pageNumber
																			}{' '}
																			of
																			{
																				numPages
																			}
																		</p> */}
																		<Worker workerUrl="https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js">
																			<div
																				style={{
																					height: '750px',
																				}}
																			>
																				<Viewer
																					fileUrl={
																						previewImage
																					}
																				/>
																			</div>
																		</Worker>
																	</div>
																</Modal>
															</>
														</Col>
													)}
												</Row>
											</Col>
										</>
									) : (
										<></>
									)}
									<Col xs={24}>
										<XForm.Item
											name="clinical_summary"
											label="Enter Clinical Summary"
											rules={[
												{
													required: true,
													message:
														'Please enter clinical summary',
												},
											]}
										>
											<TextArea
												name="clinical_summary"
												showCount
												style={{
													marginBottom: 24,
												}}
												onChange={(e) =>
													handleInputChange(
														e,
														formData,
														setFormData,
													)
												}
												autoSize={{
													minRows: 5,
												}}
												placeholder="Enter Detailed clinical summary"
											/>
										</XForm.Item>
									</Col>{' '}
								</Row>

								<Row className="mt-4">
									{id ? (
										<Col
											xs={24}
											lg={{ span: 2, offset: 0 }}
											md={{ span: 2, offset: 0 }}
											sm={{ span: 2, offset: 0 }}
											className="text-end"
										>
											<Button
												onClick={() =>
													showDeleteConfirm(id)
												}
											>
												Delete Case
											</Button>
										</Col>
									) : (
										<></>
									)}

									<Col
										xs={24}
										lg={22}
										md={22}
										sm={22}
										className="text-end"
									>
										<Button
											style={{
												color: '#000',
											}}
											onClick={() => navigate(-1)}
											className="cancel"
										>
											Cancel
										</Button>
										<XButtonConfirm
											type="primary"
											style={{
												background: '#000',
												color: '#fff',
											}}
											htmlType="submit"
										>
											Submit
										</XButtonConfirm>
									</Col>
								</Row>
							</XForm>
						</>
					) : (
						<Result
							status="403"
							title="403"
							subTitle="Sorry, you are not authorized to access this page."
							extra={
								<Button
									type="primary"
									onClick={() => navigate('/')}
								>
									Back Home
								</Button>
							}
						/>
					)}
				</XCardsTransparent>
			</XContainer>
		</>
	);
};

const mapStateToProps = (state) => ({
	getUserDropdownState: state.getUserDropdown,
	getCaseByIdState: state.getCaseById,
	editCaseState: state.editCase,
	addCaseState: state.addCase,
	deleteCaseState: state.deleteCase,
	getCasesState: state.getCases,
	analyseCoverageState: state.analyseCoverage,
	getStateDropdownState: state.getStateDropdown,
	getHospitalDropdownState: state.getHospitalDropdown,
	getInsuranceDropdownState: state.getInsuranceDropdown,
	getConfigurationOptionsState: state.getConfigurationOptions,
	getConfigurationsState: state.getConfigurations,
	getRegionsState: state.getRegions,
	getOrganizationsState: state.getOrganizations,
});

const mapDispatchToProps = (dispatch) => ({
	getUserDropdown: () => dispatch(getUserDropdownApi()),
	getUserDropdownReset: () => dispatch(getUserDropdownReset()),
	getCaseById: (data) => dispatch(getCaseByIdApi(data)),
	getCaseByIdReset: () => dispatch(getCaseByIdReset()),
	editCase: (data) => dispatch(editCaseApi(data)),
	editCaseReset: () => dispatch(editCaseReset()),
	addCase: (data) => dispatch(addCaseApi(data)),
	addCaseReset: () => dispatch(addCaseReset()),
	deleteCase: (data) => dispatch(deleteCaseApi(data)),
	deleteCaseReset: () => dispatch(deleteCaseReset()),
	getCases: (data) => dispatch(getCasesApi(data)),
	analyseCoverage: (params) => dispatch(analyseCoverageApi(params)),
	getStateDropdown: (params) => dispatch(getStateDropdownApi(params)),
	getStateDropdownReset: () => dispatch(getStateDropdownReset()),
	getHospitalDropdown: (params) => dispatch(getHospitalDropdownApi(params)),
	getHospitalDropdownReset: () => dispatch(getHospitalDropdownReset()),
	getConfigurationOptions: (params) =>
		dispatch(getConfigurationOptionsApi(params)),
	getConfigurationOptionsReset: () =>
		dispatch(getConfigurationOptionsReset()),
	getConfigurations: (params) => dispatch(getConfigurationsApi(params)),
	getRegions: (params) => dispatch(getRegionsApi(params)),
	getOrganizations: (data) => dispatch(getOrganizationsApi(data)),
	getOrganizationsReset: () => dispatch(getOrganizationsReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CaseForm);
